var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common';
import { InsuranceDetailDto } from './insurance-detail.dto';
import { determineInsuranceFeeType } from './insurance.dto';
export class InsuranceWithoutRelationsDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.name = initializer.name;
        this.detailA = initializer.detailA;
        this.detailB = initializer.detailB;
        this.detailC = initializer.detailC;
        this.detailD = initializer.detailD;
        this.detailE = initializer.detailE;
        this.feeA = initializer.feeA;
        this.feeB = initializer.feeB;
        this.feeC = initializer.feeC;
        this.feeD = initializer.feeD;
        this.feeE = initializer.feeE;
        this.calculationMethod = initializer.calculationMethod;
    }
}
__decorate([
    Type(() => InsuranceDetailDto),
    __metadata("design:type", InsuranceDetailDto)
], InsuranceWithoutRelationsDto.prototype, "detailA", void 0);
__decorate([
    Type(() => InsuranceDetailDto),
    __metadata("design:type", InsuranceDetailDto)
], InsuranceWithoutRelationsDto.prototype, "detailB", void 0);
__decorate([
    Type(() => InsuranceDetailDto),
    __metadata("design:type", InsuranceDetailDto)
], InsuranceWithoutRelationsDto.prototype, "detailC", void 0);
__decorate([
    Type(() => InsuranceDetailDto),
    __metadata("design:type", InsuranceDetailDto)
], InsuranceWithoutRelationsDto.prototype, "detailD", void 0);
__decorate([
    Type(() => InsuranceDetailDto),
    __metadata("design:type", InsuranceDetailDto)
], InsuranceWithoutRelationsDto.prototype, "detailE", void 0);
__decorate([
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], InsuranceWithoutRelationsDto.prototype, "feeA", void 0);
__decorate([
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], InsuranceWithoutRelationsDto.prototype, "feeB", void 0);
__decorate([
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], InsuranceWithoutRelationsDto.prototype, "feeC", void 0);
__decorate([
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], InsuranceWithoutRelationsDto.prototype, "feeD", void 0);
__decorate([
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], InsuranceWithoutRelationsDto.prototype, "feeE", void 0);
