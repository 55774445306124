import { Box, Divider, Typography } from '@mui/material';
import {
  getReservationDirectPayments,
  type ReservationDto,
} from '@orcar/common';
import { TableContent, TableLabel } from '@/components/StyledMuiComponent';

type ReceiptProps = {
  reservation: ReservationDto;
  possibleExtraFee?: number;
};

const Receipt = (props: ReceiptProps) => {
  const { reservation, possibleExtraFee } = props;

  const {
    vehicleFee,
    extraFee: realExtraFee,
    insuranceFee,
    prepaymentFee,
    extensionFee,
  } = getReservationDirectPayments(reservation);

  const extraFee = possibleExtraFee
    ? possibleExtraFee + realExtraFee
    : realExtraFee;

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#ffffff',
        borderTop: '1px solid #D4D4D4',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '1fr 3fr',
          rowGap: '24px',
          padding: '40px',
        }}
      >
        <TableLabel>차량요금</TableLabel>
        <TableContent textAlign={'right'}>
          {`${vehicleFee.toLocaleString()}원`}
        </TableContent>
        <TableLabel>기타요금</TableLabel>
        <TableContent textAlign={'right'}>
          {`${extraFee.toLocaleString()}원`}
        </TableContent>
        <TableLabel>자차요금</TableLabel>
        <TableContent textAlign={'right'}>
          {`${insuranceFee.toLocaleString()}원`}
        </TableContent>
        <TableLabel>연장요금</TableLabel>
        <TableContent textAlign={'right'}>
          {`${extensionFee.toLocaleString()}원`}
        </TableContent>
        <TableLabel>선납입금</TableLabel>
        <TableContent textAlign={'right'}>
          {`${prepaymentFee ? '-' : ''}${prepaymentFee.toLocaleString()}원`}
        </TableContent>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '1fr 3fr',
          rowGap: '16px',
          paddingX: '40px',
          paddingY: '47px',
        }}
      >
        <Typography fontSize={40} fontWeight={'bold'}>
          총 결제금액
        </Typography>
        <Typography
          fontSize={40}
          fontWeight={'bold'}
          sx={({ palette }) => ({
            color: palette.primary.main,
            textAlign: 'right',
          })}
        >
          {`${(
            vehicleFee +
            extraFee +
            extensionFee +
            insuranceFee -
            prepaymentFee
          ).toLocaleString()}원`}
        </Typography>
      </Box>
    </Box>
  );
};

export default Receipt;
