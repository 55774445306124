import {
  type GetManyReservationsQueryDto,
  type GetReservationInsuranceFeeQueryDto,
  type ManagerApprovalDto,
  type Paginated,
  type ReservationDto,
  type ReservationInsuranceFeeDto,
  type UpdateApiPropsWithStringId,
  type UpdateReservationDto,
  type ValidateReservationDriverLicenseAndUpdateDto,
} from '@orcar/common';
import axiosClient from './axiosClient';

export const getReservations = async (
  params?: Partial<GetManyReservationsQueryDto>,
): Promise<Paginated<ReservationDto>> => {
  const response = await axiosClient.get('/reservations', { params });

  return response.data;
};

export const getReservation = async (id: string): Promise<ReservationDto> => {
  const response = await axiosClient.get('/reservations/' + id);

  return response.data;
};

export const getReservationInsuranceFee = async (
  params: GetReservationInsuranceFeeQueryDto,
): Promise<ReservationInsuranceFeeDto> => {
  const response = await axiosClient.get('/reservations/fee/insurance', {
    params,
  });

  return response.data;
};

export const updateReservation = async ({
  input,
  id,
}: UpdateApiPropsWithStringId<UpdateReservationDto>): Promise<ReservationDto> => {
  const response = await axiosClient.patch(`/reservations/${id}`, input);

  return response.data;
};

export const uploadDriverLicenseImageAndUpdateUrl = async ({
  input,
  id,
}: UpdateApiPropsWithStringId<{
  driverNumber: 1 | 2;
  imageBlob: Blob;
}>): Promise<ReservationDto> => {
  const formData = new FormData();

  formData.append(`driverNumber`, input.driverNumber.toString());
  formData.append(
    `file`,
    input.imageBlob,
    `reservation-${id}-driver-license-${input.driverNumber}-${Date.now()}.jpg`,
  );

  const response = await axiosClient.patch(
    `/reservations/${id}/license-image/${input.driverNumber}`,
    formData,
  );

  return response.data;
};

export const validateAndUpdateDriverLicense = async ({
  id,
  input,
}: UpdateApiPropsWithStringId<{
  driverNumber: 1 | 2;
  input: ValidateReservationDriverLicenseAndUpdateDto;
}>): Promise<ReservationDto> => {
  const response = await axiosClient.post(
    `/reservations/${id}/validate-update-license/${input.driverNumber}`,
    input.input,
  );

  return response.data;
};

export const pickUpReservation = async ({
  input,
  id,
}: UpdateApiPropsWithStringId<UpdateReservationDto>): Promise<ReservationDto> => {
  const response = await axiosClient.patch(
    `/reservations/${id}/pick-up`,
    input,
  );

  return response.data;
};

export const uploadSignatureImageAndUpdateUrl = async ({
  input,
  id,
}: UpdateApiPropsWithStringId<{
  driverNumber: '1' | '2' | 'privacy';
  imageBlob: Blob;
}>): Promise<ReservationDto> => {
  const formData = new FormData();

  formData.append(`driverNumber`, input.driverNumber);
  formData.append(
    `file`,
    input.imageBlob,
    `reservation-${id}-signature-${input.driverNumber}-${Date.now()}.jpg`,
  );

  const response = await axiosClient.patch(
    `/reservations/${id}/contractor-signature/${input.driverNumber}`,
    formData,
  );

  return response.data;
};

export const getApprovedApprovals = async (
  id: string,
): Promise<ManagerApprovalDto[]> => {
  const response = await axiosClient.get<ManagerApprovalDto[]>(
    `/reservations/${id}/manager-approvals/approved`,
  );

  return response.data;
};
