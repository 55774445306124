import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import BottomButtons from '@/components/BottomButtons';
import ConfirmDialog from '@/components/ConfirmDialog';
import TitleMessage from '@/components/TitleMessage';
import { useGetReservation } from '@/hooks/reservation.hook';
import Receipt from '@/pages/reservationInfo/components/Receipt';
import ReservationDetailCard from './components/ReservationDetailCard';

const ReservationInfoPage = () => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const navigate = useNavigate();

  const id = useParams().id;

  const { data, isLoading } = useGetReservation({
    id,
  });

  if (isLoading) {
    return <></>;
  }

  if (!data || !data.assignedVehicleId || !data.dispatchAreaId) {
    return <Navigate to='/' replace />;
  }

  const handleClickNext = () => {
    if (!data.insuranceOption && data.insurances.length === 0) {
      setConfirmDialogOpen(true);
      return;
    }

    navigate(`/${id}/special-contract`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingBottom: '240px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          background: '#f5f5f5',
          paddingTop: '100px',
          paddingX: '80px',
        }}
      >
        <TitleMessage message='예약 정보를 확인해주세요.' />
        <ReservationDetailCard
          sx={{
            marginTop: '56px',
          }}
          reservation={data}
        />
      </Box>
      <Receipt reservation={data} />
      <BottomButtons
        label='다음'
        homeButton
        backButton
        onClick={handleClickNext}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        title='차량손해면책 미가입'
        confirmButtonLabel='확인'
        closeButtonLabel='이전'
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          setConfirmDialogOpen(false);
          navigate(`/${id}/extra-item`);
        }}
      >
        <Typography
          fontSize={32}
          fontWeight={'regular'}
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            marginX: '40px',
            marginTop: '20px',
            marginBottom: '40px',
          })}
        >
          현재 차량손해면책에 가입하지 않았습니다. 차량손해면책 미가입 시 차량
          사고가 발생하면{' '}
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            차량수리비, 휴차보상료 등의 비용
          </span>
          이 청구될 수 있습니다.
        </Typography>
      </ConfirmDialog>
    </Box>
  );
};

export default ReservationInfoPage;
