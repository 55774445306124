/* eslint-disable @typescript-eslint/ban-types */
import { applyIsOptionalDecorator, inheritPropertyInitializers, inheritTransformationMetadata, inheritValidationMetadata, } from './type-helpers.utils';
export function PartialType(classRef) {
    class PartialClassType {
        constructor() {
            inheritPropertyInitializers(this, classRef);
        }
    }
    const propertyKeys = inheritValidationMetadata(classRef, PartialClassType);
    inheritTransformationMetadata(classRef, PartialClassType);
    if (propertyKeys) {
        propertyKeys.forEach((key) => {
            applyIsOptionalDecorator(PartialClassType, key);
        });
    }
    Object.defineProperty(PartialClassType, 'name', {
        value: `Partial${classRef.name}`,
    });
    return PartialClassType;
}
