var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsOptional, ValidateNested } from 'class-validator';
import { UpdateFeeWeekendPolicyDto } from '../../../../classes/fee';
import { OmitType, PartialType } from '../../../../utils';
import { UpdateVehicleModelAudioDto } from './update-vehicle-model-audio.dto';
import { UpdateVehicleModelFeeDto } from './update-vehicle-model-fee.dto';
import { UpdateVehicleModelInsuranceDto } from './update-vehicle-model-insurance.dto';
import { UpdateVehicleModelKeyOptionDto } from './update-vehicle-model-key-option.dto';
import { UpdateVehicleModelOptionDto } from './update-vehicle-model-option.dto';
import { CreateVehicleModelDto } from '../create';
export class UpdateVehicleModelDto extends PartialType(OmitType(CreateVehicleModelDto, [
    'audio',
    'keyOption',
    'modelOption',
    'weekend',
    'fee',
    'insurance1',
    'insurance2',
])) {
}
__decorate([
    ValidateNested(),
    Type(() => UpdateVehicleModelAudioDto),
    IsOptional(),
    __metadata("design:type", UpdateVehicleModelAudioDto)
], UpdateVehicleModelDto.prototype, "audio", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateVehicleModelKeyOptionDto),
    IsOptional(),
    __metadata("design:type", UpdateVehicleModelKeyOptionDto)
], UpdateVehicleModelDto.prototype, "keyOption", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateVehicleModelOptionDto),
    IsOptional(),
    __metadata("design:type", UpdateVehicleModelOptionDto)
], UpdateVehicleModelDto.prototype, "modelOption", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateFeeWeekendPolicyDto),
    IsOptional(),
    __metadata("design:type", UpdateFeeWeekendPolicyDto)
], UpdateVehicleModelDto.prototype, "weekend", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateVehicleModelFeeDto),
    IsOptional(),
    __metadata("design:type", UpdateVehicleModelFeeDto)
], UpdateVehicleModelDto.prototype, "fee", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateVehicleModelInsuranceDto),
    IsOptional(),
    __metadata("design:type", UpdateVehicleModelInsuranceDto)
], UpdateVehicleModelDto.prototype, "insurance1", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateVehicleModelInsuranceDto),
    IsOptional(),
    __metadata("design:type", UpdateVehicleModelInsuranceDto)
], UpdateVehicleModelDto.prototype, "insurance2", void 0);
