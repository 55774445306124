var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean, IsDateString, IsOptional, IsString } from 'class-validator';
import { GetManyQueryDto } from '../../../classes/common';
import { TransformBoolean } from '../../../decorators';
import { PartialType } from '../../../utils';
export class GetManyErpAdminNoticesQueryDto extends PartialType(GetManyQueryDto) {
}
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyErpAdminNoticesQueryDto.prototype, "fromCreatedAt", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyErpAdminNoticesQueryDto.prototype, "usernameKeyword", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyErpAdminNoticesQueryDto.prototype, "titleKeyword", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyErpAdminNoticesQueryDto.prototype, "isNotice", void 0);
