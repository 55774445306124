var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { DispatchAreaListDtoV2 } from '../../../classes/dispatch-area/response/dispatch-area-list.v2.dto';
import { UserListDtoV2 } from '../../../classes/user/response/user-list.dto.v2';
import { VehicleListDtoV2 } from '../../../classes/vehicle/response/vehicle-list.v2.dto';
import { VehicleModelListDtoV2 } from '../../../classes/vehicle-model/response/vehicle-model-list.v2.dto';
import { ReservationPersonDtoV2 } from './reservation-person.v2.dto';
export class ReservationListDtoV2 {
    constructor(initializer) {
        Object.assign(this, initializer);
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationListDtoV2.prototype, "createdAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationListDtoV2.prototype, "pickUpAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationListDtoV2.prototype, "actualDropOffAt", void 0);
__decorate([
    Type(() => ReservationPersonDtoV2),
    __metadata("design:type", ReservationPersonDtoV2)
], ReservationListDtoV2.prototype, "contractor", void 0);
__decorate([
    Type(() => VehicleModelListDtoV2),
    __metadata("design:type", VehicleModelListDtoV2)
], ReservationListDtoV2.prototype, "vehicleModel", void 0);
__decorate([
    Type(() => VehicleListDtoV2),
    __metadata("design:type", Object)
], ReservationListDtoV2.prototype, "reservedVehicle", void 0);
__decorate([
    Type(() => VehicleListDtoV2),
    __metadata("design:type", Object)
], ReservationListDtoV2.prototype, "assignedVehicle", void 0);
__decorate([
    Type(() => DispatchAreaListDtoV2),
    __metadata("design:type", Object)
], ReservationListDtoV2.prototype, "dispatchArea", void 0);
__decorate([
    Type(() => UserListDtoV2),
    __metadata("design:type", Object)
], ReservationListDtoV2.prototype, "releaseManager", void 0);
__decorate([
    Type(() => UserListDtoV2),
    __metadata("design:type", Object)
], ReservationListDtoV2.prototype, "returnManager", void 0);
