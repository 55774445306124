import {
  type ExtraItemDto,
  type GetManyExtraItemsQueryDto,
  type Paginated,
} from '@orcar/common';
import axiosClient from './axiosClient';

export const getExtraItems = async (
  params?: GetManyExtraItemsQueryDto,
): Promise<Paginated<ExtraItemDto>> => {
  const response = await axiosClient.get('/extra-items', { params });

  return response.data;
};
