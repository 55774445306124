import {
  type AxiosException,
  type CreateReservationPaymentByKioskDto,
  type KioskPaymentDto,
} from '@orcar/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { confirmPayment } from '@/apis/payment.api';

export const useConfirmPayment = () => {
  const queryClient = useQueryClient();

  return useMutation<
    KioskPaymentDto,
    AxiosError<AxiosException>,
    CreateReservationPaymentByKioskDto
  >({
    mutationFn: confirmPayment,
    onSuccess: (data, { reservationId }) => {
      queryClient.invalidateQueries({
        queryKey: ['reservation', reservationId],
      });
      queryClient.invalidateQueries({
        queryKey: ['reservationList'],
      });
    },
  });
};
