export class Daily7InsuranceFeeDto {
    static instanceOf(obj) {
        if (obj === null || obj === undefined) {
            return false;
        }
        return ('day1' in obj &&
            'day2' in obj &&
            'day3' in obj &&
            'day4' in obj &&
            'day5' in obj &&
            'day6' in obj &&
            'day7' in obj &&
            typeof obj.day1 === 'number' &&
            typeof obj.day2 === 'number' &&
            typeof obj.day3 === 'number' &&
            typeof obj.day4 === 'number' &&
            typeof obj.day5 === 'number' &&
            typeof obj.day6 === 'number' &&
            typeof obj.day7 === 'number' &&
            Object.keys(obj).length === 7);
    }
}
