var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { ConvenienceEquipmentForComparisonDto, ExtraItemForComparisonDto, InsuranceForComparisonDto, } from './reservation-for-comparison-entity-in-array.dto';
export class ReservationExtraItemForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.extraItem = initialize.extraItem;
        this.quantity = initialize.quantity;
        this.fee = initialize.fee;
        this.directPaymentPrice = initialize.directPaymentPrice;
        this.memo = initialize.memo;
        this.isAddedByCustomer = initialize.isAddedByCustomer;
    }
}
__decorate([
    Type(() => ExtraItemForComparisonDto),
    __metadata("design:type", ExtraItemForComparisonDto)
], ReservationExtraItemForComparisonDto.prototype, "extraItem", void 0);
export class ReservationConvenienceForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.convenienceEquipment = initialize.convenienceEquipment;
        this.fee = initialize.fee;
        this.quantity = initialize.quantity;
        this.directPaymentPrice = initialize.directPaymentPrice;
        this.memo = initialize.memo;
    }
}
__decorate([
    Type(() => ConvenienceEquipmentForComparisonDto),
    __metadata("design:type", ConvenienceEquipmentForComparisonDto)
], ReservationConvenienceForComparisonDto.prototype, "convenienceEquipment", void 0);
export class ReservationExtensionForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.startDate = initialize.startDate;
        this.endDate = initialize.endDate;
        this.usageTime = initialize.usageTime;
        this.fee = initialize.fee;
        this.directPaymentPrice = initialize.directPaymentPrice;
    }
}
export class ReservationInsuranceForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.insurance = initialize.insurance;
        this.fee = initialize.fee;
        this.directPaymentPrice = initialize.directPaymentPrice;
        this.memo = initialize.memo;
    }
}
__decorate([
    Type(() => InsuranceForComparisonDto),
    __metadata("design:type", InsuranceForComparisonDto)
], ReservationInsuranceForComparisonDto.prototype, "insurance", void 0);
export class ReservationPaymentForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.type = initialize.type;
        this.category = initialize.category;
        this.date = initialize.date;
        this.fee = initialize.fee;
        this.memo = initialize.memo;
        this.bankCardId = initialize.bankCardId;
        this.managerId = initialize.managerId;
    }
}
export class ReservationConsultationForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
    }
}
export class ReservationChecklistItemForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.name = initialize.name;
    }
}
export class ReservationMessagingRecordForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
    }
}
export class ReservationManagerApprovalForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.type = initialize.type;
        this.approvedAt = initialize.approvedAt;
    }
}
