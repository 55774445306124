var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common';
import { VehicleModelAudioDto } from '../../../classes/vehicle-model/response/vehicle-model-audio.dto';
import { VehicleModelKeyOptionDto } from '../../../classes/vehicle-model/response/vehicle-model-key-option.dto';
import { VehicleModelOptionDto } from '../../../classes/vehicle-model/response/vehicle-model-option.dto';
export class VehicleWithoutRelationsDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.vehicleNumber = initializer.vehicleNumber;
        this.registeredAt = initializer.registeredAt;
        this.state = initializer.state;
        this.age = initializer.age;
        this.mileage = initializer.mileage;
        this.inspectionDue = initializer.inspectionDue;
        this.expirationDate = initializer.expirationDate;
        this.color = initializer.color;
        this.audio = initializer.audio;
        this.vehicleOption = initializer.vehicleOption;
        this.keyOption = initializer.keyOption;
        this.memo = initializer.memo;
        this.vehicleModelId = initializer.vehicleModelId;
        this.garageId = initializer.garageId;
        this.branchId = initializer.branchId;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], VehicleWithoutRelationsDto.prototype, "registeredAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], VehicleWithoutRelationsDto.prototype, "inspectionDue", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], VehicleWithoutRelationsDto.prototype, "expirationDate", void 0);
__decorate([
    Type(() => VehicleModelAudioDto),
    __metadata("design:type", VehicleModelAudioDto)
], VehicleWithoutRelationsDto.prototype, "audio", void 0);
__decorate([
    Type(() => VehicleModelOptionDto),
    __metadata("design:type", VehicleModelOptionDto)
], VehicleWithoutRelationsDto.prototype, "vehicleOption", void 0);
__decorate([
    Type(() => VehicleModelKeyOptionDto),
    __metadata("design:type", VehicleModelKeyOptionDto)
], VehicleWithoutRelationsDto.prototype, "keyOption", void 0);
