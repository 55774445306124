import CloseIcon from '@mui/icons-material/Close';
import FullscreenExitSharpIcon from '@mui/icons-material/FullscreenExitSharp';
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import { Box, Button, Modal, useTheme } from '@mui/material';
import { type FC, useState } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  openAdminDialog: () => void;
};

const AdminToolbar: FC<Props> = ({ open, onClose, openAdminDialog }) => {
  const theme = useTheme();

  const [isFullscreen, setIsFullscreen] = useState(true);

  const handleQuit = () => {
    window.electron.quit();
  };

  const handleOpenSetting = () => {
    onClose();
    openAdminDialog();
  };

  const handleFullscreen = (enter: boolean) => {
    window.electron.setFullscreen(enter);
    setIsFullscreen(enter);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          background: theme.palette.background.paper,
          outline: 'none',
        }}
      >
        <Button
          sx={{
            color: theme.palette.text.primary,
            width: 120,
            height: 100,
          }}
          onClick={handleOpenSetting}
        >
          <TuneSharpIcon sx={{ width: 64, height: 64 }} />
        </Button>
        <Button
          sx={{
            color: theme.palette.text.primary,
            width: 120,
            height: 100,
          }}
          onClick={() => {
            handleFullscreen(!isFullscreen);
          }}
        >
          {isFullscreen ? (
            <FullscreenExitSharpIcon sx={{ width: 76, height: 76 }} />
          ) : (
            <FullscreenSharpIcon sx={{ width: 76, height: 76 }} />
          )}
        </Button>
        <Button
          sx={{
            color: theme.palette.text.primary,
            width: 120,
            height: 100,
          }}
          onClick={handleQuit}
        >
          <CloseIcon sx={{ width: 76, height: 76 }} />
        </Button>
      </Box>
    </Modal>
  );
};

export default AdminToolbar;
