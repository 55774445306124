import {
  Backdrop,
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {
  getActualDropOffAt,
  getReservationDirectPayments,
  ManagerApprovalType,
  type ReservationDto,
} from '@orcar/common';
import { captureException } from '@sentry/electron';
import { type QueryObserverResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import dayjs, { extend } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import logger from 'electron-log';
import { type FC, useCallback, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getApprovedApprovals, getReservation } from '@/apis/reservation.api';
import RadioCheckedIcon from '@/assets/icons/radio_checked.svg?react';
import RadioUncheckedIcon from '@/assets/icons/radio_unchecked.svg?react';
import BottomButtons from '@/components/BottomButtons';
import LoadingDialog from '@/components/LoadingDialog';
import TitleMessage from '@/components/TitleMessage';
import { useLoginInformationState } from '@/hooks/auth.hook';
import { useManagerApproval } from '@/hooks/manager-approval.hook';
import { useConfirmPayment } from '@/hooks/payment.hook';
import { usePrintReceipt } from '@/hooks/print-receipt.hook';
import {
  useGetReservation,
  usePickUpReservation,
} from '@/hooks/reservation.hook';
import ContractSummaryCard from './components/ContractSummaryCard';
import ErrorDialog from './components/ErrorDialog';
import PaymentManagerApprovalDialog from './components/PaymentManagerApprovalDialog';
import Receipt from './components/Receipt';
import { type VehicleReadyData } from '../vehicleReady/VehicleReadyPage';

extend(utc);
extend(timezone);

const DEFAULT_ERROR_MESSAGE = '오류가 발생했어요.\n카운터에 문의해주세요.';

export type PaymentData = {
  paymentMethod: string;
  cardNumber: string;
  installment: string;
  supply: string;
  vat: string;
  amount: string;
};

type Props = {
  reservation: ReservationDto;
  refetch: () => Promise<QueryObserverResult>;
};

const PaymentPageComponent: FC<Props> = ({ reservation, refetch }) => {
  const navigate = useNavigate();

  const { id: releaseManagerId } = useLoginInformationState();

  const { mutateAsync: confirmPayment } = useConfirmPayment();
  const { mutateAsync: pickUpReservation } = usePickUpReservation();

  const [isPaying, setIsPaying] = useState(false);
  const [isPickingUp, setIsPickingUp] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [installment, setInstallment] = useState(0);
  const [isCashPayment, setIsCashPayment] = useState(false);

  const { vehicleFee, extraFee, insuranceFee, extensionFee, prepaymentFee } =
    useMemo(() => getReservationDirectPayments(reservation), [reservation]);

  const totalFee = vehicleFee + extraFee + insuranceFee + extensionFee;
  const payFee = totalFee - prepaymentFee;

  const { start, stop, status } = useManagerApproval(
    ManagerApprovalType.SKIP_PAYMENT,
    reservation.id,
  );

  const { printReceipt, requestPrinterPort } = usePrintReceipt();

  const handleError = (message: string) => {
    setErrorMessage(message);
    setIsError(true);
    return new Error(message);
  };

  const handlePickUp = useCallback(async () => {
    setIsPickingUp(true);

    try {
      try {
        await pickUpReservation({
          id: reservation.id,
          input: {
            releaseManagerId,
          },
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          const message = error.response?.data?.message;

          if (message?.includes('already picked up')) {
            return;
          }

          if (message?.includes('not usable')) {
            throw handleError(
              '대여처리에 실패했습니다.\n카운터에 문의해주세요.',
            );
          }
        }

        captureException(error);
        throw handleError(DEFAULT_ERROR_MESSAGE);
      }
    } finally {
      setIsPickingUp(false);
    }
  }, [releaseManagerId, pickUpReservation, reservation.id]);

  const getApprovedApprovalTypes = useCallback(async (id: string) => {
    try {
      const managerApprovals = await getApprovedApprovals(id);
      return managerApprovals.map((approval) => approval.type);
    } catch (error) {
      captureException(error);
      logger.error(error instanceof Error ? error.stack : error);
      return [];
    }
  }, []);

  const afterPickUp = useCallback(
    async (paymentData: PaymentData | null) => {
      const vehicleReadyData: VehicleReadyData = {
        dispatchArea: reservation.dispatchArea?.name ?? '?',
        vehicleNumber: reservation.assignedVehicle?.vehicleNumber ?? '?',
        contractorName: reservation.contractor.name ?? '',
        driverName: reservation.driver.name,
        pickUpAt: reservation.pickUpAt,
        actualDropOffAt: getActualDropOffAt(reservation),
        vehicleModelName: reservation.vehicleModel.name,
      };

      const managerApprovals = await getApprovedApprovalTypes(reservation.id);

      try {
        await printReceipt(vehicleReadyData, paymentData, managerApprovals);
      } catch (error) {
        captureException(error);
        logger.error(error instanceof Error ? error.stack : error);
      }

      navigate(`/vehicle-ready`, {
        state: {
          vehicleReadyData,
          paymentData,
          managerApprovals,
        },
      });
    },
    [navigate, printReceipt, reservation, getApprovedApprovalTypes],
  );

  const checkPrice = async () => {
    const fetchedReservation = await getReservation(reservation.id);

    const { vehicleFee, extraFee, insuranceFee, extensionFee, prepaymentFee } =
      getReservationDirectPayments(fetchedReservation);

    const totalFee = vehicleFee + extraFee + insuranceFee + extensionFee;
    const fetchedPayFee = totalFee - prepaymentFee;

    if (fetchedPayFee !== payFee) {
      refetch();
      throw handleError('결제 금액이 변경되었습니다.\n다시 시도해주세요.');
    }
  };

  const handlePayment = async (
    reservationId: string,
    amount: number,
    installment: number,
  ) => {
    if (amount < 50000 && installment > 0) {
      handleError(
        '신용카드 할부 거래는\n결제 금액 50,000원 이상 시 가능합니다.',
      );
      setInstallment(0);
      return;
    }

    setIsPaying(true);

    try {
      const result = await window.tPay.requestPayment(amount, installment);

      if (result.ERROR_CHECK_RESULT === 'E') {
        handleError(
          `결제에 실패했습니다.\n(${result.ERROR_CHECK_MESSAGE.trim()})`,
        );
        return;
      }

      const authorizationNumber = result.MESSAGE.slice(0, 12).trim();
      const confirmedAt = result.TRANSTIME;

      try {
        await confirmPayment({
          reservationId,
          authorizationNumber,
          confirmedAt: dayjs.tz('20' + confirmedAt, 'Asia/Seoul').toISOString(),
          acquirer: result.ACQUIER,
          amount,
          installment,
          rawData: JSON.stringify(result),
        });

        return result;
      } catch (error) {
        // 결제 성공하였으나 서버에서 오류 발생
        captureException(error);
        handleError(`오류가 발생했습니다.\n카운터에 문의해주세요.`);
      }
    } catch (error) {
      // 결제 중 오류 발생
      handleError(`결제에 실패했습니다.\n(키오스크 오류)`);
    } finally {
      setIsPaying(false);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '240px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          background: '#f5f5f5',
          paddingTop: '100px',
          paddingX: '80px',
        }}
      >
        <TitleMessage
          message={
            payFee
              ? ['계약 내용 확인 후', '결제를 진행해 주세요.']
              : ['계약 내용을 확인해주세요.']
          }
        />
        <Box sx={{ height: '56px' }} />
        <ContractSummaryCard reservation={reservation} payFee={payFee} />
        {payFee ? (
          <>
            <Box sx={{ height: '32px' }} />
            <Box
              sx={{
                background: '#ffffff',
                border: '1px solid #d4d4d4',
                padding: '40px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography fontSize={40} fontWeight={'bold'}>
                  {'결제 방법'}
                </Typography>
                <Divider sx={{ marginY: '40px' }} />
                <Box>
                  <RadioGroup
                    value={isCashPayment ? 'cash' : 'card'}
                    onChange={(e) => {
                      setIsCashPayment(e.target.value === 'cash');
                    }}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                    }}
                  >
                    <FormControlLabel
                      value='card'
                      control={
                        <Radio
                          icon={<RadioUncheckedIcon />}
                          checkedIcon={<RadioCheckedIcon />}
                        />
                      }
                      label='카드 결제'
                    />
                    <FormControlLabel
                      value='cash'
                      control={
                        <Radio
                          icon={<RadioUncheckedIcon />}
                          checkedIcon={<RadioCheckedIcon />}
                        />
                      }
                      label='현금 결제'
                    />
                  </RadioGroup>
                </Box>
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
      <Receipt
        totalFee={totalFee}
        payFee={payFee}
        isCashPayment={isCashPayment}
        installment={installment}
        onChangeInstallment={(value) => setInstallment(value)}
      />
      <BottomButtons
        label={payFee > 0 ? '결제하기' : '다음'}
        homeButton
        backButton
        onClick={async () => {
          await requestPrinterPort();

          const vat = Math.floor(payFee / 11);
          const supply = payFee - vat;

          let paymentResult: Record<string, string> | undefined;

          if (payFee > 0) {
            try {
              await checkPrice();
            } catch {
              return;
            }

            if (isCashPayment) {
              await start(async () => {
                try {
                  await handlePickUp();
                  await afterPickUp(null);
                } catch {}
              });

              return;
            }

            paymentResult = await handlePayment(
              reservation.id,
              payFee,
              installment,
            );

            if (!paymentResult) {
              return;
            }
          }

          await handlePickUp();
          await afterPickUp(
            paymentResult
              ? {
                  paymentMethod:
                    paymentResult.CARDKBN === 'CK'
                      ? '신용카드'
                      : paymentResult.CARDKBN === 'CH'
                      ? '체크카드'
                      : '카드',
                  cardNumber: paymentResult.TRACK2DATA.trim(),
                  installment: installment ? `${installment}개월` : '일시불',
                  supply: `${supply.toLocaleString()}원`,
                  vat: `${vat.toLocaleString()}원`,
                  amount: `${payFee.toLocaleString()}원`,
                }
              : null,
          );
        }}
      />
      <PaymentManagerApprovalDialog
        open={status === 'WAITING'}
        onClosed={() => {
          stop();
        }}
      />
      <Backdrop open={isPaying} />
      <LoadingDialog open={isPickingUp} content='잠시만 기다려주세요.' />
      <ErrorDialog
        open={isError}
        message={errorMessage}
        onConfirm={() => {
          setIsError(false);
        }}
      />
    </Box>
  );
};

const PaymentPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: reservation, isLoading, refetch } = useGetReservation({ id });

  if (isLoading) {
    return <></>;
  }

  if (!reservation) {
    return <Navigate to='/' replace />;
  }

  return <PaymentPageComponent reservation={reservation} refetch={refetch} />;
};

export default PaymentPage;
