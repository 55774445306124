var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BankCardDto } from '../../../classes/bank-card';
import { ReservationPaymentForSettlementDto } from '../../../classes/reservation-payment/response/reservation-payment-for-settlement.dto';
import { VendorWithoutRelationsDto } from '../../../classes/vendor';
import { SettlementBaseDto } from './settlement-base.dto';
export class SettlementWithPaymentsDto extends SettlementBaseDto {
    constructor(initializer) {
        super(initializer);
        this.vendor = initializer.vendor;
        this.bankCard = initializer.bankCard;
        this.payments = initializer.payments;
    }
}
__decorate([
    Type(() => VendorWithoutRelationsDto),
    __metadata("design:type", VendorWithoutRelationsDto)
], SettlementWithPaymentsDto.prototype, "vendor", void 0);
__decorate([
    Type(() => BankCardDto),
    __metadata("design:type", BankCardDto)
], SettlementWithPaymentsDto.prototype, "bankCard", void 0);
__decorate([
    Type(() => ReservationPaymentForSettlementDto),
    __metadata("design:type", Array)
], SettlementWithPaymentsDto.prototype, "payments", void 0);
