import styled from '@emotion/styled';
import React from 'react';
import { formatPhoneNumber, translateDriverLicenseType } from '../../utils';
import ContractTable from './ContractTable';
const TableHeader = styled.th `
  width: 50%;
  height: 20px;
  background: #eeeeee;
  color: #868686;
  text-align: left !important;
`;
const DriverInfo = ({ reservation }) => {
    var _a, _b, _c, _d, _e;
    const { contractor, driver } = reservation;
    const contractorLicenseImageUrl = contractor.driverLicenseImageUrl;
    const driverLicenseImageUrl = driver.driverLicenseImageUrl;
    const getDriverInfo = (driverDto) => {
        var _a;
        if (!driverDto.name) {
            return '';
        }
        return `${driverDto.name} ${driverDto.driverLicenseType
            ? translateDriverLicenseType(driverDto.driverLicenseType)
            : ''} ${(_a = driverDto.driverLicenseValidDate) !== null && _a !== void 0 ? _a : ''}`;
    };
    return (React.createElement(ContractTable, null,
        React.createElement("tbody", null,
            React.createElement("tr", null,
                React.createElement(TableHeader, { colSpan: 2 },
                    "\u00A0\uC784\uCC28\uC778(\uC81C1\uC6B4\uC804\uC790)",
                    contractorLicenseImageUrl && (React.createElement("span", { style: {
                            fontWeight: 'normal',
                        } },
                        "\u00A0: ", (_a = contractor.name) !== null && _a !== void 0 ? _a : '',
                        "\u00A0(",
                        contractor.phoneNumber
                            ? formatPhoneNumber(contractor.phoneNumber)
                            : '',
                        ")"))),
                React.createElement(TableHeader, { colSpan: 2 },
                    "\u00A0\uC81C2\uC6B4\uC804\uC790",
                    driverLicenseImageUrl && (React.createElement("span", { style: {
                            fontWeight: 'normal',
                        } },
                        "\u00A0: ", (_b = driver.name) !== null && _b !== void 0 ? _b : '',
                        "\u00A0(",
                        driver.phoneNumber
                            ? formatPhoneNumber(driver.phoneNumber)
                            : '',
                        ")")))),
            React.createElement("tr", { className: 'fixed-row' },
                React.createElement("th", { style: { width: '7.5%' } }, contractorLicenseImageUrl ? '면허정보' : '이름'),
                React.createElement("td", null, contractorLicenseImageUrl
                    ? getDriverInfo(contractor)
                    : (_c = contractor.name) !== null && _c !== void 0 ? _c : ''),
                React.createElement("th", { style: { width: '7.5%' } }, driverLicenseImageUrl ? '면허정보' : '이름'),
                React.createElement("td", null, driverLicenseImageUrl ? getDriverInfo(driver) : driver.name)),
            React.createElement("tr", { className: 'fixed-row' },
                contractorLicenseImageUrl ? (React.createElement("td", { rowSpan: 3, colSpan: 2, style: {
                        padding: 0,
                        verticalAlign: 'top',
                    } },
                    React.createElement("div", { style: {
                            textAlign: 'center',
                            padding: 5,
                        } },
                        React.createElement("img", { style: {
                                maxHeight: 350,
                                maxWidth: 350,
                            }, src: contractorLicenseImageUrl })))) : (React.createElement(React.Fragment, null,
                    React.createElement("th", null, "\uC5F0\uB77D\uCC98"),
                    React.createElement("td", null, contractor.phoneNumber
                        ? formatPhoneNumber(contractor.phoneNumber)
                        : ''))),
                driverLicenseImageUrl ? (React.createElement("td", { rowSpan: 3, colSpan: 2, style: {
                        padding: 0,
                        verticalAlign: 'top',
                    } },
                    React.createElement("div", { style: {
                            textAlign: 'center',
                            padding: 5,
                        } },
                        React.createElement("img", { style: {
                                maxHeight: 350,
                                maxWidth: 350,
                            }, src: driverLicenseImageUrl })))) : (React.createElement(React.Fragment, null,
                    React.createElement("th", null, "\uC5F0\uB77D\uCC98"),
                    React.createElement("td", null, driver.phoneNumber
                        ? formatPhoneNumber(driver.phoneNumber)
                        : '')))),
            React.createElement("tr", { className: 'fixed-row' },
                !contractorLicenseImageUrl && (React.createElement(React.Fragment, null,
                    React.createElement("th", null, "\uBA74\uD5C8\uC815\uBCF4"),
                    React.createElement("td", null, getDriverInfo(contractor)))),
                !driverLicenseImageUrl && (React.createElement(React.Fragment, null,
                    React.createElement("th", null, "\uBA74\uD5C8\uC815\uBCF4"),
                    React.createElement("td", null, getDriverInfo(driver))))),
            React.createElement("tr", null,
                !contractorLicenseImageUrl && (React.createElement(React.Fragment, null,
                    React.createElement("th", null, "\uC8FC\uC18C"),
                    React.createElement("td", null, (_d = contractor.address) !== null && _d !== void 0 ? _d : ''))),
                !driverLicenseImageUrl && (React.createElement(React.Fragment, null,
                    React.createElement("th", null, "\uC8FC\uC18C"),
                    React.createElement("td", null, (_e = driver.address) !== null && _e !== void 0 ? _e : '')))))));
};
export default DriverInfo;
