import { Box, Button, Typography } from '@mui/material';
import { formatPhoneNumber } from '@orcar/common';
import { type FC } from 'react';
import theme from '@/app/theme';
import CancelIcon from '@/assets/icons/cancel.svg?react';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const PhoneNumberInput: FC<Props> = ({ value, onChange }) => {
  return (
    <Box
      sx={{
        height: '156px',
        paddingX: '40px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid',
        borderColor: theme.palette.divider,
      }}
    >
      <Typography
        sx={{
          flexGrow: 1,
          fontSize: 64,
          fontWeight: 'regular',
        }}
      >
        {formatPhoneNumber(value).replace(/-/g, ' - ')}
      </Typography>
      {value && (
        <Button
          sx={{
            width: '64px',
            height: '64px',
            ':hover': {
              background: 'transparent',
            },
          }}
          endIcon={<CancelIcon />}
          onClick={() => {
            onChange('');
          }}
        />
      )}
    </Box>
  );
};

export default PhoneNumberInput;
