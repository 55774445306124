var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Transform } from 'class-transformer';
import { IsArray, IsOptional, ValidateNested } from 'class-validator';
import { OmitType, PartialType } from '../../../../utils';
import { UpdateDetailForMaintenanceDto } from './update-detail-for-maintenance.dto';
import { CreateDetailForMaintenanceDto } from '../create/create-detail-for-maintenance.dto';
import { CreateVehicleMaintenanceDto } from '../create/create-vehicle-maintenance.dto';
export class UpdateVehicleMaintenanceDto extends PartialType(OmitType(CreateVehicleMaintenanceDto, ['details'])) {
}
__decorate([
    IsArray(),
    IsOptional(),
    ValidateNested({ each: true }),
    Transform(({ value }) => {
        return value.map((item) => {
            if (item && 'id' in item) {
                const dto = new UpdateDetailForMaintenanceDto();
                dto.id = item.id;
                dto.type = item.type;
                dto.partSupplier = item.partSupplier;
                dto.partPrice = item.partPrice;
                dto.content = item.content;
                return dto;
            }
            const dto = new CreateDetailForMaintenanceDto();
            dto.type = item.type;
            dto.partSupplier = item.partSupplier;
            dto.partPrice = item.partPrice;
            dto.content = item.content;
            return dto;
        });
    }),
    __metadata("design:type", Array)
], UpdateVehicleMaintenanceDto.prototype, "details", void 0);
