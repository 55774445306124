import dayjs from 'dayjs';
import { calculateDDay } from './date.util';
import { getActualDropOffAt, getFeeList, getPaymentList, translateInsuranceOption, } from './reservation.util';
export const formatDateForMessaging = (date, varName) => {
    const target = dayjs(date);
    if (!target.isValid()) {
        return '';
    }
    switch (varName[varName.length - 1]) {
        case '2':
            return target.format('MM-DD HH:mm');
        case '3':
            return target.format('M/D HH:mm');
        default:
            return target.format('M월 D일 HH시 mm분');
    }
};
export const formatPhoneNumber = (value) => {
    if (!value)
        return '';
    value = value.replace(/\D/g, '');
    if (value.startsWith('1')) {
        value = value.replace(/^(\d{0,4})/, '$1-');
    }
    else if (value.startsWith('01')) {
        if (value.length >= 11) {
            value = value.replace(/^(\d{3})(\d{4})/, '$1-$2-');
        }
        else if (value.length >= 10) {
            value = value.replace(/^(\d{3})(\d{3})/, '$1-$2-');
        }
        else {
            value = value.replace(/^(\d{3})(\d{0,4})/, '$1-$2-');
        }
    }
    else if (value.startsWith('02')) {
        if (value.length >= 10) {
            value = value.replace(/^02(\d{4})/, '02-$1-');
        }
        else {
            value = value.replace(/^02(\d{0,3})/, '02-$1-');
        }
    }
    else {
        if (value.length >= 11) {
            value = value.replace(/^(\d{3})(\d{4})/, '$1-$2-');
        }
        else {
            value = value.replace(/^(\d{3})(\d{0,3})/, '$1-$2-');
        }
    }
    return value.replace(/-+$/g, '');
};
export const formatPhoneNumberWithAsterisk = (value) => {
    value = value.replace(/[^\d*]/g, '');
    if (value.startsWith('1')) {
        value = value.replace(/^(\d{0,4})/, '$1-');
    }
    else if (value.startsWith('01')) {
        if (value.length >= 11) {
            value = value.replace(/^(01[\d*]{1})([\d*]{4})/, '$1-$2-');
        }
        else if (value.length >= 10) {
            value = value.replace(/^(01[\d*]{1})([\d*]{3})/, '$1-$2-');
        }
        else {
            value = value.replace(/^(01[\d*]{1})([\d*]{0,4})/, '$1-$2-');
        }
    }
    else if (value.startsWith('02')) {
        if (value.length >= 10) {
            value = value.replace(/^(02)([\d*]{4})/, '$1-$2-');
        }
        else {
            value = value.replace(/^(02)([\d*]{0,3})/, '$1-$2-');
        }
    }
    else {
        if (value.length >= 11) {
            value = value.replace(/^(\d{3})([\d*]{4})/, '$1-$2-');
        }
        else {
            value = value.replace(/^(\d{3})([\d*]{0,3})/, '$1-$2-');
        }
    }
    return value.replace(/-+$/g, '');
};
const getRawValueFromReservation = (varName, reservation) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const paymentList = getPaymentList(reservation);
    const feeList = getFeeList(reservation);
    switch (varName) {
        case '#{고객명}':
        case '#{예약자명}':
            return reservation.customerName1;
        case '#{거래처}':
            return reservation.vendor.name;
        case '#{대여일시}':
        case '#{대여일시1}':
        case '#{대여일시2}':
        case '#{대여일시3}':
            return reservation.pickUpAt;
        case '#{반납일시}':
        case '#{반납일시1}':
        case '#{반납일시2}':
        case '#{반납일시3}':
            return getActualDropOffAt(reservation);
        case '#{대여장소}':
            return (_a = reservation.pickUpGarage) === null || _a === void 0 ? void 0 : _a.name;
        case '#{반납장소}':
            return (_c = (_b = reservation.returnGarage) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : (_d = reservation.dropOffGarage) === null || _d === void 0 ? void 0 : _d.name;
        case '#{차량모델}':
        case '#{모델명}':
            return reservation.vehicleModel.name;
        case '#{요금합계}':
            return feeList.totalFee.toLocaleString();
        case '#{직불예정}':
            return paymentList.totalDirectPayment.toLocaleString();
        case '#{고객납입}':
            return paymentList.feeCustomerPaid.toLocaleString();
        case '#{고객미납}':
            return paymentList.feeCustomerNotPaid.toLocaleString();
        case '#{계약자명}':
            return reservation.contractor.name;
        case '#{운전자명}':
            return reservation.driver.name;
        case '#{입금예정일}':
        case '#{입금예정일1}':
        case '#{입금예정일2}':
        case '#{입금예정일3}':
            return reservation.expectedDepositDate
                ? dayjs(reservation.expectedDepositDate).format('M월 D일')
                : '날짜없음';
        case '#{입금예정금액}':
            return (_e = reservation.expectedDepositFee) === null || _e === void 0 ? void 0 : _e.toLocaleString();
        case '#{예약번호}':
            return reservation.id;
        case '#{차량번호}':
        case '#{대여차량번호}':
            return (_f = reservation.reservedVehicle) === null || _f === void 0 ? void 0 : _f.vehicleNumber;
        case '#{운전자등록URL}':
            return `https://rent.orcar.kr`; // TODO: 운전자 등록 URL
        case '#{연장요금}':
            return feeList.extensionFee.toLocaleString();
        case '#{연장시간}':
            return (_g = reservation.extensions) === null || _g === void 0 ? void 0 : _g.reduce((acc, extension) => acc + extension.usageTime, 0);
        case '#{모델연료비}':
            return null; // TODO: 모델 연료비
        case '#{자차옵션}':
            return translateInsuranceOption((_h = reservation.insuranceOption) !== null && _h !== void 0 ? _h : 0);
        case '#{자차보험}':
            return (_j = reservation.insurances[0]) === null || _j === void 0 ? void 0 : _j.insurance.name;
        case '#{계약서URL}':
            return `https://contract-viewer.orcar.kr/${reservation.id}`;
        case '#{반납완료내용}':
            return `${reservation.vehicleModel.name}(${((_k = reservation.reservedVehicle) === null || _k === void 0 ? void 0 : _k.vehicleNumber) || ''}) 반납처리가 완료되었습니다. 입고인: ${((_l = reservation.returnManager) === null || _l === void 0 ? void 0 : _l.name) || ''}`;
        case '#{지정차량번호}':
            return (_m = reservation.assignedVehicle) === null || _m === void 0 ? void 0 : _m.vehicleNumber;
        case '#{배차구역}':
            return (_o = reservation.dispatchArea) === null || _o === void 0 ? void 0 : _o.name;
        case '#{입고인}':
            return (_p = reservation.returnManager) === null || _p === void 0 ? void 0 : _p.name;
        case '#{슈어체크인URL}':
            return `https://link.sure.orcar.kr/9mFj/checkIn?deep_link_sub1=${reservation.id}`;
        case '#{디데이}':
            const diff = calculateDDay(reservation.pickUpAt);
            return diff > 0 ? `D-${diff}` : diff < 0 ? `D+${-diff}` : 'D-Day';
        default:
            return null;
    }
};
const getValueFromReservation = (varName, reservation) => {
    const rawValue = getRawValueFromReservation(varName, reservation);
    if (!rawValue) {
        return '';
    }
    if (varName.includes('일시')) {
        return formatDateForMessaging(rawValue, varName);
    }
    return rawValue.toString();
};
/**
 * Template을 파싱하여 MessageTemplatePart 배열로 반환합니다.
 * 변수는 예약에서 가져온 값으로 입력됩니다.
 */
export const parseTemplateWithReservationValues = (template, reservation) => {
    const result = [];
    let lastIndex = 0;
    template.replace(/#\{.*?\}/g, (varName, index) => {
        if (index > lastIndex) {
            result.push({ value: template.slice(lastIndex, index) });
        }
        result.push({
            varName,
            value: getValueFromReservation(varName, reservation),
        });
        lastIndex = index + varName.length;
        return varName;
    });
    if (lastIndex < template.length) {
        result.push({ value: template.slice(lastIndex) });
    }
    return result;
};
/**
 * Template을 파싱하여 MessageTemplatePart 배열로 반환합니다.
 * 변수명이 Default value로서 입력됩니다.
 */
export const parseTemplate = (template) => {
    const result = [];
    let lastIndex = 0;
    template.replace(/#\{(.*?)\}/g, (varName, value, index) => {
        if (index > lastIndex) {
            result.push({ value: template.slice(lastIndex, index) });
        }
        result.push({ varName, value });
        lastIndex = index + varName.length;
        return varName;
    });
    if (lastIndex < template.length) {
        result.push({ value: template.slice(lastIndex) });
    }
    return result;
};
/**
 * 최종적으로 발송할 메시지 content를 반환합니다.
 */
export const mergeMessageParts = (parts) => parts.map((part) => part.value).join('');
