// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import './editor.css';

type ViewerProps = {
  content: string;
  style?: React.CSSProperties;
};

const Viewer = ({ content, style }: ViewerProps) => {
  const quillRef = useRef<ReactQuill | null>(null);

  return (
    <ReactQuill
      ref={quillRef}
      theme='bubble'
      value={content}
      readOnly={true}
      style={style}
    />
  );
};

export default Viewer;
