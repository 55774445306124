export var Manufacturer;
(function (Manufacturer) {
    Manufacturer["KIA"] = "KIA";
    Manufacturer["SAMSUNG"] = "SAMSUNG";
    Manufacturer["SSANGYONG"] = "SSANGYONG";
    Manufacturer["HYUNDAI"] = "HYUNDAI";
    Manufacturer["GM"] = "GM";
    Manufacturer["IMPORTED"] = "IMPORTED";
    Manufacturer["MERCEDES"] = "MERCEDES";
    Manufacturer["BMW"] = "BMW";
    Manufacturer["AUDI"] = "AUDI";
    Manufacturer["TESLA"] = "TESLA";
    Manufacturer["VOLKSWAGEN"] = "VOLKSWAGEN";
    Manufacturer["VOLVO"] = "VOLVO";
})(Manufacturer || (Manufacturer = {}));
