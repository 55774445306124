import {
  type ContractSectionDto,
  type GetManyContractSectionsQueryDto,
  type Paginated,
} from '@orcar/common';
import axiosClient from './axiosClient';

export const getContractSections = async (
  params?: Partial<GetManyContractSectionsQueryDto>,
): Promise<Paginated<ContractSectionDto>> => {
  const response = await axiosClient.get('/contract-sections', { params });

  return response.data;
};
