export const RESERVATION_VALIDATE_LICENSE_ERROR_CODE = {
    VALIDATION_FAILED: 'VALIDATION_FAILED',
    AGE_LIMIT: 'AGE_LIMIT',
    EXPERIENCE_LIMIT: 'EXPERIENCE_LIMIT',
    EXPERIENCE_AMBIGUOUS: 'EXPERIENCE_AMBIGUOUS',
    LICENSE_TYPE: 'LICENSE_TYPE',
    INSUFFICIENT_LICENSE_INFO: 'INSUFFICIENT_LICENSE_INFO',
};
export const BLACK_LIST_CHECK_ERROR_CODE = {
    // 필수 파라미터 누락
    MISSING_REQUIRED_PARAMETER: 'MISSING_REQUIRED_PARAMETER',
};
