import { Cancel } from '@mui/icons-material';
import {
  Box,
  IconButton,
  TableCell,
  type TableCellProps,
  Typography,
} from '@mui/material';

type KeypadInputProps = TableCellProps & {
  value: string;
  onDelete: () => void;
  placeholder?: string;
  nonTable?: boolean;
};

const KeypadInput = ({
  value,
  onDelete,
  placeholder,
  nonTable,
  ...BoxProps
}: KeypadInputProps) => {
  const { sx, ...rest } = BoxProps;
  const showPlaceholder = value.length === 0 && placeholder;

  return nonTable ? (
    <Box
      sx={{
        height: 100,
        padding: '26px 36px 26px 40px',
        border: '1px solid #D4D4D4',
        bgcolor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...sx,
      }}
      {...rest}
    >
      <Typography
        fontSize={40}
        sx={{
          color: showPlaceholder ? 'grey.50' : 'text.primary',
        }}
      >
        {showPlaceholder ? placeholder : value}
      </Typography>
      <IconButton
        sx={{
          width: 48,
          height: 48,
        }}
        onClick={onDelete}
      >
        <Cancel sx={{ width: 40, height: 40, color: 'grey.200' }} />
      </IconButton>
    </Box>
  ) : (
    <TableCell
      sx={{
        height: 100,
        padding: '26px 36px 26px 40px',
        border: '1px solid #D4D4D4',
        bgcolor: 'white',
        ...sx,
      }}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          fontSize={40}
          sx={{
            color: showPlaceholder ? 'grey.50' : 'text.primary',
          }}
        >
          {showPlaceholder ? placeholder : value}
        </Typography>
        <IconButton
          sx={{
            width: 48,
            height: 48,
          }}
          onClick={onDelete}
        >
          <Cancel sx={{ width: 40, height: 40, color: 'grey.200' }} />
        </IconButton>
      </Box>
    </TableCell>
  );
};

export default KeypadInput;
