import { DriverLicenseType } from '../enums';
export const REGIONAL_CODE = {
    서울: '11',
    부산: '12',
    경기: '13',
    경기남부: '13',
    강원: '14',
    충북: '15',
    충남: '16',
    전북: '17',
    전남: '18',
    경북: '19',
    경남: '20',
    제주: '21',
    대구: '22',
    인천: '23',
    광주: '24',
    대전: '25',
    울산: '26',
    경기북부: '28',
};
export const DRIVER_LICENSE_TYPE = {
    [DriverLicenseType.FIRST_LARGE]: '11',
    [DriverLicenseType.FIRST_ORDINARY]: '12',
    [DriverLicenseType.FIRST_SMALL]: '13',
    [DriverLicenseType.FIRST_LARGE_TRAILER]: '14',
    [DriverLicenseType.FIRST_WRECKER]: '15',
    [DriverLicenseType.FIRST_SMALL_TRAILER]: '16',
    [DriverLicenseType.SECOND_ORDINARY]: '32',
    [DriverLicenseType.SECOND_SMALL]: '33',
    [DriverLicenseType.SECOND_MOTORIZED_BICYCLE]: '38',
};
