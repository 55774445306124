import { Box, Dialog, Typography } from '@mui/material';
import SureImage from '@/assets/images/sure_checkIn_confirmed.png';
import { SureYellowButton } from '@/components/StyledMuiComponent';

type ConfirmSureUserDialogProps = {
  open: boolean;
  onConfirm: () => void;
};

const ConfirmSureUserDialog = ({
  onConfirm,
  open,
}: ConfirmSureUserDialogProps) => {
  return (
    <Dialog open={open}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography fontSize={40} fontWeight={600}>
            <span style={{ fontWeight: 800 }}>슈어 </span>
            사전 체크인 예약건
          </Typography>
          <Typography fontSize={32} color='GrayText'>
            사전 체크인 한 예약을 정상적으로 확인했습니다!
          </Typography>
        </Box>
        <img width={760} height={512} src={SureImage} />
        <SureYellowButton
          variant='contained'
          fullWidth
          sx={{
            fontSize: 40,
          }}
          onClick={onConfirm}
        >
          계약내용 확인
        </SureYellowButton>
      </Box>
    </Dialog>
  );
};

export default ConfirmSureUserDialog;
