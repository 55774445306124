var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseStringIdDataDto } from '../../../classes/common';
import { GarageDto } from '../../../classes/garage';
import { VendorWithoutRelationsDto } from '../../../classes/vendor/response/vendor-without-relations.dto';
import { ReservationExtensionDto } from './reservation-extension.dto';
export class ReservationForChartForVendorDto extends BaseStringIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.pickUpAt = initializer.pickUpAt;
        this.pickUpGarageId = initializer.pickUpGarageId;
        this.pickUpGarage = initializer.pickUpGarage;
        this.dropOffAt = initializer.dropOffAt;
        this.dropOffGarageId = initializer.dropOffGarageId;
        this.dropOffGarage = initializer.dropOffGarage;
        this.reservedVehicleId = initializer.reservedVehicleId;
        this.state = initializer.state;
        this.managerId = initializer.managerId;
        this.vendor = initializer.vendor;
        this.vendorId = initializer.vendorId;
        this.email = initializer.email;
        this.customerName1 = initializer.customerName1;
        this.customerName2 = initializer.customerName2;
        this.customerPhoneNumber1 = initializer.customerPhoneNumber1;
        this.customerPhoneNumber2 = initializer.customerPhoneNumber2;
        this.returnDate = initializer.returnDate;
        this.extensions = initializer.extensions;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationForChartForVendorDto.prototype, "pickUpAt", void 0);
__decorate([
    Type(() => GarageDto),
    __metadata("design:type", Object)
], ReservationForChartForVendorDto.prototype, "pickUpGarage", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationForChartForVendorDto.prototype, "dropOffAt", void 0);
__decorate([
    Type(() => GarageDto),
    __metadata("design:type", Object)
], ReservationForChartForVendorDto.prototype, "dropOffGarage", void 0);
__decorate([
    Type(() => VendorWithoutRelationsDto),
    __metadata("design:type", VendorWithoutRelationsDto)
], ReservationForChartForVendorDto.prototype, "vendor", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationForChartForVendorDto.prototype, "returnDate", void 0);
__decorate([
    Type(() => ReservationExtensionDto),
    __metadata("design:type", Array)
], ReservationForChartForVendorDto.prototype, "extensions", void 0);
