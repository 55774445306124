var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BankCardDto } from '../../../classes/bank-card';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { ReservationWithoutOneToManyRelationsDto } from '../../../classes/reservation/response/reservation-without-relations.dto';
import { SettlementWithoutRelationsDto } from '../../../classes/settlement/response/settlement-without-relations.dto';
import { UserWithoutRelationsDto } from '../../../classes/user/response/user-without-relations.dto';
export class ReservationPaymentDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.type = initializer.type;
        this.category = initializer.category;
        this.date = initializer.date;
        this.reservation = initializer.reservation;
        this.reservationId = initializer.reservationId;
        this.settlementId = initializer.settlementId;
        this.bankCard = initializer.bankCard;
        this.bankCardId = initializer.bankCardId;
        this.fee = initializer.fee;
        this.manager = initializer.manager;
        this.managerId = initializer.managerId;
        this.memo = initializer.memo;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationPaymentDto.prototype, "date", void 0);
__decorate([
    Type(() => ReservationWithoutOneToManyRelationsDto),
    __metadata("design:type", ReservationWithoutOneToManyRelationsDto)
], ReservationPaymentDto.prototype, "reservation", void 0);
__decorate([
    Type(() => SettlementWithoutRelationsDto),
    __metadata("design:type", Object)
], ReservationPaymentDto.prototype, "settlement", void 0);
__decorate([
    Type(() => BankCardDto),
    __metadata("design:type", BankCardDto)
], ReservationPaymentDto.prototype, "bankCard", void 0);
__decorate([
    Type(() => UserWithoutRelationsDto),
    __metadata("design:type", UserWithoutRelationsDto)
], ReservationPaymentDto.prototype, "manager", void 0);
