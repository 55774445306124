var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { FeeGradeWithoutRelationsDto } from '../../../classes/fee-grade/response/fee-grade-without-relations.dto';
import { FeeInfoDto } from './fee-info.dto';
export class FeeDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.startDate = initializer.startDate;
        this.endDate = initializer.endDate;
        this.feeInfos = initializer.feeInfos;
        this.feeGrade = initializer.feeGrade;
        this.feeGradeId = initializer.feeGradeId;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], FeeDto.prototype, "startDate", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], FeeDto.prototype, "endDate", void 0);
__decorate([
    Type(() => FeeInfoDto),
    __metadata("design:type", Array)
], FeeDto.prototype, "feeInfos", void 0);
__decorate([
    Type(() => FeeGradeWithoutRelationsDto),
    __metadata("design:type", FeeGradeWithoutRelationsDto)
], FeeDto.prototype, "feeGrade", void 0);
