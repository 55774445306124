import { Typography } from '@mui/material';

const TitleMessage = ({ message }: { message: string | string[] }) => {
  return (
    <>
      {typeof message === 'string' ? (
        <Typography
          sx={{
            fontSize: 64,
            fontWeight: 'bold',
          }}
          lineHeight={'84px'}
        >
          {message}
        </Typography>
      ) : (
        message.map((line) => (
          <Typography
            key={line}
            sx={{
              fontSize: 64,
              fontWeight: 'bold',
            }}
            lineHeight={'84px'}
          >
            {line}
          </Typography>
        ))
      )}
    </>
  );
};

export default TitleMessage;
