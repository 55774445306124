import {
  type Dispatch,
  type FC,
  type MouseEvent as ReactMouseEvent,
  type TouchEvent as ReactTouchEvent,
  type RefObject,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';

type Props = {
  canvasRef: RefObject<HTMLCanvasElement>;
  setIsDrawn: Dispatch<SetStateAction<boolean>>;
};

const AutographCanvas: FC<Props> = ({ canvasRef, setIsDrawn }) => {
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      const context = canvas.getContext('2d');

      if (context) {
        context.lineCap = 'round';
        context.strokeStyle = 'black';
        context.lineWidth = 5;
      }
    }
  }, [canvasRef]);

  const getOffset = (
    event: MouseEvent | TouchEvent,
  ): { offsetX: number; offsetY: number } => {
    const target = event.target as HTMLCanvasElement;
    const rect = target.getBoundingClientRect();

    if ('touches' in event) {
      return {
        offsetX: event.touches[0].clientX - rect.left,
        offsetY: event.touches[0].clientY - rect.top,
      };
    } else {
      return {
        offsetX: event.clientX - rect.left,
        offsetY: event.clientY - rect.top,
      };
    }
  };

  const startDrawing = (
    event:
      | ReactMouseEvent<HTMLCanvasElement>
      | ReactTouchEvent<HTMLCanvasElement>,
  ) => {
    setIsDrawing(true);
    setIsDrawn(true);

    const context = canvasRef.current?.getContext('2d');

    if (context) {
      const { offsetX, offsetY } = getOffset(event.nativeEvent);
      context.beginPath();
      context.moveTo(offsetX, offsetY);
    }
  };

  const endDrawing = () => {
    setIsDrawing(false);

    const context = canvasRef.current?.getContext('2d');

    if (context) {
      context.closePath();
    }
  };

  const draw = (
    event:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>,
  ) => {
    if (!isDrawing) {
      return;
    }

    const context = canvasRef.current?.getContext('2d');

    if (context) {
      const { offsetX, offsetY } = getOffset(event.nativeEvent);

      context.lineTo(offsetX, offsetY);
      context.stroke();
    }
  };

  return (
    <canvas
      ref={canvasRef}
      style={{ touchAction: 'none', width: '100%', height: '100%' }}
      onMouseDown={startDrawing}
      onMouseUp={endDrawing}
      onMouseOut={endDrawing}
      onMouseMove={draw}
      onTouchStart={startDrawing}
      onTouchEnd={endDrawing}
      onTouchCancel={endDrawing}
      onTouchMove={draw}
    />
  );
};

export default AutographCanvas;
