import { Box, Button, Dialog, DialogTitle, Typography } from '@mui/material';
import React, { type ReactNode } from 'react';
import { GreyButton } from './StyledMuiComponent';

type DialogProps = {
  open: boolean;
  confirmButtonLabel?: string;
  closeButtonLabel?: string;
  isConfirmButtonLeft?: boolean;
  title: string | string[];
  onClose: () => void;
  onConfirm: () => void;
  onClickOutside?: () => void;
  children?: ReactNode;
};

const ConfirmDialog = (props: DialogProps) => {
  const {
    open,
    confirmButtonLabel = '확인',
    closeButtonLabel = '취소',
    isConfirmButtonLeft = false,
    title = '알림',
    children,
    onClose,
    onConfirm,
    onClickOutside,
  } = props;

  return (
    <Dialog open={open} onClose={onClickOutside ?? onClose}>
      {children ? (
        <>
          <DialogTitle
            sx={{
              padding: '0px',
              margin: '0px',
            }}
          >
            <Typography fontSize={40} fontWeight={'bold'}>
              {typeof title === 'string'
                ? title
                : title?.map((text, index) => (
                    <React.Fragment key={index}>
                      {text}
                      <br />
                    </React.Fragment>
                  ))}
            </Typography>
          </DialogTitle>
          {children}
        </>
      ) : (
        <DialogTitle
          sx={{
            padding: '0px',
            marginTop: '50px',
            marginBottom: '90px',
          }}
        >
          <Typography fontSize={40} fontWeight={'bold'}>
            {typeof title === 'string'
              ? title
              : title?.map((text, index) => (
                  <React.Fragment key={index}>
                    {text}
                    <br />
                  </React.Fragment>
                ))}
          </Typography>
        </DialogTitle>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          columnGap: '20px',
          width: '100%',
        }}
      >
        {isConfirmButtonLeft && (
          <Button variant='contained' fullWidth onClick={onConfirm}>
            <Typography fontSize={40} fontWeight={'medium'}>
              {confirmButtonLabel}
            </Typography>
          </Button>
        )}
        <GreyButton variant='contained' fullWidth onClick={onClose}>
          <Typography fontSize={40} fontWeight={'medium'}>
            {closeButtonLabel}
          </Typography>
        </GreyButton>
        {!isConfirmButtonLeft && (
          <Button variant='contained' fullWidth onClick={onConfirm}>
            <Typography fontSize={40} fontWeight={'medium'}>
              {confirmButtonLabel}
            </Typography>
          </Button>
        )}
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;
