var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
export class ExtensionStatisticDto {
    constructor(initializer) {
        this.vehicleModelId = initializer.vehicleModelId;
        this.vehicleModelName = initializer.vehicleModelName;
        this.usageTime = initializer.usageTime;
        this.pickUpAt = initializer.pickUpAt;
        this.fee = initializer.fee;
        this.reservationId = initializer.reservationId;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ExtensionStatisticDto.prototype, "pickUpAt", void 0);
