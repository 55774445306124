import { type AxiosException, type CompanySettingDto } from '@orcar/common';
import { useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { getSetting } from '@/apis/company-setting.api';

export const useGetSetting = () => {
  return useQuery<CompanySettingDto, AxiosError<AxiosException>>({
    queryKey: ['setting'],
    queryFn: getSetting,
  });
};
