var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { FeeGradeWithoutRelationsDto } from '../../../classes/fee-grade/response/fee-grade-without-relations.dto';
import { PreallocationWithoutRelationsDto } from '../../../classes/preallocation/response/preallocation-without-relations.dto';
import { UserWithoutRelationsDto } from '../../../classes/user/response/user-without-relations.dto';
import { VendorAuthorityDto } from './vendor-authority.dto';
export class VendorDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.name = initializer.name;
        this.type = initializer.type;
        this.managerName = initializer.managerName;
        this.phoneNumber1 = initializer.phoneNumber1;
        this.phoneNumber2 = initializer.phoneNumber2;
        this.fax = initializer.fax;
        this.email = initializer.email;
        this.commission1 = initializer.commission1;
        this.commission2 = initializer.commission2;
        this.insuranceGrade = initializer.insuranceGrade;
        this.authority = initializer.authority;
        this.calculationMethod = initializer.calculationMethod;
        this.rate = initializer.rate;
        this.memo = initializer.memo;
        this.feeGrade = initializer.feeGrade;
        this.feeGradeId = initializer.feeGradeId;
        this.users = initializer.users;
        this.preallocations = initializer.preallocations;
        this.updateDeadlineHours = initializer.updateDeadlineHours;
        this.updateDeadlineHoursForApi = initializer.updateDeadlineHoursForApi;
        this.defaultUpdateDeadlineHours = initializer.defaultUpdateDeadlineHours;
        this.defaultUpdateDeadlineHoursForApi =
            initializer.defaultUpdateDeadlineHoursForApi;
    }
}
__decorate([
    Type(() => VendorAuthorityDto),
    __metadata("design:type", VendorAuthorityDto)
], VendorDto.prototype, "authority", void 0);
__decorate([
    Type(() => FeeGradeWithoutRelationsDto),
    __metadata("design:type", FeeGradeWithoutRelationsDto)
], VendorDto.prototype, "feeGrade", void 0);
__decorate([
    Type(() => UserWithoutRelationsDto),
    __metadata("design:type", Array)
], VendorDto.prototype, "users", void 0);
__decorate([
    Type(() => PreallocationWithoutRelationsDto),
    __metadata("design:type", Array)
], VendorDto.prototype, "preallocations", void 0);
