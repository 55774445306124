export var Color;
(function (Color) {
    Color["BLACK"] = "BLACK";
    Color["RED"] = "RED";
    Color["ORANGE"] = "ORANGE";
    Color["YELLOW"] = "YELLOW";
    Color["YELLOWGREEN"] = "YELLOWGREEN";
    Color["GREEN"] = "GREEN";
    Color["CYAN"] = "CYAN";
    Color["BLUE"] = "BLUE";
    Color["PURPLE"] = "PURPLE";
    Color["FUCHSIA"] = "FUCHSIA";
    Color["GREY"] = "GREY";
})(Color || (Color = {}));
