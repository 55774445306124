var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsArray, IsEnum, IsOptional, IsString, ValidateNested, } from 'class-validator';
import { MessagingDealer } from '../../../enums';
export class SendKakaoInfoTalkButtonDto {
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], SendKakaoInfoTalkButtonDto.prototype, "name", void 0);
__decorate([
    IsEnum(['AL', 'WL']),
    __metadata("design:type", String)
], SendKakaoInfoTalkButtonDto.prototype, "type", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], SendKakaoInfoTalkButtonDto.prototype, "iosAppLink", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], SendKakaoInfoTalkButtonDto.prototype, "aosAppLink", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], SendKakaoInfoTalkButtonDto.prototype, "mobileWebLink", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], SendKakaoInfoTalkButtonDto.prototype, "pcWebLink", void 0);
export class SendKakaoInfoTalkMessageDto {
}
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], SendKakaoInfoTalkMessageDto.prototype, "reservationId", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], SendKakaoInfoTalkMessageDto.prototype, "receiverNumber", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], SendKakaoInfoTalkMessageDto.prototype, "content", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => SendKakaoInfoTalkButtonDto),
    IsOptional(),
    __metadata("design:type", Array)
], SendKakaoInfoTalkMessageDto.prototype, "buttons", void 0);
export class SendKakaoInfoTalksDto {
}
__decorate([
    IsEnum(MessagingDealer),
    __metadata("design:type", String)
], SendKakaoInfoTalksDto.prototype, "dealer", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], SendKakaoInfoTalksDto.prototype, "templateCode", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], SendKakaoInfoTalksDto.prototype, "senderNumber", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], SendKakaoInfoTalksDto.prototype, "title", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => SendKakaoInfoTalkMessageDto),
    __metadata("design:type", Array)
], SendKakaoInfoTalksDto.prototype, "messages", void 0);
