var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { DayFeeDto } from './day-fee.dto';
import { FeeWeekendPolicyDto } from './fee-weekend-policy.dto';
export class FeeInfoDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.vehicleModelId = initializer.vehicleModelId;
        this.calculationMethod = initializer.calculationMethod;
        this.applyWeekendPolicyOn = initializer.applyWeekendPolicyOn;
        this.basic = initializer.basic;
        this.option1 = initializer.option1;
        this.option2 = initializer.option2;
    }
}
__decorate([
    Type(() => FeeWeekendPolicyDto),
    __metadata("design:type", FeeWeekendPolicyDto)
], FeeInfoDto.prototype, "applyWeekendPolicyOn", void 0);
__decorate([
    Type(() => DayFeeDto),
    __metadata("design:type", DayFeeDto)
], FeeInfoDto.prototype, "basic", void 0);
__decorate([
    Type(() => DayFeeDto),
    __metadata("design:type", DayFeeDto)
], FeeInfoDto.prototype, "option1", void 0);
__decorate([
    Type(() => DayFeeDto),
    __metadata("design:type", DayFeeDto)
], FeeInfoDto.prototype, "option2", void 0);
