var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsArray, IsDateString, IsEnum, IsNumber, IsString, ValidateNested, } from 'class-validator';
import { SettlementState } from '../../../enums';
import { CreatePaymentForSettlementDto } from './create-payment-for-settlement.dto';
export class CreateSettlementDto {
}
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], CreateSettlementDto.prototype, "settledAt", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateSettlementDto.prototype, "transactionPrice", void 0);
__decorate([
    IsEnum(SettlementState),
    __metadata("design:type", String)
], CreateSettlementDto.prototype, "state", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateSettlementDto.prototype, "managerId", void 0);
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], CreateSettlementDto.prototype, "startDate", void 0);
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], CreateSettlementDto.prototype, "endDate", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateSettlementDto.prototype, "memo", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateSettlementDto.prototype, "bankCardId", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateSettlementDto.prototype, "vendorId", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => CreatePaymentForSettlementDto),
    __metadata("design:type", Array)
], CreateSettlementDto.prototype, "payments", void 0);
