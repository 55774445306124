import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { socket } from '@/apis/websocketClient';
import InactivityDialog from '@/components/InactivityDialog';
import TopProgressBar from '@/components/TopProgressBar';
import { useLoginInformationState } from '@/hooks/auth.hook';
import { useGetReservation } from '@/hooks/reservation.hook';

const CommonLayout = () => {
  const { login } = useLoginInformationState();

  const id = useParams().id;

  const { data } = useGetReservation({
    id,
  });

  const isExistData =
    !!data && !!data.assignedVehicleId && !!data.dispatchAreaId;

  useEffect(() => {
    if (login && !socket.connected) {
      socket.connect();
      socket.emit('register');
    } else {
      socket.disconnect();
    }
  }, [login]);

  useEffect(() => {
    if (isExistData) {
      socket.emit('subscribe', { id: id });
    }
    return () => {
      socket.emit('unsubscribe');
    };
  }, [isExistData, id]);

  return (
    <>
      {!login && <Navigate to='/login' />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100wv',
          height: '100vh',
          backgroundColor: '#f5f5f5',
        }}
      >
        <TopProgressBar />
        <Outlet />
      </Box>
      <InactivityDialog />
    </>
  );
};

export default CommonLayout;
