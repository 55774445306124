var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsArray, IsOptional, ValidateNested } from 'class-validator';
import { OmitType, PartialType } from '../../../utils';
import { CreateReservationDto } from './create-reservation.dto';
import { UpdatePaymentForReservationDto } from './update-payment-for-reservation.dto';
import { UpdateReservationConvenienceDto } from './update-reservation-convenience.dto';
import { UpdateReservationExtensionDto } from './update-reservation-extension.dto';
import { UpdateReservationExtraItemDto } from './update-reservation-extra-item.dto';
import { UpdateReservationInsuranceDto } from './update-reservation-insurance.dto';
export class UpdateReservationDto extends PartialType(OmitType(CreateReservationDto, ['insurances', 'conveniences', 'extraItems'])) {
}
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => UpdateReservationInsuranceDto),
    IsOptional(),
    __metadata("design:type", Array)
], UpdateReservationDto.prototype, "insurances", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => UpdateReservationExtraItemDto),
    IsOptional(),
    __metadata("design:type", Array)
], UpdateReservationDto.prototype, "extraItems", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => UpdateReservationConvenienceDto),
    IsOptional(),
    __metadata("design:type", Array)
], UpdateReservationDto.prototype, "conveniences", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => UpdateReservationExtensionDto),
    IsOptional(),
    __metadata("design:type", Array)
], UpdateReservationDto.prototype, "extensions", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => UpdatePaymentForReservationDto),
    IsOptional(),
    __metadata("design:type", Array)
], UpdateReservationDto.prototype, "payments", void 0);
