import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

const SpaceIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{
        width: 36,
        height: 12,
        ...props.sx,
      }}
      viewBox='0 0 36 12'
      fill='none'
      {...props}
    >
      <path d='M0 12V0H3V9H29V0H32V12H0Z' fill='#7E7E7E' />
    </SvgIcon>
  );
};
export default SpaceIcon;
