var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsEnum, IsNumber, IsOptional, ValidateNested } from 'class-validator';
import { VehicleModelFeeCalculationMethod } from '../../../../enums';
import { UpdateDayFeeDto } from './update-day-fee.dto';
import { UpdateFeeWeekendPolicyDto } from './update-fee-weekend-policy.dto';
export class UpdateFeeInfoDto {
}
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], UpdateFeeInfoDto.prototype, "id", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], UpdateFeeInfoDto.prototype, "vehicleModelId", void 0);
__decorate([
    IsEnum(VehicleModelFeeCalculationMethod),
    IsOptional(),
    __metadata("design:type", String)
], UpdateFeeInfoDto.prototype, "calculationMethod", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateFeeWeekendPolicyDto),
    IsOptional(),
    __metadata("design:type", UpdateFeeWeekendPolicyDto)
], UpdateFeeInfoDto.prototype, "applyWeekendPolicyOn", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateDayFeeDto),
    IsOptional(),
    __metadata("design:type", UpdateDayFeeDto)
], UpdateFeeInfoDto.prototype, "basic", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateDayFeeDto),
    IsOptional(),
    __metadata("design:type", UpdateDayFeeDto)
], UpdateFeeInfoDto.prototype, "option1", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateDayFeeDto),
    IsOptional(),
    __metadata("design:type", UpdateDayFeeDto)
], UpdateFeeInfoDto.prototype, "option2", void 0);
