import { Box, Divider, Typography } from '@mui/material';
import {
  DriverLicenseType,
  formatDriverLicenseNumber,
  formatPhoneNumber,
  ManagerApprovalType,
  REGIONAL_CODE,
  type ReservationPersonDto,
  translateDriverLicenseType,
} from '@orcar/common';
import dayjs from 'dayjs';
import { type FC, Fragment, useEffect, useRef, useState } from 'react';
import { type UseFormReturn, useWatch } from 'react-hook-form';
import theme from '@/app/theme';
import LoadingDialog from '@/components/LoadingDialog';
import { ChipButton } from '@/components/StyledMuiComponent';
import { useManagerApproval } from '@/hooks/manager-approval.hook';
import DriverInput from './DriverInput';
import DriverLicensePlaceholder from './DriverLicensePlaceholder';
import DriverLicenseScanDialog, {
  type OcrResult,
} from './DriverLicenseScanDialog';
import { type KeypadState } from '../DriverInfoPage';
import {
  type DriverLicenseForm,
  type DriverLicenseImageForm,
} from '../DriverLicenseInfoPage';

type ScannedImage = {
  src: string;
  direction: 1 | 2;
  isOlderVersion: boolean;
};

type Props = {
  type: 'driverInfo' | 'driverLicenseInfo';
  reservationId: string;
  driverNumber: 1 | 2;
  person: ReservationPersonDto;
  driverLicenseForm: UseFormReturn<DriverLicenseForm, unknown, undefined>;
  driverLicenseImageForm: UseFormReturn<
    DriverLicenseImageForm,
    unknown,
    undefined
  >;
  isKeypadOpen?: boolean;
  setKeypadState: React.Dispatch<React.SetStateAction<KeypadState>>;
};

const ReservationPersonCard: FC<Props> = ({
  type,
  reservationId,
  driverNumber,
  person,
  driverLicenseForm,
  driverLicenseImageForm,
  isKeypadOpen,
  setKeypadState,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const {
    status: imageApprovalStatus,
    start: startImageApproval,
    stop: stopImageApproval,
  } = useManagerApproval(
    ManagerApprovalType.SKIP_DRIVER_LICENSE_IMAGE,
    reservationId,
  );

  // const {
  //   status: editingApprovalStatus,
  //   start: startEditingApproval,
  //   stop: stopEditingApproval,
  // } = useManagerApproval(
  //   ManagerApprovalType.EDIT_DRIVER_LICENSE_INFO,
  //   reservationId,
  // );

  const [openScanDialog, setOpenScanDialog] = useState(false);
  const [isWaitingOcr, setIsWaitingOcr] = useState(false);
  const [scannedImage, setScannedImage] = useState<ScannedImage | null>(null);

  const {
    setValue,
    control,
    formState: { errors },
    clearErrors,
  } = driverLicenseForm;
  const { setValue: setImageValue, control: imageControl } =
    driverLicenseImageForm;

  const name = useWatch({ control, name: 'name' });
  const phoneNumber = useWatch({ control, name: 'phoneNumber' });
  const birthDate = useWatch({ control, name: 'birthDate' });
  const driverLicenseType = useWatch({ control, name: 'driverLicenseType' });
  const driverLicenseNumber = useWatch({
    control,
    name: 'driverLicenseNumber',
  });

  const isApproved = useWatch({ control: imageControl, name: 'isApproved' });

  useEffect(() => {
    setOpenScanDialog(false);
    setIsWaitingOcr(false);
    setScannedImage(null);
    stopImageApproval();
    // stopEditingApproval();
  }, [driverNumber, stopImageApproval]);

  useEffect(() => {
    const imageUrl = scannedImage
      ? scannedImage.src
      : person.driverLicenseImageUrl;

    if (imageUrl) {
      const canvas = canvasRef.current;

      if (canvas) {
        const context = canvas.getContext('2d');

        if (context) {
          const image = new Image();
          image.crossOrigin = 'Anonymous';
          image.src = imageUrl;
          image.onload = () => {
            if (scannedImage?.direction === 2) {
              context.translate(840 / 2, 523 / 2);
              context.rotate(Math.PI);
              context.drawImage(image, -840 / 2, -523 / 2, 840, 523);
              context.setTransform(1, 0, 0, 1, 0, 0);
            } else {
              context.drawImage(image, 0, 0, 840, 523);
            }

            if (scannedImage) {
              context.fillStyle = 'black';
              if (scannedImage.isOlderVersion) {
                context.fillRect(590, 177, 150, 43);
              } else {
                context.fillRect(450, 177, 150, 43);
              }

              canvas.toBlob((blob) => {
                setImageValue('licenseImage', blob);
                setImageValue('isApproved', false);
              }, 'image/jpeg');
            }
          };
        }
      }
    }
  }, [scannedImage, setImageValue, person.driverLicenseImageUrl]);

  useEffect(() => {
    if (
      !person.driverLicenseImageUrl &&
      !scannedImage &&
      !isApproved &&
      type === 'driverLicenseInfo'
    ) {
      setOpenScanDialog(true);
    }
  }, [person.driverLicenseImageUrl, scannedImage, isApproved]);

  const updateByOcrResult = async ({
    licenseNumber: driverLicenseNumber,
    driverType,
  }: OcrResult) => {
    const licenseTypeStr = driverType.replace(/\s/g, '');

    const driverLicenseType = licenseTypeStr.includes('1종대형')
      ? DriverLicenseType.FIRST_LARGE
      : licenseTypeStr.includes('1종보통')
      ? DriverLicenseType.FIRST_ORDINARY
      : licenseTypeStr.includes('2종보통')
      ? DriverLicenseType.SECOND_ORDINARY
      : licenseTypeStr.includes('특수')
      ? licenseTypeStr.includes('대형견인') ||
        licenseTypeStr.includes('트레일러')
        ? DriverLicenseType.FIRST_LARGE_TRAILER
        : licenseTypeStr.includes('소형견인')
        ? DriverLicenseType.FIRST_SMALL_TRAILER
        : licenseTypeStr.includes('구난') || licenseTypeStr.includes('레커')
        ? DriverLicenseType.FIRST_WRECKER
        : null
      : licenseTypeStr.includes('1종소형')
      ? DriverLicenseType.FIRST_SMALL
      : licenseTypeStr.includes('2종소형')
      ? DriverLicenseType.SECOND_SMALL
      : licenseTypeStr.includes('원동기')
      ? DriverLicenseType.SECOND_MOTORIZED_BICYCLE
      : null;

    const regex = new RegExp(`${Object.keys(REGIONAL_CODE).join('|')}`, 'g');
    const region = driverLicenseNumber.match(regex)?.[0];
    const regionalCode =
      REGIONAL_CODE[region as keyof typeof REGIONAL_CODE] || '';
    const numbers = regionalCode + driverLicenseNumber.replace(/\D/g, '');

    setValue('driverLicenseType', driverLicenseType);
    setValue('driverLicenseNumber', numbers.length === 12 ? numbers : '');
  };

  const handleOcr = async () => {
    setIsWaitingOcr(true);

    try {
      const ocrResult = await window.quantumScan.getOcrResult();
      updateByOcrResult(ocrResult);
    } catch (error) {
      console.error(error);
    } finally {
      setIsWaitingOcr(false);
      setKeypadState({
        open: true,
        type: 'licenseInfo',
        editSingleType: false,
      });
    }
  };

  return (
    <Box
      sx={{
        background: '#ffffff',
        border: '1px solid #d4d4d4',
        padding: '40px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography fontSize={40} fontWeight='bold'>
          제{driverNumber}운전자 {type === 'driverLicenseInfo' ? '면허' : ''}{' '}
          정보
        </Typography>
        <Divider
          sx={{
            marginTop: '36px',
          }}
        />
        {type === 'driverInfo' && (
          <Fragment>
            <DriverInput
              type='name'
              value={name}
              setKeypadState={setKeypadState}
              driverLicenseForm={driverLicenseForm}
            />
            <DriverInput
              type='phoneNumber'
              value={phoneNumber ? formatPhoneNumber(phoneNumber) : ''}
              setKeypadState={setKeypadState}
              driverLicenseForm={driverLicenseForm}
            />
            <DriverInput
              type='birthDate'
              value={
                birthDate
                  ? dayjs(birthDate).format('YYYYMMDD') +
                    ` (만 ${dayjs().diff(birthDate, 'year')}세)`
                  : ''
              }
              setKeypadState={setKeypadState}
              driverLicenseForm={driverLicenseForm}
            />
          </Fragment>
        )}
        {type === 'driverLicenseInfo' && (
          <Fragment>
            <DriverInput
              type='name'
              value={name}
              setKeypadState={setKeypadState}
              driverLicenseForm={driverLicenseForm}
              canEdit={false}
            />
            <DriverInput
              type='driverLicenseType'
              value={
                driverLicenseType
                  ? translateDriverLicenseType(driverLicenseType)
                  : ''
              }
              setKeypadState={setKeypadState}
              driverLicenseForm={driverLicenseForm}
            />
            <DriverInput
              type='driverLicenseNumber'
              value={
                driverLicenseNumber
                  ? formatDriverLicenseNumber(driverLicenseNumber)
                  : ''
              }
              setKeypadState={setKeypadState}
              driverLicenseForm={driverLicenseForm}
            />
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                mt: 4,
                mb: 2,
              }}
            >
              {!isApproved && (person.driverLicenseImageUrl || scannedImage) ? (
                <canvas ref={canvasRef} width={840} height={523} />
              ) : (
                <DriverLicensePlaceholder />
              )}
              {isApproved && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 840,
                    height: 523,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.error.main,
                      fontSize: '68px',
                      fontWeight: 'bold',
                      border: '8px solid',
                      padding: '20px',
                      rotate: '-15deg',
                    }}
                  >
                    {'관리자 확인'}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ChipButton
                useIcon={false}
                buttonSize='large'
                sx={{
                  backgroundColor: errors.needToReScan ? '#EF5350' : '#fafafa',
                  color: errors.needToReScan ? '#fff' : 'text.secondary',
                }}
                onClick={() => {
                  setOpenScanDialog(true);
                  clearErrors('needToReScan');
                }}
              >
                {errors.needToReScan ? '다시 스캔하기' : '스캔하기'}
              </ChipButton>
            </Box>
            {isKeypadOpen && <Box sx={{ height: 1000 }} />}
          </Fragment>
        )}
      </Box>
      <DriverLicenseScanDialog
        open={openScanDialog}
        onClose={() => {
          setOpenScanDialog(false);
        }}
        onScanEnd={async (scanResult, isOlderVersion) => {
          await handleOcr();
          setScannedImage({
            src: URL.createObjectURL(
              new Blob([scanResult.imageBuffer], { type: 'image/bmp' }),
            ),
            direction: scanResult.direction === 2 ? 2 : 1,
            isOlderVersion,
          });
          setOpenScanDialog(false);
          // stopEditingApproval();
        }}
        approvalStatus={imageApprovalStatus}
        startManagerApproval={async () =>
          await startImageApproval(() => {
            setScannedImage(null);
            setImageValue('licenseImage', null);
            setImageValue('isApproved', true);
            setOpenScanDialog(false);
          })
        }
        stopManagerApproval={stopImageApproval}
      />
      <LoadingDialog
        open={isWaitingOcr}
        content='운전면허증에서 정보를 추출하고 있어요.'
      />
      {/* <Dialog
        open={editingApprovalStatus === 'WAITING'}
        PaperProps={{
          sx: {
            position: 'relative',
            padding: 0,
          },
        }}
      >
        <EditValueManagerApproval onClosed={stopEditingApproval} />
      </Dialog> */}
    </Box>
  );
};

export default ReservationPersonCard;
