var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { VehicleModelAudioDto } from './vehicle-model-audio.dto';
import { VehicleModelFeeDto } from './vehicle-model-fee.dto';
import { VehicleModelInsuranceListDtoV2 } from './vehicle-model-insurance-list.v2.dto';
import { VehicleModelKeyOptionDto } from './vehicle-model-key-option.dto';
import { VehicleModelOptionDto } from './vehicle-model-option.dto';
export class VehicleModelListDtoV2 {
    constructor(initializer) {
        Object.assign(this, initializer);
    }
}
__decorate([
    Type(() => VehicleModelAudioDto),
    __metadata("design:type", VehicleModelAudioDto)
], VehicleModelListDtoV2.prototype, "audio", void 0);
__decorate([
    Type(() => VehicleModelKeyOptionDto),
    __metadata("design:type", VehicleModelKeyOptionDto)
], VehicleModelListDtoV2.prototype, "keyOption", void 0);
__decorate([
    Type(() => VehicleModelOptionDto),
    __metadata("design:type", VehicleModelOptionDto)
], VehicleModelListDtoV2.prototype, "modelOption", void 0);
__decorate([
    Type(() => VehicleModelFeeDto),
    __metadata("design:type", VehicleModelFeeDto)
], VehicleModelListDtoV2.prototype, "fee", void 0);
__decorate([
    Type(() => VehicleModelInsuranceListDtoV2),
    __metadata("design:type", Object)
], VehicleModelListDtoV2.prototype, "insurance1", void 0);
__decorate([
    Type(() => VehicleModelInsuranceListDtoV2),
    __metadata("design:type", Object)
], VehicleModelListDtoV2.prototype, "insurance2", void 0);
