var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsArray, IsBoolean, IsDateString, IsEnum, IsNumber, IsOptional, IsString, ValidateNested, } from 'class-validator';
import { FuelRateUnit, ReservationRoute, ReservationState } from '../../../enums';
import { CreateReservationConvenienceDto } from './create-reservation-convenience.dto';
import { CreateReservationExtraItemDto } from './create-reservation-extra-item.dto';
import { CreateReservationInsuranceDto } from './create-reservation-insurance.dto';
import { CreateReservationPersonDto } from './create-reservation-person.dto';
export class CreateReservationDto {
}
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "pickUpAt", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "pickUpGarageId", void 0);
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "dropOffAt", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "dropOffGarageId", void 0);
__decorate([
    IsNumber()
    // 타 브랜치에 영향없도록 임시로 optional 처리
    ,
    IsOptional(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "billingTime", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "vendorId", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "vehicleModelId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "reservedVehicleId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "insuranceOption", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "listPrice", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "finalPrice", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], CreateReservationDto.prototype, "priceLock", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "directPaymentPrice", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], CreateReservationDto.prototype, "checkDirectPayment", void 0);
__decorate([
    IsEnum(ReservationState),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "state", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "managerId", void 0);
__decorate([
    IsEnum(ReservationRoute),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "route", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], CreateReservationDto.prototype, "allAges", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], CreateReservationDto.prototype, "guide", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "email", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "customerName1", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "customerName2", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "customerPhoneNumber1", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "customerPhoneNumber2", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "memo", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "vendorMemo", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => CreateReservationInsuranceDto),
    IsOptional(),
    __metadata("design:type", Array)
], CreateReservationDto.prototype, "insurances", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => CreateReservationExtraItemDto),
    IsOptional(),
    __metadata("design:type", Array)
], CreateReservationDto.prototype, "extraItems", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => CreateReservationConvenienceDto),
    IsOptional(),
    __metadata("design:type", Array)
], CreateReservationDto.prototype, "conveniences", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "expectedDepositDate", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "expectedDepositFee", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "expectedDepositMemo", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "releaseManagerId", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "contractNo", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "departureDate", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "departureFuelRate", void 0);
__decorate([
    IsEnum(FuelRateUnit),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "departureFuelRateUnit", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "departureMileage", void 0);
__decorate([
    IsArray(),
    IsNumber({}, { each: true }),
    IsOptional(),
    __metadata("design:type", Array)
], CreateReservationDto.prototype, "checklistItemIds", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], CreateReservationDto.prototype, "releasePreparation", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "assignedVehicleId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "dispatchAreaId", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "releasePreparationMemo", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "releasePreparedAt", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateReservationPersonDto),
    IsOptional(),
    __metadata("design:type", CreateReservationPersonDto)
], CreateReservationDto.prototype, "contractor", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateReservationPersonDto),
    IsOptional(),
    __metadata("design:type", CreateReservationPersonDto)
], CreateReservationDto.prototype, "driver", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "returnManagerId", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "returnDate", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "returnFuelRate", void 0);
__decorate([
    IsEnum(FuelRateUnit),
    IsOptional(),
    __metadata("design:type", String)
], CreateReservationDto.prototype, "returnFuelRateUnit", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateReservationDto.prototype, "returnMileage", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "returnGarageId", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], CreateReservationDto.prototype, "customerAirportArrived", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "wantedVendor", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationDto.prototype, "cancelReason", void 0);
