var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { ReservationForChartDto } from '../../../classes/reservation/response/reservation-for-chart.dto';
import { VehicleWithReservationDto } from '../../../classes/vehicle/response';
export class VehicleModelWithReservationDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.name = initializer.name;
        this.graphOrder = initializer.graphOrder;
        this.type = initializer.type;
        this.state = initializer.state;
        this.vehicles = initializer.vehicles;
        this.nonVehicleReservations = initializer.nonVehicleReservations;
    }
}
__decorate([
    Type(() => VehicleWithReservationDto),
    __metadata("design:type", Array)
], VehicleModelWithReservationDto.prototype, "vehicles", void 0);
__decorate([
    Type(() => ReservationForChartDto),
    __metadata("design:type", Array)
], VehicleModelWithReservationDto.prototype, "nonVehicleReservations", void 0);
