import { Box, Table, TableBody, TableRow } from '@mui/material';
import { type Nullable } from '@orcar/common';
import Hangul from 'hangul-js';
import { useEffect, useState } from 'react';
import BackspaceIcon from '@/components/BackspaceIcon';
import ShiftIcon from '@/components/ShiftIcon';
import SpaceIcon from '@/components/SpaceIcon';
import Key from './Key';
import KeypadInput from './KeypadInput';

type KeyMap = {
  korean: {
    upper: string[];
    lower: string[];
  };
  english: {
    upper: string[];
    lower: string[];
  };
};

const FirstRow: KeyMap = {
  korean: {
    upper: ['ㅃ', 'ㅉ', 'ㄸ', 'ㄲ', 'ㅆ', 'ㅛ', 'ㅕ', 'ㅑ', 'ㅒ', 'ㅖ'],
    lower: ['ㅂ', 'ㅈ', 'ㄷ', 'ㄱ', 'ㅅ', 'ㅛ', 'ㅕ', 'ㅑ', 'ㅐ', 'ㅔ'],
  },
  english: {
    upper: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    lower: ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  },
};

const SecondRow: KeyMap = {
  korean: {
    upper: ['ㅁ', 'ㄴ', 'ㅇ', 'ㄹ', 'ㅎ', 'ㅗ', 'ㅓ', 'ㅏ', 'ㅣ'],
    lower: ['ㅁ', 'ㄴ', 'ㅇ', 'ㄹ', 'ㅎ', 'ㅗ', 'ㅓ', 'ㅏ', 'ㅣ'],
  },
  english: {
    upper: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    lower: ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  },
};

const ThirdRow: KeyMap = {
  korean: {
    upper: ['ㅋ', 'ㅌ', 'ㅊ', 'ㅍ', 'ㅠ', 'ㅜ', 'ㅡ'],
    lower: ['ㅋ', 'ㅌ', 'ㅊ', 'ㅍ', 'ㅠ', 'ㅜ', 'ㅡ'],
  },
  english: {
    upper: ['Z', 'X', 'C', 'V', 'B', 'N', 'M'],
    lower: ['z', 'x', 'c', 'v', 'b', 'n', 'm'],
  },
};

type TextKeypadProps = {
  defaultValue?: Nullable<string>;
  onConfirm: (value: string) => void;
};

const TextKeypad = ({ defaultValue, onConfirm }: TextKeypadProps) => {
  const [language, setLanguage] = useState<'korean' | 'english'>('korean');
  const [shift, setShift] = useState<'upper' | 'lower'>('lower');

  const [inputValue, setInputValue] = useState('');
  const [currentChar, setCurrentChar] = useState('');

  useEffect(() => {
    setInputValue(defaultValue ?? '');
  }, [defaultValue]);

  const handleClick = (value: string) => {
    if (value.match(/[ㄱ-ㅎ|ㅏ-ㅣ]/)) {
      const disassembled = [...Hangul.disassemble(currentChar), value];
      const assembled = Hangul.assemble(disassembled);
      if (assembled.length >= 2) {
        setInputValue(inputValue + assembled.slice(0, -1));
        setCurrentChar(assembled.slice(-1));
      } else {
        setCurrentChar(assembled);
      }
    } else if (value === 'backspace') {
      if (currentChar.length === 0) {
        setInputValue(inputValue.slice(0, -1));
      } else {
        setCurrentChar(
          Hangul.assemble(Hangul.disassemble(currentChar).slice(0, -1)),
        );
      }
    } else if (value === 'ABC') {
      setLanguage('english');
    } else if (value === '한글') {
      setLanguage('korean');
    } else if (value === 'shift') {
      setShift(shift === 'upper' ? 'lower' : 'upper');
    } else if (value === 'space') {
      if (currentChar.length === 0) {
        setInputValue(inputValue + ' ');
      } else {
        setInputValue(inputValue + currentChar);
        setCurrentChar('');
      }
    } else {
      setInputValue(inputValue + currentChar + value);
      setCurrentChar('');
    }

    // Shift 고정하지 않으려면 주석 해제
    // if (value !== 'shift') {
    //   setShift('lower');
    // }
  };
  useEffect(() => {
    setCurrentChar('');
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        bgcolor: 'white',
        height: 499,
        flexDirection: 'column',
      }}
    >
      <Table sx={{ width: '100%', flexGrow: 1 }}>
        <TableBody>
          <TableRow>
            <KeypadInput
              colSpan={10}
              value={inputValue + currentChar}
              onDelete={() => {
                setInputValue('');
                setCurrentChar('');
              }}
            />
          </TableRow>
          <TableRow>
            {FirstRow[language][shift].map((value, _) => {
              return (
                <Key
                  key={_}
                  sx={{ width: 100, height: 100 }}
                  onClick={() => handleClick(value)}
                >
                  {value}
                </Key>
              );
            })}
          </TableRow>
          <TableRow>
            {SecondRow[language][shift].map((value, index) => {
              return (
                <Key
                  key={index}
                  sx={{ width: 100, height: 100 }}
                  onClick={() => handleClick(value)}
                >
                  {value}
                </Key>
              );
            })}
            <Key
              sx={{ width: 100, height: 100, bgcolor: '#F5F5F5' }}
              onClick={() => handleClick('backspace')}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <BackspaceIcon />
              </Box>
            </Key>
          </TableRow>
          <TableRow>
            <Key
              sx={{ width: 100, height: 100, bgcolor: '#F5F5F5' }}
              onClick={() => handleClick('shift')}
              isPressed={shift === 'upper'}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ShiftIcon />
              </Box>
            </Key>
            {ThirdRow[language][shift].map((value, index) => {
              return (
                <Key
                  key={index}
                  sx={{ width: 100, height: 100 }}
                  onClick={() => handleClick(value)}
                >
                  {value}
                </Key>
              );
            })}
            <Key
              sx={{
                width: 100,
                height: 100,
                bgcolor: 'primary.main',
                color: 'white',
                fontWeight: 'medium',
              }}
              colSpan={2}
              rowSpan={2}
              onClick={() => {
                onConfirm(inputValue + currentChar);
              }}
            >
              확인
            </Key>
          </TableRow>
          <TableRow>
            <Key
              sx={{ width: 100, height: 100, bgcolor: '#F5F5F5' }}
              onClick={() =>
                handleClick(language === 'korean' ? 'ABC' : '한글')
              }
            >
              {language === 'korean' ? 'ABC' : '한글'}
            </Key>
            <Key
              colSpan={7}
              sx={{ height: 100, bgcolor: '#F5F5F5' }}
              onClick={() => handleClick('space')}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <SpaceIcon />
              </Box>
            </Key>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default TextKeypad;
