var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { ErpAdminNoticeTagDto } from './erp-admin-notice-tag.dto';
import { BaseNumberIdDataDto } from '../../common';
export class ErpAdminNoticeDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.title = initializer.title;
        this.content = initializer.content;
        this.tags = initializer.tags;
        this.username = initializer.username;
        this.notice = initializer.notice;
    }
}
__decorate([
    Type(() => ErpAdminNoticeTagDto),
    __metadata("design:type", Array)
], ErpAdminNoticeDto.prototype, "tags", void 0);
