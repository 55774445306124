import { ContractSectionType } from '../enums';
export const CONTRACT_SECTION_TYPE = {
    [ContractSectionType.COMPANY_INFO]: '상단회사정보',
    [ContractSectionType.MAIN_CONTENT]: '계약조건',
    [ContractSectionType.PRIVACY_AGREEMENT]: '개인정보동의',
    [ContractSectionType.FINAL_AGREEMENT]: '최종확인내용',
    [ContractSectionType.COMPANY_SIGNATURE]: '임대인서명',
    [ContractSectionType.ADDITIONAL_CONTENT]: '추가내용(약관)',
    // 자차면책제도, 제외되는 항목 설명
    [ContractSectionType.INSURANCE_ADDITIONAL_CONTENT]: '자차보험추가내용',
    [ContractSectionType.NON_INSURANCE_GUIDANCE]: '자차보험미가입안내',
    [ContractSectionType.UNMANNED_DISPATCH_CONSENT]: '무인배차사용동의',
};
