var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean } from 'class-validator';
export class FeeWeekendPolicyDto {
    constructor(initializer) {
        this.monday = initializer.monday;
        this.tuesday = initializer.tuesday;
        this.wednesday = initializer.wednesday;
        this.thursday = initializer.thursday;
        this.friday = initializer.friday;
        this.saturday = initializer.saturday;
        this.sunday = initializer.sunday;
    }
}
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], FeeWeekendPolicyDto.prototype, "monday", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], FeeWeekendPolicyDto.prototype, "tuesday", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], FeeWeekendPolicyDto.prototype, "wednesday", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], FeeWeekendPolicyDto.prototype, "thursday", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], FeeWeekendPolicyDto.prototype, "friday", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], FeeWeekendPolicyDto.prototype, "saturday", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], FeeWeekendPolicyDto.prototype, "sunday", void 0);
