var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../../classes/common/response/base-number-id-data.dto';
import { PartnersInsuranceDto } from '../../../../classes/partners/insurance/response/partners-insurance.dto';
import { VehicleModelForPartnersDto } from '../../../../classes/vehicle-model';
import { PartnersVehicleModelCategoryDto } from './partners-vehicle-model-category.dto';
export class PartnersVehicleModelDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.name = initializer.name;
        this.code = initializer.code;
        this.categories = initializer.categories;
        this.vehicleModels = initializer.vehicleModels;
        this.partnersInsurances = initializer.partnersInsurances;
    }
}
__decorate([
    Type(() => PartnersVehicleModelCategoryDto),
    __metadata("design:type", Array)
], PartnersVehicleModelDto.prototype, "categories", void 0);
__decorate([
    Type(() => VehicleModelForPartnersDto),
    __metadata("design:type", Array)
], PartnersVehicleModelDto.prototype, "vehicleModels", void 0);
__decorate([
    Type(() => PartnersInsuranceDto),
    __metadata("design:type", Array)
], PartnersVehicleModelDto.prototype, "partnersInsurances", void 0);
