var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common';
import { GarageDto } from '../../../classes/garage';
import { PreallocationBlockWithPreallocationDto } from '../../../classes/preallocation-block/response/preallocation-block-with-preallocation.dto';
import { ReservationForChartDto } from '../../../classes/reservation/response/reservation-for-chart.dto';
export class VehicleWithReservationDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.vehicleNumber = initializer.vehicleNumber;
        this.registeredAt = initializer.registeredAt;
        this.state = initializer.state;
        this.age = initializer.age;
        this.mileage = initializer.mileage;
        this.vehicleModelId = initializer.vehicleModelId;
        this.garage = initializer.garage;
        this.garageId = initializer.garageId;
        this.branchId = initializer.branchId;
        this.preallocationBlocks = initializer.preallocationBlocks;
        this.reservations = initializer.reservations;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], VehicleWithReservationDto.prototype, "registeredAt", void 0);
__decorate([
    Type(() => GarageDto),
    __metadata("design:type", Object)
], VehicleWithReservationDto.prototype, "garage", void 0);
__decorate([
    Type(() => PreallocationBlockWithPreallocationDto),
    __metadata("design:type", Array)
], VehicleWithReservationDto.prototype, "preallocationBlocks", void 0);
__decorate([
    Type(() => ReservationForChartDto),
    __metadata("design:type", Array)
], VehicleWithReservationDto.prototype, "reservations", void 0);
