var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { ChecklistItemDto } from '../../../classes/checklist-item';
import { BaseStringIdDataDto } from '../../../classes/common';
import { DispatchAreaWithoutRelationsDto } from '../../../classes/dispatch-area';
import { GarageDto } from '../../../classes/garage';
import { UserWithoutRelationsDto } from '../../../classes/user';
import { VehicleWithoutRelationsDto } from '../../../classes/vehicle';
import { VehicleModelWithoutRelationsDto } from '../../../classes/vehicle-model';
import { VendorWithoutRelationsDto } from '../../../classes/vendor/response/vendor-without-relations.dto';
import { ReservationPersonDto } from './reservation-person.dto';
export class ReservationWithoutOneToManyRelationsDto extends BaseStringIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.pickUpAt = initializer.pickUpAt;
        this.pickUpGarageId = initializer.pickUpGarageId;
        this.pickUpGarage = initializer.pickUpGarage;
        this.dropOffAt = initializer.dropOffAt;
        this.dropOffGarageId = initializer.dropOffGarageId;
        this.dropOffGarage = initializer.dropOffGarage;
        this.billingTime = initializer.billingTime;
        this.vehicleModel = initializer.vehicleModel;
        this.vehicleModelId = initializer.vehicleModelId;
        this.reservedVehicleId = initializer.reservedVehicleId;
        this.reservedVehicle = initializer.reservedVehicle;
        this.insuranceOption = initializer.insuranceOption;
        this.listPrice = initializer.listPrice;
        this.finalPrice = initializer.finalPrice;
        this.priceLock = initializer.priceLock;
        this.directPaymentPrice = initializer.directPaymentPrice;
        this.checkDirectPayment = initializer.checkDirectPayment;
        this.state = initializer.state;
        this.manager = initializer.manager;
        this.managerId = initializer.managerId;
        this.vendor = initializer.vendor;
        this.vendorId = initializer.vendorId;
        this.route = initializer.route;
        this.allAges = initializer.allAges;
        this.guide = initializer.guide;
        this.email = initializer.email;
        this.customerName1 = initializer.customerName1;
        this.customerName2 = initializer.customerName2;
        this.customerPhoneNumber1 = initializer.customerPhoneNumber1;
        this.customerPhoneNumber2 = initializer.customerPhoneNumber2;
        this.memo = initializer.memo;
        this.vendorMemo = initializer.vendorMemo;
        this.expectedDepositDate = initializer.expectedDepositDate;
        this.expectedDepositFee = initializer.expectedDepositFee;
        this.expectedDepositMemo = initializer.expectedDepositMemo;
        // 차량대여
        this.releaseManagerId = initializer.releaseManagerId; // 출고인
        this.contractNo = initializer.contractNo;
        this.departureDate = initializer.departureDate;
        this.departureFuelRate = initializer.departureFuelRate;
        this.departureFuelRateUnit = initializer.departureFuelRateUnit;
        this.departureMileage = initializer.departureMileage;
        // 기타준비사항
        this.checklistItems = initializer.checklistItems;
        this.releasePreparation = initializer.releasePreparation;
        this.releasePreparationMemo = initializer.releasePreparationMemo;
        this.assignedVehicle = initializer.assignedVehicle;
        this.assignedVehicleId = initializer.assignedVehicleId;
        this.dispatchArea = initializer.dispatchArea;
        this.dispatchAreaId = initializer.dispatchAreaId;
        this.contractor = initializer.contractor;
        this.driver = initializer.driver;
        // 차량반납
        this.returnManagerId = initializer.returnManagerId;
        this.returnDate = initializer.returnDate;
        this.returnFuelRate = initializer.returnFuelRate;
        this.returnFuelRateUnit = initializer.returnFuelRateUnit;
        this.returnMileage = initializer.returnMileage;
        this.returnGarage = initializer.returnGarage; // 반납 후 차량의 위치 변경
        this.returnGarageId = initializer.returnGarageId;
        // 예약취소
        this.canceledAt = initializer.canceledAt;
        this.cancelReason = initializer.cancelReason;
        this.cancelManager = initializer.cancelManager;
        this.cancelManagerId = initializer.cancelManagerId;
        this.customerAirportArrived = initializer.customerAirportArrived;
        this.numberOfPassengers = initializer.numberOfPassengers;
        this.flightDepartedAt = initializer.flightDepartedAt;
        this.privacyAgreementSignatureUrl =
            initializer.privacyAgreementSignatureUrl;
        this.wantedVendor = initializer.wantedVendor;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationWithoutOneToManyRelationsDto.prototype, "pickUpAt", void 0);
__decorate([
    Type(() => GarageDto),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "pickUpGarage", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationWithoutOneToManyRelationsDto.prototype, "dropOffAt", void 0);
__decorate([
    Type(() => GarageDto),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "dropOffGarage", void 0);
__decorate([
    Type(() => VendorWithoutRelationsDto),
    __metadata("design:type", VendorWithoutRelationsDto)
], ReservationWithoutOneToManyRelationsDto.prototype, "vendor", void 0);
__decorate([
    Type(() => VehicleModelWithoutRelationsDto),
    __metadata("design:type", VehicleModelWithoutRelationsDto)
], ReservationWithoutOneToManyRelationsDto.prototype, "vehicleModel", void 0);
__decorate([
    Type(() => VehicleWithoutRelationsDto),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "reservedVehicle", void 0);
__decorate([
    Type(() => UserWithoutRelationsDto),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "manager", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "expectedDepositDate", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "departureDate", void 0);
__decorate([
    Type(() => ChecklistItemDto),
    __metadata("design:type", Array)
], ReservationWithoutOneToManyRelationsDto.prototype, "checklistItems", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "releasePreparedAt", void 0);
__decorate([
    Type(() => VehicleWithoutRelationsDto),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "assignedVehicle", void 0);
__decorate([
    Type(() => DispatchAreaWithoutRelationsDto),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "dispatchArea", void 0);
__decorate([
    Type(() => ReservationPersonDto),
    __metadata("design:type", ReservationPersonDto)
], ReservationWithoutOneToManyRelationsDto.prototype, "contractor", void 0);
__decorate([
    Type(() => ReservationPersonDto),
    __metadata("design:type", ReservationPersonDto)
], ReservationWithoutOneToManyRelationsDto.prototype, "driver", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "returnDate", void 0);
__decorate([
    Type(() => GarageDto),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "returnGarage", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "canceledAt", void 0);
__decorate([
    Type(() => UserWithoutRelationsDto),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "cancelManager", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationWithoutOneToManyRelationsDto.prototype, "flightDepartedAt", void 0);
