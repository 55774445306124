export class DailyExcessInsuranceFeeDto {
    static instanceOf(obj) {
        if (obj === null || obj === undefined) {
            return false;
        }
        return ('regular' in obj &&
            'excess' in obj &&
            typeof obj.regular === 'number' &&
            typeof obj.excess === 'number' &&
            Object.keys(obj).length === 2);
    }
}
