var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Transform } from 'class-transformer';
import { IsArray, IsBoolean, IsDate, IsEnum, IsNumber, IsObject, IsOptional, } from 'class-validator';
import { GetManyQueryDto } from '../../../classes/common/request/get-many-query.dto';
import { TransformBoolean } from '../../../decorators';
import { ReservationState } from '../../../enums';
import { PartialType } from '../../../utils';
export class GetManyReservationsQueryDtoV2 extends PartialType(GetManyQueryDto) {
}
__decorate([
    IsArray(),
    IsEnum(ReservationState, { each: true }),
    IsOptional(),
    __metadata("design:type", Array)
], GetManyReservationsQueryDtoV2.prototype, "states", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDtoV2.prototype, "vehicleModelId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDtoV2.prototype, "pickUpGarageId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDtoV2.prototype, "dropOffGarageId", void 0);
__decorate([
    IsDate(),
    IsOptional(),
    __metadata("design:type", Date)
], GetManyReservationsQueryDtoV2.prototype, "fromPickUpAt", void 0);
__decorate([
    IsDate(),
    IsOptional(),
    __metadata("design:type", Date)
], GetManyReservationsQueryDtoV2.prototype, "toPickUpAt", void 0);
__decorate([
    IsDate(),
    IsOptional(),
    __metadata("design:type", Date)
], GetManyReservationsQueryDtoV2.prototype, "fromDropOffAt", void 0);
__decorate([
    IsDate(),
    IsOptional(),
    __metadata("design:type", Date)
], GetManyReservationsQueryDtoV2.prototype, "toDropOffAt", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDtoV2.prototype, "customerAirportArrived", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDtoV2.prototype, "releasePreparation", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDtoV2.prototype, "hasAssignedVehicle", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDtoV2.prototype, "hasDispatchArea", void 0);
__decorate([
    Transform(({ value }) => JSON.parse(value)),
    IsObject(),
    IsOptional(),
    __metadata("design:type", Object)
], GetManyReservationsQueryDtoV2.prototype, "fields", void 0);
