import { Button } from '@mui/material';
import { type FC } from 'react';

type Props = {
  label: string;
  errorMessage?: string;
};

const LoginButton: FC<Props> = ({ label, errorMessage }) => {
  return (
    <Button
      variant='contained'
      size='large'
      disabled={!!errorMessage}
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        position: 'fixed',
        left: 0,
        bottom: 0,
        flexShrink: 0,
        width: '100%',
        height: '240px',
        fontSize: 64,
        '&:disabled': {
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.dark,
          opacity: 1,
        },
        transition: ['background-color 0.2s ease-in'],
      })}
      type='submit'
    >
      {errorMessage || label}
    </Button>
  );
};

export default LoginButton;
