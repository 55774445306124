import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
export class HistoryDetailDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.historyId = initializer.historyId;
        this.productId = initializer.productId;
        this.productType = initializer.productType;
        this.productName = initializer.productName;
        this.description = initializer.description;
    }
}
