export class VehicleModelKeyOptionDto {
    constructor(initializer) {
        this.remoteKey = initializer.remoteKey;
        this.smartKey = initializer.smartKey;
        this.immobilizer = initializer.immobilizer;
        this.generalKey = initializer.generalKey;
        this.carKey = initializer.carKey;
        this.mobileKey = initializer.mobileKey;
    }
}
