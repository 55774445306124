import { Box, Button, Dialog, Typography } from '@mui/material';
import { getActualDropOffAt, type ReservationDto } from '@orcar/common';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import theme from '@/app/theme';
import { ChipButton } from '@/components/StyledMuiComponent';

interface ManyResultDialogProps {
  open: boolean;
  reservations: ReservationDto[];
  openConfirmSureUserDialog: (reservation: string) => void;
  onCancel: () => void;
  getIsRentalAvailable: (reservation: ReservationDto) => boolean;
}

const ManyResultDialog = ({
  open,
  reservations,
  openConfirmSureUserDialog,
  onCancel,
  getIsRentalAvailable,
}: ManyResultDialogProps) => {
  const navigate = useNavigate();

  return (
    <Dialog open={open}>
      <Box sx={{ width: '100%' }}>
        <Typography fontSize={40} fontWeight='bold' sx={{ marginBottom: 2 }}>
          배차받을 예약을 선택해주세요.
        </Typography>
        <Typography
          fontSize={32}
          fontWeight={400}
          color='text.secondary'
          lineHeight='44px'
          sx={{ marginBottom: 5 }}
        >
          해당 번호로 {reservations.length}건의 예약이 검색되었습니다.
        </Typography>
        {reservations.map((reservation, index) => (
          <Box
            onClick={() => {
              const alreadyCheckedInViaSure =
                !!reservation.contractor.sureUserId;

              if (alreadyCheckedInViaSure) {
                const isRentalAvailable = getIsRentalAvailable(reservation);
                if (!isRentalAvailable) return;

                openConfirmSureUserDialog(reservation.id);
              } else {
                navigate(`/${reservation.id}/terms`);
              }
            }}
            key={reservation.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              padding: 5,
              border: '1px solid #D4D4D4',
              gap: 3,
              ...(index !== 0 && { marginTop: '28px' }),
              ':hover': {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                background: theme.palette.primary.main + '08',
              },
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography
                fontSize={32}
                color='text.secondary'
                sx={{ marginRight: 4 }}
              >
                예약자명
              </Typography>
              <Typography fontSize={32} color='text.primary'>
                {reservation.customerName1}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <ChipButton useIcon={false}>선택하기</ChipButton>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                fontSize={32}
                color='text.secondary'
                sx={{ marginRight: 4 }}
              >
                대여차량
              </Typography>
              <Typography fontSize={32} color='text.primary'>
                {reservation.vehicleModel.name}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                fontSize={32}
                color='text.secondary'
                sx={{ marginRight: 4 }}
              >
                대여기간
              </Typography>
              <Typography fontSize={32} color='text.primary'>
                {dayjs(reservation.pickUpAt).format('YY.MM.DD HH:mm')} ~{' '}
                {dayjs(getActualDropOffAt(reservation)).format(
                  'YY.MM.DD HH:mm',
                )}
              </Typography>
            </Box>
          </Box>
        ))}
        <Button
          variant='contained'
          color='secondary'
          fullWidth
          sx={{
            marginTop: 5,
            height: 100,
            fontSize: 40,
            fontWeight: 500,
          }}
          onClick={onCancel}
        >
          취소
        </Button>
      </Box>
    </Dialog>
  );
};

export default ManyResultDialog;
