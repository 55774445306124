import styled from 'styled-components';
const ContractTable = styled.table `
  width: 100%;
  border-collapse: collapse;
  th {
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    background: #eee;
    color: #868686;
    border: solid 1px #333;
    padding: 2px;
  }
  td {
    font-weight: normal;
    border: solid 1px #333;
    padding: 2px;
    font-size: 12px;
  }
  .fixed-row {
    height: 20px;
  }
  img {
    display: block;
  }
`;
export default ContractTable;
