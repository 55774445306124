export var TaskType;
(function (TaskType) {
    TaskType["USER"] = "USER";
    TaskType["USER_PROFILE"] = "USER_PROFILE";
    TaskType["COMPANY_PROFILE"] = "COMPANY_PROFILE";
    TaskType["VENDOR"] = "VENDOR";
    TaskType["VEHICLE"] = "VEHICLE";
    TaskType["VEHICLE_MODEL"] = "VEHICLE_MODEL";
    TaskType["INSURANCE"] = "INSURANCE";
    TaskType["FEE"] = "FEE";
})(TaskType || (TaskType = {}));
