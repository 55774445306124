import { type LoginResponseDto } from '@orcar/common';
import { type CompanyLoginForm } from '@/interfaces/company-login-form';
import axiosClient from './axiosClient';

export const login = async (
  input: CompanyLoginForm,
): Promise<LoginResponseDto> => {
  const { remember, ...data } = input;
  const response = await axiosClient.post('/auth/login', data);
  return response.data;
};

export const refreshLogin = async (): Promise<LoginResponseDto> => {
  const response = await axiosClient.post('/auth/refresh');
  return response.data;
};

export const logout = async (): Promise<void> => {
  await axiosClient.post('/auth/logout');
};

export const checkPassword = async (
  text: string,
): Promise<{ isValid: boolean }> => {
  const response = await axiosClient.post(`/auth/check/password`, {
    password: text,
  });
  return response.data;
};
