import { Box, Button, Checkbox, Dialog, Typography } from '@mui/material';
import {
  calculateExtraItemFeeForReservation,
  ExtraItemCategory,
  getRentalDays,
  type UpdateReservationExtraItemDto,
} from '@orcar/common';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import theme from '@/app/theme';
import BottomButtons from '@/components/BottomButtons';
import TitleMessage from '@/components/TitleMessage';
import { useGetExtraItems } from '@/hooks/extra-item.hook';
import {
  useGetReservation,
  useUpdateReservation,
} from '@/hooks/reservation.hook';
import Receipt from '../reservationInfo/components/Receipt';

const ExtraItemPage = () => {
  const id = useParams().id;
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState<
    UpdateReservationExtraItemDto[]
  >([]);

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState('');

  const { data, isLoading } = useGetReservation({ id });
  const { mutate } = useUpdateReservation();
  const { data: extraItemData, isLoading: isExtraItemLoading } =
    useGetExtraItems({
      params: {
        reservationPeriod: getRentalDays(data?.pickUpAt, data?.dropOffAt),
        vehicleModelId: data?.vehicleModelId,
      },
    });

  useEffect(() => {
    if (data?.extraItems && data?.extraItems.length > 0) {
      setSelectedItems(
        data.extraItems
          .filter(
            (item) =>
              item.extraItem.category !== ExtraItemCategory.SPECIAL_CONTRACT,
          )
          .map((item) => {
            return {
              id: item.id,
              extraItemId: item.extraItem.id,
              quantity: item.quantity,
              fee: item.fee,
              directPaymentPrice: item.directPaymentPrice ?? 0,
            };
          }),
      );
    }
  }, [data]);

  if (isLoading || isExtraItemLoading) {
    return <></>;
  }

  if (!data || !id) {
    return <Navigate to='/' replace />;
  }

  const extraItems = extraItemData?.data.filter(
    (item) =>
      item.category !== ExtraItemCategory.SPECIAL_CONTRACT &&
      (item.vehicleModels.find((model) => model.id === data.vehicleModelId) ||
        item.global) &&
      item.visibility.kiosk,
  );

  if (!extraItems || extraItems?.length === 0) {
    return <Navigate to={`/${id}/driver-info/1`} replace />;
  }

  const handleError = (errorMessage: string) => {
    setErrorDialogMessage(errorMessage);
    setErrorDialogOpen(true);
  };

  const handleClickNext = () => {
    if (
      selectedItems.every((item) =>
        data.extraItems
          .filter(
            (item) =>
              item.extraItem.category !== ExtraItemCategory.SPECIAL_CONTRACT,
          )
          .some((extraItem) => extraItem.id === item.id),
      ) &&
      selectedItems.length ===
        data.extraItems.filter(
          (item) =>
            item.extraItem.category !== ExtraItemCategory.SPECIAL_CONTRACT,
        ).length
    ) {
      navigate(`/${id}/driver-info/1`);
    } else {
      mutate(
        {
          id,
          input: {
            extraItems: [
              ...data.extraItems
                .filter(
                  (item) =>
                    item.extraItem.category ===
                    ExtraItemCategory.SPECIAL_CONTRACT,
                )
                .map((item) => {
                  return {
                    id: item.id,
                  };
                }),
              ...selectedItems,
            ],
          },
        },
        {
          onSuccess: () => {
            navigate(`/${id}/driver-info/1`);
          },
          onError: () => {
            handleError('기타상품 등록에 실패했습니다. 다시 시도해주세요');
          },
        },
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingBottom: '240px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          background: '#f5f5f5',
          paddingTop: '100px',
          paddingX: '80px',
        }}
      >
        <TitleMessage message='기타상품 추가하기' />
        <Box
          sx={{
            marginTop: '56px',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 550,
            overflowY: 'scroll',
          }}
        >
          {extraItems.map((item) => {
            const selected = selectedItems.some(
              (selectedItem) => selectedItem.extraItemId === item.id,
            );

            const existingItem = data.extraItems.find(
              (extraItem) =>
                extraItem.extraItem.id === item.id &&
                extraItem.extraItem.category !==
                  ExtraItemCategory.SPECIAL_CONTRACT,
            );

            const fee = calculateExtraItemFeeForReservation(item, data);

            return (
              <Box
                key={item.id}
                sx={{
                  marginTop: 2,
                  padding: '32px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  border: '1px solid',
                  borderColor: selected
                    ? 'primary.main'
                    : theme.palette.grey[200],
                  background: selected ? 'rgba(51, 202, 119, 0.08)' : '#ffffff',
                }}
                onClick={() => {
                  if (selected) {
                    setSelectedItems(
                      selectedItems.filter(
                        (selectedItem) => selectedItem.extraItemId !== item.id,
                      ),
                    );
                  } else {
                    if (existingItem) {
                      setSelectedItems([
                        ...selectedItems,
                        {
                          id: existingItem.id,
                          extraItemId: item.id,
                          quantity: existingItem.quantity,
                          fee: existingItem.fee,
                          directPaymentPrice:
                            existingItem.directPaymentPrice ?? undefined,
                        },
                      ]);
                      return;
                    }

                    setSelectedItems([
                      ...selectedItems,
                      {
                        extraItemId: item.id,
                        quantity: 1,
                        fee,
                        directPaymentPrice: fee,
                      },
                    ]);
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 42,
                      },
                    }}
                    checked={selected}
                  />
                  <Typography
                    fontWeight='bold'
                    fontSize={32}
                    textAlign='left'
                    color={selected ? 'primary.main' : 'text.primary'}
                  >
                    {item.name}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography
                    fontWeight='bold'
                    fontSize={32}
                    textAlign='left'
                    color={selected ? 'primary.main' : 'text.primary'}
                  >
                    {(existingItem?.directPaymentPrice ?? fee).toLocaleString()}
                    원
                  </Typography>
                </Box>
                <Typography
                  fontSize={28}
                  color={selected ? 'primary.main' : 'grey'}
                  sx={{ marginTop: '16px', marginLeft: '48px' }}
                >
                  {item.detail}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Receipt
        reservation={{
          ...data,
          extraItems: data.extraItems.filter(
            (item) =>
              item.extraItem.category === ExtraItemCategory.SPECIAL_CONTRACT ||
              selectedItems.some(
                (selectedItem) =>
                  selectedItem.extraItemId === item.extraItem.id,
              ),
          ),
        }}
        possibleExtraFee={
          selectedItems.length > 0
            ? selectedItems.reduce((acc, cur) => {
                const extraItem = extraItems.find(
                  (item) => item.id === cur.extraItemId,
                );
                if (!extraItem) {
                  return acc;
                }
                if (data.extraItems.some((item) => item.id === cur.id)) {
                  return acc;
                }
                return (
                  acc + calculateExtraItemFeeForReservation(extraItem, data)
                );
              }, 0)
            : undefined
        }
      />
      <BottomButtons
        label='다음'
        homeButton
        backButton
        onClick={handleClickNext}
      />
      <Dialog open={errorDialogOpen}>
        <Box sx={{ width: '100%' }}>
          <Typography
            fontSize={32}
            fontWeight='regular'
            color='text.secondary'
            lineHeight='44px'
            sx={{ marginBottom: 5 }}
          >
            {errorDialogMessage}
          </Typography>
          <Button
            variant='contained'
            fullWidth
            sx={{ height: 100, fontSize: 40, fontWeight: 500 }}
            onClick={() => {
              setErrorDialogOpen(false);
            }}
          >
            닫기
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ExtraItemPage;
