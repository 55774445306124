export var DriverLicenseType;
(function (DriverLicenseType) {
    DriverLicenseType["FIRST_LARGE"] = "FIRST_LARGE";
    DriverLicenseType["FIRST_ORDINARY"] = "FIRST_ORDINARY";
    DriverLicenseType["FIRST_SMALL"] = "FIRST_SMALL";
    DriverLicenseType["FIRST_LARGE_TRAILER"] = "FIRST_LARGE_TRAILER";
    DriverLicenseType["FIRST_WRECKER"] = "FIRST_WRECKER";
    DriverLicenseType["FIRST_SMALL_TRAILER"] = "FIRST_SMALL_TRAILER";
    DriverLicenseType["SECOND_ORDINARY"] = "SECOND_ORDINARY";
    DriverLicenseType["SECOND_SMALL"] = "SECOND_SMALL";
    DriverLicenseType["SECOND_MOTORIZED_BICYCLE"] = "SECOND_MOTORIZED_BICYCLE";
})(DriverLicenseType || (DriverLicenseType = {}));
// licenseTypeCode
// 11 1종대형
// 12 1종보통
// 13 1종소형
// 14 대형견인차(트레일러)
// 15 구난차(레커)
// 16 소형견인차
// 32 2종보통
// 33 2종소형
// 38 2종원자
