export var ExtraItemFeeCriterion;
(function (ExtraItemFeeCriterion) {
    ExtraItemFeeCriterion["RESERVATION"] = "RESERVATION";
    ExtraItemFeeCriterion["ONE"] = "ONE";
    ExtraItemFeeCriterion["TWO"] = "TWO";
    ExtraItemFeeCriterion["THREE"] = "THREE";
    ExtraItemFeeCriterion["FOUR"] = "FOUR";
    ExtraItemFeeCriterion["FIVE"] = "FIVE";
    ExtraItemFeeCriterion["SIX"] = "SIX";
    ExtraItemFeeCriterion["SEVEN"] = "SEVEN";
})(ExtraItemFeeCriterion || (ExtraItemFeeCriterion = {}));
