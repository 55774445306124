import { TableCell, type TableCellProps } from '@mui/material';
import { useState } from 'react';

const Key = (props: TableCellProps & { isPressed?: boolean }) => {
  const { sx, isPressed, ...rest } = props;

  const [isTouched, setIsTouched] = useState(false);

  return (
    <TableCell
      sx={{
        color: 'text.secondary',
        fontSize: 40,
        fontWeight: 'regular',
        border: '1px solid #D4D4D4',
        bgcolor: 'transparent',
        verticalAlign: 'middle',
        cursor: 'pointer',
        ...sx,
        ...((isTouched || isPressed) && {
          bgcolor: '#D4D4D4',
        }),
      }}
      padding='none'
      align='center'
      onTouchStart={() => setIsTouched(true)}
      onTouchEnd={() => setIsTouched(false)}
      {...rest}
    />
  );
};

export default Key;
