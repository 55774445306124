import {
  type ExtraItemDto,
  type GetManyApiProps,
  type GetManyExtraItemsQueryDto,
  type Paginated,
} from '@orcar/common';
import { useQuery } from '@tanstack/react-query';
import { getExtraItems } from '@/apis/extra-items.api';

export const useGetExtraItems = (
  props: GetManyApiProps<GetManyExtraItemsQueryDto, Paginated<ExtraItemDto>> = {
    params: {},
  },
) => {
  const { params } = props;
  return useQuery({
    queryKey: ['extraItemList', params],
    queryFn: () => getExtraItems(params),
  });
};
