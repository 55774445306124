import {
  Box,
  Button,
  Dialog,
  Table,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material';
import { type Nullable } from '@orcar/common';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import BackspaceIcon from '@/components/BackspaceIcon';
import Key from './Key';
import KeypadInput from './KeypadInput';

type BirthDateKeypadProps = {
  defaultValue?: Nullable<string>;
  onConfirm: (value: string) => void;
};

const BirthDateKeypad = ({ defaultValue, onConfirm }: BirthDateKeypadProps) => {
  const [inputValue, setInputValue] = useState('');
  const [dialogState, setDialogState] = useState({ open: false, message: '' });

  useEffect(() => {
    setInputValue(
      defaultValue ? dayjs(defaultValue).format('YYYYMMDD').slice(2, 8) : '',
    );
  }, [defaultValue]);

  const handleClick = (value: string) => {
    if (value === 'backspace') {
      setInputValue((prev) => prev.slice(0, prev.length - 1));
    } else if (inputValue.length >= 6) {
      return;
    } else {
      setInputValue((prev) => prev + value);
    }
  };

  const convertToYYYYMMDD = (value: string) => {
    let fullYear: string;

    const standardYear = dayjs().year() % 100;
    const year = value.slice(0, 2);
    const month = value.slice(2, 4);
    const day = value.slice(4, 6);

    if (parseInt(year) >= standardYear) {
      fullYear = '19' + value.slice(0, 2);
    } else {
      fullYear = '20' + value.slice(0, 2);
    }

    return fullYear + month + day;
  };

  const handleConfirm = (value: string) => {
    if (value.length !== 6) {
      setDialogState({
        open: true,
        message: '생년월일을 YYMMDD 형식으로 입력해주세요.',
      });
      return;
    }

    const birthDate = convertToYYYYMMDD(value);
    const year = parseInt(birthDate.slice(0, 4));
    const month = parseInt(birthDate.slice(4, 6));
    const day = parseInt(birthDate.slice(6, 8));

    if (year > dayjs().year() || year < 1900 || month < 1 || month > 12) {
      setDialogState({
        open: true,
        message: '올바른 생년월일을 입력해주세요.',
      });
      return;
    }

    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    const daysInMonth = [
      31,
      isLeapYear ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];

    if (!(day > 0 && day <= daysInMonth[month - 1])) {
      setDialogState({
        open: true,
        message: '올바른 생년월일을 입력해주세요.',
      });
      return;
    }

    onConfirm(birthDate);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        bgcolor: 'white',
        height: 599,
        flexDirection: 'column',
      }}
    >
      <Table sx={{ width: '100%', flexGrow: 1 }}>
        <TableBody>
          <TableRow>
            <KeypadInput
              colSpan={3}
              value={inputValue}
              placeholder='YYMMDD'
              onDelete={() => {
                setInputValue('');
              }}
            />
          </TableRow>
          <TableRow>
            {['1', '2', '3'].map((value, _) => (
              <Key
                key={_}
                sx={{ width: 100, height: 100 }}
                onClick={() => handleClick(value)}
              >
                {value}
              </Key>
            ))}
          </TableRow>
          <TableRow>
            {['4', '5', '6'].map((value, _) => (
              <Key
                key={_}
                sx={{ height: 100 }}
                onClick={() => handleClick(value)}
              >
                {value}
              </Key>
            ))}
          </TableRow>
          <TableRow>
            {['7', '8', '9'].map((value, _) => (
              <Key
                key={_}
                sx={{ height: 100 }}
                onClick={() => handleClick(value)}
              >
                {value}
              </Key>
            ))}
          </TableRow>
          <TableRow>
            {['', '0'].map((value, _) => (
              <Key
                key={_}
                sx={{ height: 100 }}
                onClick={() => handleClick(value)}
              >
                {value}
              </Key>
            ))}
            <Key sx={{ height: 100 }} onClick={() => handleClick('backspace')}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <BackspaceIcon />
              </Box>
            </Key>
          </TableRow>
          <TableRow>
            <Key
              sx={{
                width: 100,
                height: 100,
                bgcolor: 'primary.main',
                color: 'white',
                fontWeight: 'medium',
              }}
              colSpan={3}
              onClick={() => handleConfirm(inputValue)}
            >
              확인
            </Key>
          </TableRow>
        </TableBody>
      </Table>
      <Dialog open={dialogState.open}>
        <Box sx={{ width: '100%' }}>
          <Typography
            fontSize={32}
            fontWeight='regular'
            color='text.secondary'
            lineHeight='44px'
            sx={{ marginBottom: 5, whiteSpace: 'pre-line' }}
          >
            {dialogState.message}
          </Typography>
          <Button
            variant='contained'
            fullWidth
            sx={{ height: 100, fontSize: 40, fontWeight: 500 }}
            onClick={() => setDialogState((prev) => ({ ...prev, open: false }))}
          >
            닫기
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default BirthDateKeypad;
