var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { ReservationStatisticBranchInfoDto } from './statistic-branch-info.dto';
export class ReservationAnnualStatisticByVehicleModelPerBranchDto {
    constructor({ year, month, branchInfo, reservationCount, vehicleCount, totalUsageTime, averageUsageTime, totalFee, averageTotalFeePerReservation, averageFeePerVehicle, vehicleFee, insuranceFee, extensionFee, extraItemFee, }) {
        this.year = year;
        this.month = month;
        this.branchInfo = branchInfo;
        this.reservationCount = reservationCount;
        this.vehicleCount = vehicleCount;
        this.totalUsageTime = totalUsageTime;
        this.averageUsageTime = averageUsageTime;
        this.totalFee = totalFee;
        this.averageTotalFeePerReservation = averageTotalFeePerReservation;
        this.averageFeePerVehicle = averageFeePerVehicle;
        this.vehicleFee = vehicleFee;
        this.insuranceFee = insuranceFee;
        this.extensionFee = extensionFee;
        this.extraItemFee = extraItemFee;
    }
}
__decorate([
    Type(() => ReservationStatisticBranchInfoDto),
    __metadata("design:type", Array)
], ReservationAnnualStatisticByVehicleModelPerBranchDto.prototype, "branchInfo", void 0);
