import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
export class ReservationExtensionDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.startDate = initializer.startDate;
        this.endDate = initializer.endDate;
        this.usageTime = initializer.usageTime;
        this.fee = initializer.fee;
        this.directPaymentPrice = initializer.directPaymentPrice;
    }
}
