import { type LoginResponseDto, UserRole } from '@orcar/common';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

const initialState: LoginResponseDto = {
  login: false,
  id: 0,
  username: '',
  name: '',
  companyName: '',
  authority: null,
  role: UserRole.NORMAL,
  landingPage: '',
  vendor: null,
  isOnlineContract: false,
};

interface LoginInformationPayload {
  key: keyof LoginResponseDto;
  value: LoginResponseDto[keyof LoginResponseDto];
}

const loginInformationSlice = createSlice({
  name: 'loginInformationSlice',
  initialState,
  reducers: {
    setLoginInformation: (state, action: PayloadAction<LoginResponseDto>) =>
      action.payload,
    setLoginInformationEach: (
      state,
      { payload: { key, value } }: PayloadAction<LoginInformationPayload>,
    ) => ({
      ...state,
      [key]: value,
    }),
    setLogOut: () => initialState,
  },
});

export const { setLoginInformation, setLoginInformationEach, setLogOut } =
  loginInformationSlice.actions;
export default loginInformationSlice.reducer;
