var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { InsuranceDetailDto } from '../../../classes/insurance';
export class OnlineCheckInReservationInsuranceDto extends InsuranceDetailDto {
    constructor(initializer) {
        super(initializer);
        this.name = initializer.name;
        this.insuranceId = initializer.insuranceId;
        this.fee = initializer.fee;
        this.isDeletable = initializer.isDeletable;
    }
}
export class OnlineCheckInReservationExtraItemDto {
    constructor(initializer) {
        this.extraItemId = initializer.extraItemId;
        this.name = initializer.name;
        this.category = initializer.category;
        this.quantity = initializer.quantity;
        this.fee = initializer.fee;
        this.detail = initializer.detail;
        this.isDeletable = initializer.isDeletable;
    }
}
export class OnlineCheckInReservationDto {
    constructor(initializer) {
        this.vehicleModelName = initializer.vehicleModelName;
        this.vehicleModelDriverAge = initializer.vehicleModelDriverAge;
        this.vehicleModelDriverExperience =
            initializer.vehicleModelDriverExperience;
        this.pickUpAt = initializer.pickUpAt;
        this.pickUpGarageName = initializer.pickUpGarageName;
        this.dropOffAt = initializer.dropOffAt;
        this.dropOffGarageName = initializer.dropOffGarageName;
        this.insurances = initializer.insurances;
        this.extraItems = initializer.extraItems;
        this.vehicleFee = initializer.vehicleFee;
        this.prePaymentFee = initializer.prePaymentFee;
        this.driver1 = initializer.driver1;
        this.driver2 = initializer.driver2;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], OnlineCheckInReservationDto.prototype, "pickUpAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], OnlineCheckInReservationDto.prototype, "dropOffAt", void 0);
__decorate([
    Type(() => OnlineCheckInReservationInsuranceDto),
    __metadata("design:type", Array)
], OnlineCheckInReservationDto.prototype, "insurances", void 0);
__decorate([
    Type(() => OnlineCheckInReservationExtraItemDto),
    __metadata("design:type", Array)
], OnlineCheckInReservationDto.prototype, "extraItems", void 0);
