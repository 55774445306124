export var ExtraItemCategory;
(function (ExtraItemCategory) {
    //기타
    ExtraItemCategory["ETC"] = "ETC";
    //배차비
    ExtraItemCategory["DISPATCH_FEE"] = "DISPATCH_FEE";
    //수리비
    ExtraItemCategory["REPAIR_FEE"] = "REPAIR_FEE";
    //연료비
    ExtraItemCategory["FUEL_FEE"] = "FUEL_FEE";
    //자차특약
    ExtraItemCategory["SPECIAL_CONTRACT"] = "SPECIAL_CONTRACT";
    //주차비
    ExtraItemCategory["PARKING_FEE"] = "PARKING_FEE";
    //체인
    ExtraItemCategory["CHAIN"] = "CHAIN";
    //충전카드
    ExtraItemCategory["CHARGE_CARD"] = "CHARGE_CARD";
    //회차비
    ExtraItemCategory["ROUND_FEE"] = "ROUND_FEE";
})(ExtraItemCategory || (ExtraItemCategory = {}));
