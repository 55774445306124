import { Box, CircularProgress, Dialog, Typography } from '@mui/material';

const LoadingDialog = ({
  content,
  open,
}: {
  content: string;
  open: boolean;
}) => {
  return (
    <Dialog open={open}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 250,
          alignItems: 'center',
        }}
      >
        <Typography fontSize={40} fontWeight='bold' sx={{ marginBottom: 10 }}>
          {content}
        </Typography>
        <CircularProgress size={100} />
      </Box>
    </Dialog>
  );
};

export default LoadingDialog;
