var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common';
import { FeeWeekendPolicyDto } from '../../../classes/fee/response/fee-weekend-policy.dto';
import { VehicleModelAudioDto } from './vehicle-model-audio.dto';
import { VehicleModelFeeDto } from './vehicle-model-fee.dto';
import { VehicleModelImageDto } from './vehicle-model-image.dto';
import { VehicleModelInsuranceDto } from './vehicle-model-insurance.dto';
import { VehicleModelKeyOptionDto } from './vehicle-model-key-option.dto';
import { VehicleModelOptionDto } from './vehicle-model-option.dto';
export class VehicleModelWithImagesDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.name = initializer.name;
        this.graphOrder = initializer.graphOrder;
        this.type = initializer.type;
        this.state = initializer.state;
        this.manufacturer = initializer.manufacturer;
        this.fuel = initializer.fuel;
        this.transmission = initializer.transmission;
        this.seat = initializer.seat;
        this.capacity = initializer.capacity;
        this.mileage = initializer.mileage;
        this.audio = initializer.audio;
        this.drivingSystem = initializer.drivingSystem;
        this.keyOption = initializer.keyOption;
        this.modelOption = initializer.modelOption;
        this.driverAge = initializer.driverAge;
        this.driverExperience = initializer.driverExperience;
        this.memo = initializer.memo;
        this.calculationMethod = initializer.calculationMethod;
        this.weekend = initializer.weekend;
        this.fee = initializer.fee;
        this.insurance1 = initializer.insurance1;
        this.insurance1Id = initializer.insurance1Id;
        this.insurance2 = initializer.insurance2;
        this.insurance2Id = initializer.insurance2Id;
        this.images = initializer.images;
    }
}
__decorate([
    Type(() => VehicleModelAudioDto),
    __metadata("design:type", VehicleModelAudioDto)
], VehicleModelWithImagesDto.prototype, "audio", void 0);
__decorate([
    Type(() => VehicleModelKeyOptionDto),
    __metadata("design:type", VehicleModelKeyOptionDto)
], VehicleModelWithImagesDto.prototype, "keyOption", void 0);
__decorate([
    Type(() => VehicleModelOptionDto),
    __metadata("design:type", VehicleModelOptionDto)
], VehicleModelWithImagesDto.prototype, "modelOption", void 0);
__decorate([
    Type(() => FeeWeekendPolicyDto),
    __metadata("design:type", FeeWeekendPolicyDto)
], VehicleModelWithImagesDto.prototype, "weekend", void 0);
__decorate([
    Type(() => VehicleModelFeeDto),
    __metadata("design:type", VehicleModelFeeDto)
], VehicleModelWithImagesDto.prototype, "fee", void 0);
__decorate([
    Type(() => VehicleModelInsuranceDto),
    __metadata("design:type", Object)
], VehicleModelWithImagesDto.prototype, "insurance1", void 0);
__decorate([
    Type(() => VehicleModelInsuranceDto),
    __metadata("design:type", Object)
], VehicleModelWithImagesDto.prototype, "insurance2", void 0);
__decorate([
    Type(() => VehicleModelImageDto),
    __metadata("design:type", Array)
], VehicleModelWithImagesDto.prototype, "images", void 0);
