export var BoardType;
(function (BoardType) {
    BoardType["VENDOR_NOTICE"] = "VENDOR_NOTICE";
    BoardType["COMPANY"] = "COMPANY";
    BoardType["MAINTENANCE"] = "MAINTENANCE";
})(BoardType || (BoardType = {}));
export var NotionBoardType;
(function (NotionBoardType) {
    NotionBoardType["UPDATE_NOTE"] = "UPDATE_NOTE";
    NotionBoardType["NOTICE"] = "NOTICE";
    NotionBoardType["USER_GUIDE"] = "USER_GUIDE";
})(NotionBoardType || (NotionBoardType = {}));
