var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BranchDto } from '../../../classes/branch';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { UserWithoutRelationsDto } from '../../../classes/user';
import { VehicleWithVehicleModelDto } from '../../../classes/vehicle/response/vehicle-with-vehicle-model.dto';
import { OmitType } from '../../../utils';
import { VehicleAccidentPersonDto } from './vehicle-accident-person.dto';
export class VehicleAccidentDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.manager = initializer.manager;
        this.managerId = initializer.managerId;
        this.vehicle = initializer.vehicle;
        this.vehicleId = initializer.vehicleId;
        this.branch = initializer.branch;
        this.branchId = initializer.branchId;
        this.state = initializer.state;
        this.date = initializer.date;
        this.location = initializer.location;
        this.detail = initializer.detail;
        this.note = initializer.note;
        this.personA = initializer.personA;
        this.personB = initializer.personB;
    }
}
__decorate([
    Type(() => UserWithoutRelationsDto),
    __metadata("design:type", UserWithoutRelationsDto)
], VehicleAccidentDto.prototype, "manager", void 0);
__decorate([
    Type(() => VehicleWithVehicleModelDto),
    __metadata("design:type", VehicleWithVehicleModelDto)
], VehicleAccidentDto.prototype, "vehicle", void 0);
__decorate([
    Type(() => BranchDto),
    __metadata("design:type", BranchDto)
], VehicleAccidentDto.prototype, "branch", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], VehicleAccidentDto.prototype, "date", void 0);
__decorate([
    Type(() => VehicleAccidentPersonDto),
    __metadata("design:type", VehicleAccidentPersonDto)
], VehicleAccidentDto.prototype, "personA", void 0);
__decorate([
    Type(() => VehicleAccidentPersonDto),
    __metadata("design:type", VehicleAccidentPersonDto)
], VehicleAccidentDto.prototype, "personB", void 0);
export class VehicleAccidentWithoutRelationsDto extends OmitType(VehicleAccidentDto, ['vehicle', 'branch']) {
    constructor(initializer) {
        super();
        this.id = initializer.id;
        this.createdAt = initializer.createdAt;
        this.updatedAt = initializer.updatedAt;
        this.deletedAt = initializer.deletedAt;
        this.manager = initializer.manager;
        this.managerId = initializer.managerId;
        this.vehicleId = initializer.vehicleId;
        this.branchId = initializer.branchId;
        this.state = initializer.state;
        this.date = initializer.date;
        this.location = initializer.location;
        this.detail = initializer.detail;
        this.note = initializer.note;
        this.personA = initializer.personA;
        this.personB = initializer.personB;
    }
}
