export var ReservationState;
(function (ReservationState) {
    ReservationState["IN_USE"] = "IN_USE";
    ReservationState["PENDING"] = "PENDING";
    ReservationState["IN_PROGRESS"] = "IN_PROGRESS";
    ReservationState["CONFIRMED"] = "CONFIRMED";
    ReservationState["CANCELED"] = "CANCELED";
    ReservationState["CLOSED"] = "CLOSED";
    ReservationState["RETURNED"] = "RETURNED";
})(ReservationState || (ReservationState = {}));
