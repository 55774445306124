import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRefreshLogin } from '@/hooks/auth.hook';
import CommonLayout from '@/layout/CommonLayout';
import ContractAutographPage from '@/pages/contract/ContractAutographPage';
import ContractPage from '@/pages/contract/ContractPage';
import DriverInfoPage from '@/pages/driverInfo/DriverInfoPage';
import DriverLicenseInfoPage from '@/pages/driverInfo/DriverLicenseInfoPage';
import ExtraItemPage from '@/pages/extraItem/ExtraItemPage';
import SpecialContractPage from '@/pages/extraItem/SpecialContractPage';
import StartPage from '@/pages/home/StartPage';
import LoginPage from '@/pages/login/LoginPage';
import NotFound from '@/pages/NotFound';
import PaymentPage from '@/pages/payment/PaymentPage';
import ReservationInfoPage from '@/pages/reservationInfo/ReservationInfoPage';
import TermsAgreePage from '@/pages/terms/TermsAgreePage';
import VehicleReadyPage from '@/pages/vehicleReady/VehicleReadyPage';

const App = () => {
  const { mutate } = useRefreshLogin();

  useEffect(() => {
    mutate();
  }, [mutate]);

  return (
    <Routes>
      <Route element={<CommonLayout />}>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/' element={<StartPage />} />
        <Route path='/:id/terms' element={<TermsAgreePage />} />
        <Route path='/:id/reservation-info' element={<ReservationInfoPage />} />
        <Route path='/:id/special-contract' element={<SpecialContractPage />} />
        <Route path='/:id/extra-item' element={<ExtraItemPage />} />
        <Route
          path='/:id/driver-info/:driverNumber'
          element={<DriverInfoPage />}
        />
        <Route
          path='/:id/driver-license-info/:driverNumber'
          element={<DriverLicenseInfoPage />}
        />
        <Route path='/:id/contract-info' element={<ContractPage />} />
        <Route
          path='/:id/signature/:driverNumber'
          element={<ContractAutographPage />}
        />
        <Route path='/:id/payment' element={<PaymentPage />} />
        <Route path='/vehicle-ready' element={<VehicleReadyPage />} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
