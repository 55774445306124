import { Box, Divider, Typography } from '@mui/material';
import { ContractSectionType } from '@orcar/common';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import BottomButtons from '@/components/BottomButtons';
import TitleMessage from '@/components/TitleMessage';
import Viewer from '@/components/Viewer';
import { useGetContractSections } from '@/hooks/contract-section.hook';
import { useGetReservation } from '@/hooks/reservation.hook';

const ContractPage = () => {
  const navigate = useNavigate();
  const id = useParams().id;

  const { data: reservation, isLoading } = useGetReservation({
    id,
  });
  const { data: contractSectionData } = useGetContractSections({
    params: {
      type: ContractSectionType.MAIN_CONTENT,
    },
  });

  if (isLoading) {
    return <></>;
  }

  if (!reservation) {
    return <Navigate to='/' replace />;
  }

  const mainContent =
    contractSectionData?.data?.find(
      (section) => section.branchId === reservation.reservedVehicle?.branchId,
    ) || contractSectionData?.data?.[0];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          background: '#f5f5f5',
          paddingTop: '100px',
          paddingX: '80px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TitleMessage message='계약 조건을 확인해 주세요.' />
        <Box sx={{ height: '56px' }} />
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '1260px',
            background: theme.palette.grey[50],
            border: '1px solid #d4d4d4',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              maxHeight: 'calc(100%)',
              flexDirection: 'column',
              paddingX: '40px',
              paddingTop: '40px',
            }}
          >
            <Typography fontWeight={'bold'} fontSize={40}>
              계약 조건
            </Typography>
            <Divider sx={{ marginY: '40px' }} />
            <Box
              sx={{
                width: '100%',
                height: '1090px',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '0px',
                },
              }}
            >
              <Viewer content={mainContent?.content || ''} />
            </Box>
          </Box>
        </Box>
      </Box>
      <BottomButtons
        label='다음'
        homeButton
        backButton
        onClick={() => {
          const hasContractSignature = !!reservation.contractor.signatureUrl;
          const hasDriver =
            reservation.driver.name &&
            reservation.driver.driverLicenseType &&
            reservation.driver.driverLicenseNumber;

          if (hasContractSignature && hasDriver) {
            navigate(`/${id}/signature/2`);
          } else {
            navigate(`/${id}/signature/privacy`);
          }
        }}
      />
    </Box>
  );
};

export default ContractPage;
