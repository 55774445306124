export class HourlyInsuranceFeeDto {
    static instanceOf(obj) {
        if (obj === null || obj === undefined) {
            return false;
        }
        return ('hour6' in obj &&
            'hour12' in obj &&
            'hour24' in obj &&
            'excess' in obj &&
            typeof obj.hour6 === 'number' &&
            typeof obj.hour12 === 'number' &&
            typeof obj.hour24 === 'number' &&
            typeof obj.excess === 'number' &&
            Object.keys(obj).length === 4);
    }
}
