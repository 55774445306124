import { Box, Dialog, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { type FC } from 'react';
import SuccessfullyDoneJson from '@/assets/lottie/successfully-done.json';
import { GreyButton } from '@/components/StyledMuiComponent';

type Props = {
  open: boolean;
  onClosed: () => void;
};

const PaymentManagerApprovalDialog: FC<Props> = ({ open, onClosed }) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          position: 'relative',
          padding: 0,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          background: '#ffffff',
          padding: '40px',
        }}
      >
        <Typography fontSize={40} fontWeight='bold'>
          {'현금 결제 확인'}
        </Typography>
        <Box sx={{ height: '16px' }} />
        <Typography
          fontSize={32}
          fontWeight='regular'
          color='text.secondary'
          sx={{ whiteSpace: 'pre-line' }}
        >
          {'담당 직원을 통해 현금 결제를 완료해주세요.'}
        </Typography>
        <Box sx={{ height: '40px' }} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Lottie
            animationData={SuccessfullyDoneJson}
            loop={true}
            style={{ height: 240 }}
          />
        </Box>
        <Box sx={{ height: '40px' }} />
        <GreyButton
          sx={{
            width: '100%',
            fontSize: 40,
          }}
          onClick={() => {
            onClosed();
          }}
        >
          <Typography fontSize={40} fontWeight='medium'>
            취소
          </Typography>
        </GreyButton>
      </Box>
    </Dialog>
  );
};

export default PaymentManagerApprovalDialog;
