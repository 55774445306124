var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
export class VehicleAccidentPersonDto {
    constructor(initializer) {
        this.vehicleNumber = initializer.vehicleNumber;
        this.driverName = initializer.driverName;
        this.birthDate = initializer.birthDate;
        this.licenseNumber = initializer.licenseNumber;
        this.phoneNumber = initializer.phoneNumber;
        this.insuranceCompany = initializer.insuranceCompany;
        this.serviceCenter = initializer.serviceCenter;
        this.note = initializer.note;
        this.vehicleDamageFee = initializer.vehicleDamageFee;
        this.personDamageFee = initializer.personDamageFee;
        this.materialDamageFee = initializer.materialDamageFee;
        this.accidentPercentage = initializer.accidentPercentage;
        this.charge = initializer.charge;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], VehicleAccidentPersonDto.prototype, "birthDate", void 0);
