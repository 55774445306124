import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';
import { setLogOut } from '@/redux/modules/auth.module';
import { store } from '@/redux/reducers/store';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = import.meta.env.RENDERER_VITE_API_BASE_URL;
axiosClient.defaults.withCredentials = true;
axiosClient.defaults.timeout = 7000;

const { dispatch } = store;

let isRefreshing = false;
let failedQueue: {
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
}[] = [];

const processQueue = (error: AxiosError | null) => {
  failedQueue.forEach(async (config) => {
    if (error) {
      config.reject(error);
    } else {
      config.resolve(null);
    }
  });
  failedQueue = [];
};

interface AxiosRequestConfigWithRetry<T> extends AxiosRequestConfig<T> {
  _retry?: boolean;
}

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest =
      error.config as AxiosRequestConfigWithRetry<unknown>;

    const message = error.response?.data?.message;
    const statusCode = error.response?.data?.statusCode;

    if (
      originalRequest.url !== '/auth/login' &&
      originalRequest.url !== '/auth/refresh' &&
      originalRequest.url !== '/auth/vendor' &&
      statusCode === 401 &&
      message === 'Unauthorized' &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return axiosClient(originalRequest);
          })
          .catch((error_2) => {
            return Promise.reject(error_2);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        axiosClient
          .post('/auth/refresh')
          .then(() => {
            processQueue(null);
            resolve(axiosClient(originalRequest));
          })
          .catch((error) => {
            processQueue(error);
            if (error.response?.status === 401) {
              axiosClient.post('/auth/logout');
              dispatch(setLogOut());
            }
            reject(error);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    console.error('Axios Error:', JSON.stringify(error));
    throw error;
  },
);

export default axiosClient;
