var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { InsuranceCalculationMethod } from '../../../enums/insurance/insurance-calculation-method.enum';
import { Daily357InsuranceFeeDto, Daily7InsuranceFeeDto, DailyExcessInsuranceFeeDto, Hourly24InsuranceFeeDto, HourlyInsuranceFeeDto, } from './fee';
import { InsuranceDetailDto } from './insurance-detail.dto';
export const determineInsuranceFeeType = (options) => {
    var _a;
    const calculationMethod = (_a = options === null || options === void 0 ? void 0 : options.object) === null || _a === void 0 ? void 0 : _a.calculationMethod;
    switch (calculationMethod) {
        case InsuranceCalculationMethod.DAILY_7:
            return Daily7InsuranceFeeDto;
        case InsuranceCalculationMethod.DAILY_357:
            return Daily357InsuranceFeeDto;
        case InsuranceCalculationMethod.DAILY_EXCESS:
            return DailyExcessInsuranceFeeDto;
        case InsuranceCalculationMethod.HOUR_0:
        case InsuranceCalculationMethod.HOUR_1:
        case InsuranceCalculationMethod.HOUR_2:
        case InsuranceCalculationMethod.HOUR_3:
        case InsuranceCalculationMethod.HOUR_4:
        case InsuranceCalculationMethod.HOUR_5:
        case InsuranceCalculationMethod.N_HOUR_1:
        case InsuranceCalculationMethod.N_HOUR_2:
        case InsuranceCalculationMethod.N_HOUR_3:
            return HourlyInsuranceFeeDto;
        case InsuranceCalculationMethod.HOUR_24:
            return Hourly24InsuranceFeeDto;
        default:
            throw new Error('Invalid calculation method');
    }
};
export class InsuranceDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.name = initializer.name;
        this.detailA = initializer.detailA;
        this.detailB = initializer.detailB;
        this.detailC = initializer.detailC;
        this.detailD = initializer.detailD;
        this.detailE = initializer.detailE;
        this.feeA = initializer.feeA;
        this.feeB = initializer.feeB;
        this.feeC = initializer.feeC;
        this.feeD = initializer.feeD;
        this.feeE = initializer.feeE;
        this.calculationMethod = initializer.calculationMethod;
        this.vehicleModelIds = initializer.vehicleModelIds;
    }
}
__decorate([
    Type(() => InsuranceDetailDto),
    __metadata("design:type", InsuranceDetailDto)
], InsuranceDto.prototype, "detailA", void 0);
__decorate([
    Type(() => InsuranceDetailDto),
    __metadata("design:type", InsuranceDetailDto)
], InsuranceDto.prototype, "detailB", void 0);
__decorate([
    Type(() => InsuranceDetailDto),
    __metadata("design:type", InsuranceDetailDto)
], InsuranceDto.prototype, "detailC", void 0);
__decorate([
    Type(() => InsuranceDetailDto),
    __metadata("design:type", InsuranceDetailDto)
], InsuranceDto.prototype, "detailD", void 0);
__decorate([
    Type(() => InsuranceDetailDto),
    __metadata("design:type", InsuranceDetailDto)
], InsuranceDto.prototype, "detailE", void 0);
__decorate([
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], InsuranceDto.prototype, "feeA", void 0);
__decorate([
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], InsuranceDto.prototype, "feeB", void 0);
__decorate([
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], InsuranceDto.prototype, "feeC", void 0);
__decorate([
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], InsuranceDto.prototype, "feeD", void 0);
__decorate([
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], InsuranceDto.prototype, "feeE", void 0);
