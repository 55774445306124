import { type AxiosException, type LoginResponseDto } from '@orcar/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { login, logout, refreshLogin } from '@/apis/auth.api';
import { type CompanyLoginForm } from '@/interfaces/company-login-form';
import { setLoginInformation, setLogOut } from '@/redux/modules/auth.module';
import { type RootState } from '@/redux/reducers';

export const useLogin = () => {
  const queryClient = useQueryClient();

  return useMutation<
    LoginResponseDto,
    AxiosError<AxiosException>,
    CompanyLoginForm
  >({
    mutationFn: login,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
  });
};

export const useRefreshLogin = () => {
  const dispatch = useDispatch();
  const { mutate: logout } = useLogout();

  return useMutation<LoginResponseDto, AxiosError<AxiosException>>({
    mutationFn: refreshLogin,
    onSuccess: (data) => {
      dispatch(setLoginInformation(data));
    },
    onError: (error) => {
      if (error.response?.status === 401) {
        logout();
      }
    },
  });
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError<AxiosException>>({
    mutationFn: logout,
    onSettled: () => {
      dispatch(setLogOut());
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
  });
};

export const useLoginInformationState = () => {
  const loginInformationState = useSelector<
    RootState,
    RootState['loginInformationSlice']
  >((state) => state.loginInformationSlice, shallowEqual);

  return loginInformationState;
};
