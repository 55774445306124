import { Button, type Theme } from '@mui/material';
import { type FC, type ReactNode, useState } from 'react';

type Props = {
  onClick: () => void;
  disabled?: boolean;
  children: ReactNode;
};

const NumberPadButton: FC<Props> = ({
  onClick,
  children,
  disabled = false,
}) => {
  const [isTouched, setIsTouched] = useState(false);

  const numberPadTheme = (theme: Theme) => ({
    background: '#ffffff',
    fontSize: 64,
    fontWeight: 'regular',
    color: theme.palette.text.secondary,
    ':disabled': {
      opacity: 1,
      background: '#ffffff',
    },
    ':focus': {
      background: '#ffffff',
    },
    ...(isTouched && {
      bgcolor: '#D4D4D4',
    }),
  });

  return (
    <Button
      sx={numberPadTheme}
      disableRipple={true}
      disabled={disabled}
      onClick={onClick}
      onTouchStart={() => setIsTouched(true)}
      onTouchEnd={() => setIsTouched(false)}
    >
      {children}
    </Button>
  );
};

export default NumberPadButton;
