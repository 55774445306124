import { BaseNumberIdDataDto } from '../../../classes/common';
export class ReservationHistoryDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.userId = initializer.userId;
        this.username = initializer.username;
        this.source = initializer.source;
        this.taskSubtype = initializer.taskSubtype;
        this.prevData = initializer.prevData;
        this.nextData = initializer.nextData;
        this.isPaymentChanged = initializer.isPaymentChanged;
        this.isDateChanged = initializer.isDateChanged;
        this.isFeeChanged = initializer.isFeeChanged;
    }
}
