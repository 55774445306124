export class D2CPaymentDto {
    constructor(initializer) {
        this.id = initializer.id;
        this.paymentKey = initializer.paymentKey;
        this.amount = initializer.amount;
        this.isCanceled = initializer.isCanceled;
        this.canceledAt = initializer.canceledAt;
        this.cancelAmount = initializer.cancelAmount;
        // custom
        this.method = initializer.method;
        this.easyPayProvider = initializer.easyPayProvider;
        this.approvedAt = initializer.approvedAt;
    }
}
