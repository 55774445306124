var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsArray, IsNumber, IsOptional, ValidateNested } from 'class-validator';
class UpdateVehicleImageDto {
}
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], UpdateVehicleImageDto.prototype, "id", void 0);
__decorate([
    IsOptional(),
    __metadata("design:type", Blob)
], UpdateVehicleImageDto.prototype, "image", void 0);
export class UpdateReservationVehicleImageDto {
}
__decorate([
    ValidateNested(),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateReservationVehicleImageDto.prototype, "scratchLeftImage", void 0);
__decorate([
    ValidateNested(),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateReservationVehicleImageDto.prototype, "scratchRightImage", void 0);
__decorate([
    ValidateNested(),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateReservationVehicleImageDto.prototype, "scratchFrontImage", void 0);
__decorate([
    ValidateNested(),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateReservationVehicleImageDto.prototype, "scratchBackImage", void 0);
__decorate([
    ValidateNested(),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateReservationVehicleImageDto.prototype, "fuelImage", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    IsOptional(),
    __metadata("design:type", Array)
], UpdateReservationVehicleImageDto.prototype, "generalImages", void 0);
