var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
export class VendorForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.name = initialize.name;
    }
}
export class VehicleModelForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.name = initialize.name;
    }
}
export class GarageForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.name = initialize.name;
    }
}
export class VehicleForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.vehicleNumber = initialize.vehicleNumber;
    }
}
export class DispatchAreaForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.name = initialize.name;
    }
}
export class UserForComparisonDto {
    constructor(initialize) {
        this.id = initialize.id;
        this.name = initialize.name;
    }
}
export class ReservationPersonForComparisonDto {
    constructor(initializer) {
        this.name = initializer.name;
        this.birthDate = initializer.birthDate;
        this.telNumber = initializer.telNumber;
        this.phoneNumber = initializer.phoneNumber;
        this.fax = initializer.fax;
        this.email = initializer.email;
        this.driverLicenseType = initializer.driverLicenseType;
        this.driverLicenseNumber = initializer.driverLicenseNumber;
        this.driverLicenseValidDate = initializer.driverLicenseValidDate;
        this.driverLicenseVerified = initializer.driverLicenseVerified;
        this.driverLicenseImageUrl = initializer.driverLicenseImageUrl;
        this.signatureUrl = initializer.signatureUrl;
        this.postNumber = initializer.postNumber;
        this.address = initializer.address;
        this.nationality = initializer.nationality;
        this.passportNumber = initializer.passportNumber;
        this.memo = initializer.memo;
        this.sureUserId = initializer.sureUserId;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationPersonForComparisonDto.prototype, "birthDate", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationPersonForComparisonDto.prototype, "driverLicenseValidDate", void 0);
