export var InsuranceCalculationMethod;
(function (InsuranceCalculationMethod) {
    InsuranceCalculationMethod["DAILY_7"] = "DAILY_7";
    InsuranceCalculationMethod["DAILY_357"] = "DAILY_357";
    InsuranceCalculationMethod["DAILY_EXCESS"] = "DAILY_EXCESS";
    InsuranceCalculationMethod["HOUR_0"] = "HOUR_0";
    InsuranceCalculationMethod["HOUR_1"] = "HOUR_1";
    InsuranceCalculationMethod["HOUR_2"] = "HOUR_2";
    InsuranceCalculationMethod["HOUR_3"] = "HOUR_3";
    InsuranceCalculationMethod["HOUR_4"] = "HOUR_4";
    InsuranceCalculationMethod["HOUR_5"] = "HOUR_5";
    InsuranceCalculationMethod["HOUR_24"] = "HOUR_24";
    InsuranceCalculationMethod["N_HOUR_1"] = "N_HOUR_1";
    InsuranceCalculationMethod["N_HOUR_2"] = "N_HOUR_2";
    InsuranceCalculationMethod["N_HOUR_3"] = "N_HOUR_3";
})(InsuranceCalculationMethod || (InsuranceCalculationMethod = {}));
