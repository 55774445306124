export var VehicleModelFeeCalculationMethod;
(function (VehicleModelFeeCalculationMethod) {
    VehicleModelFeeCalculationMethod["HOUR_0"] = "HOUR_0";
    VehicleModelFeeCalculationMethod["HOUR_1"] = "HOUR_1";
    VehicleModelFeeCalculationMethod["HOUR_2"] = "HOUR_2";
    VehicleModelFeeCalculationMethod["HOUR_3"] = "HOUR_3";
    VehicleModelFeeCalculationMethod["HOUR_4"] = "HOUR_4";
    VehicleModelFeeCalculationMethod["HOUR_5"] = "HOUR_5";
    VehicleModelFeeCalculationMethod["N_HOUR_1_0"] = "N_HOUR_1_0";
    VehicleModelFeeCalculationMethod["N_HOUR_2_0"] = "N_HOUR_2_0";
    VehicleModelFeeCalculationMethod["N_HOUR_2_1"] = "N_HOUR_2_1";
    VehicleModelFeeCalculationMethod["N_HOUR_3_0"] = "N_HOUR_3_0";
    VehicleModelFeeCalculationMethod["N_HOUR_3_1"] = "N_HOUR_3_1";
    VehicleModelFeeCalculationMethod["N_HOUR_4_0"] = "N_HOUR_4_0";
    VehicleModelFeeCalculationMethod["N_HOUR_4_1"] = "N_HOUR_4_1";
    VehicleModelFeeCalculationMethod["SIMPLE"] = "SIMPLE";
})(VehicleModelFeeCalculationMethod || (VehicleModelFeeCalculationMethod = {}));
export const FEE_CALCULATION_XY_VALUES = {
    [VehicleModelFeeCalculationMethod.HOUR_0]: [0, 0],
    [VehicleModelFeeCalculationMethod.HOUR_1]: [1, 1],
    [VehicleModelFeeCalculationMethod.HOUR_2]: [2, 2],
    [VehicleModelFeeCalculationMethod.HOUR_3]: [3, 3],
    [VehicleModelFeeCalculationMethod.HOUR_4]: [4, 4],
    [VehicleModelFeeCalculationMethod.HOUR_5]: [5, 5],
    [VehicleModelFeeCalculationMethod.N_HOUR_1_0]: [1, 0],
    [VehicleModelFeeCalculationMethod.N_HOUR_2_0]: [2, 0],
    [VehicleModelFeeCalculationMethod.N_HOUR_2_1]: [2, 1],
    [VehicleModelFeeCalculationMethod.N_HOUR_3_0]: [3, 0],
    [VehicleModelFeeCalculationMethod.N_HOUR_3_1]: [3, 1],
    [VehicleModelFeeCalculationMethod.N_HOUR_4_0]: [4, 0],
    [VehicleModelFeeCalculationMethod.N_HOUR_4_1]: [4, 1],
    [VehicleModelFeeCalculationMethod.SIMPLE]: [-1, -1],
};
