import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

const ShiftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{
        width: 36,
        height: 38,
        ...props.sx,
      }}
      viewBox='0 0 36 38'
      fill='none'
      {...props}
    >
      <path
        d='M10 38V22.5H0L18 0L36 22.5H26V38H10ZM13 35H23V19.5H29.65L18 4.7L6.35 19.5H13V35Z'
        fill='#7E7E7E'
      />
    </SvgIcon>
  );
};
export default ShiftIcon;
