var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common';
import { UserWithoutRelationsDto } from '../../../classes/user';
import { BlackListCommentDto } from './black-list-comment.dto';
export class BlackListDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.type = initializer.type;
        this.isPublic = initializer.isPublic;
        this.isKioskRentRestricted = initializer.isKioskRentRestricted;
        this.birthDate = initializer.birthDate;
        this.driverLicenseNumber = initializer.driverLicenseNumber;
        this.name = initializer.name;
        this.phoneNumber1 = initializer.phoneNumber1;
        this.phoneNumber2 = initializer.phoneNumber2;
        this.address = initializer.address;
        this.user = initializer.user;
        this.userId = initializer.userId;
        this.content = initializer.content;
        this.comments = initializer.comments;
        this.companyName = initializer.companyName;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], BlackListDto.prototype, "birthDate", void 0);
__decorate([
    Type(() => UserWithoutRelationsDto),
    __metadata("design:type", UserWithoutRelationsDto)
], BlackListDto.prototype, "user", void 0);
__decorate([
    Type(() => BlackListCommentDto),
    __metadata("design:type", Array)
], BlackListDto.prototype, "comments", void 0);
