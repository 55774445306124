import { Box, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoginButton from '@/components/LoginButton';
import TextInput from '@/components/TextInput';
import TitleMessage from '@/components/TitleMessage';
import { useLogin } from '@/hooks/auth.hook';
import { type CompanyLoginForm } from '@/interfaces/company-login-form';
import { setLoginInformation } from '@/redux/modules/auth.module';

const LoginPage = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { mutateAsync: login } = useLogin();

  const [error, setError] = useState('');

  const { control, handleSubmit, reset } = useForm<CompanyLoginForm>();

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  }, [error]);

  useEffect(() => {
    const companyId = localStorage.getItem('companyId');
    const companyPassword = localStorage.getItem('companyPassword');
    const username = localStorage.getItem('username');

    reset({
      companyId: companyId || '',
      companyPassword: companyPassword || '',
      username: username || '',
    });
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await login(data);
      dispatch(setLoginInformation(response));

      localStorage.setItem('companyId', data.companyId);
      localStorage.setItem('companyPassword', data.companyPassword);
      localStorage.setItem('username', data.username);

      if (response.login) {
        navigate('/');
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const {
          data: { statusCode, message },
        } = error.response;

        switch (statusCode) {
          case 404:
            switch (message) {
              case 'COMPANY_NOT_FOUND':
                setError('존재하지 않는 회사 ID입니다.');
                break;
              case 'USER_NOT_FOUND':
                setError('존재하지 않는 사용자 ID입니다.');
                break;
              default:
                setError('로그인에 실패했습니다.');
                break;
            }
            break;
          case 401:
            switch (message) {
              case 'WRONG_COMPANY_PASSWORD':
                setError('회사 비밀번호가 올바르지 않습니다.');
                break;
              case 'WRONG_USER_PASSWORD':
                setError('사용자 비밀번호가 올바르지 않습니다.');
                break;
              default:
                setError('로그인에 실패했습니다.');
                break;
            }
            break;
          default:
            setError('로그인에 실패했습니다.');
            break;
        }
      }

      setError('로그인에 실패했습니다.');
      return;
    }
  });

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      onSubmit={onSubmit}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          paddingTop: '100px',
          paddingX: '80px',
        }}
      >
        <TitleMessage message={['로그인']}></TitleMessage>
        <Box sx={{ height: '56px' }}></Box>

        <Typography fontSize={32} sx={{ marginBottom: '20px' }}>
          회사 ID
        </Typography>
        <Controller
          name='companyId'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={field.onChange}
              textFieldSx={{
                width: '100%',
                height: '120px',
              }}
              inputSx={{
                fontSize: 40,
              }}
              type='password'
            />
          )}
        />
        <Box sx={{ height: '40px' }}></Box>
        <Typography fontSize={32} sx={{ marginBottom: '20px' }}>
          회사 비밀번호
        </Typography>
        <Controller
          name='companyPassword'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={field.onChange}
              textFieldSx={{
                width: '100%',
                height: '120px',
              }}
              inputSx={{
                fontSize: 40,
              }}
              type='password'
            />
          )}
        />
        <Box sx={{ height: '40px' }}></Box>
        <Typography fontSize={32} sx={{ marginBottom: '20px' }}>
          사용자 ID
        </Typography>
        <Controller
          name='username'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={field.onChange}
              textFieldSx={{
                width: '100%',
                height: '120px',
              }}
              inputSx={{
                fontSize: 40,
              }}
            />
          )}
        />
        <Box sx={{ height: '40px' }}></Box>
        <Typography fontSize={32} sx={{ marginBottom: '20px' }}>
          사용자 비밀번호
        </Typography>
        <Controller
          name='password'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={field.onChange}
              textFieldSx={{
                width: '100%',
                height: '120px',
              }}
              inputSx={{
                fontSize: 40,
              }}
              type='password'
            />
          )}
        />
      </Box>
      <LoginButton label='로그인' errorMessage={error} />
    </form>
  );
};

export default LoginPage;
