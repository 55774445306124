var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsArray, IsBoolean, IsEnum, IsNumber, IsOptional, IsString, ValidateNested, } from 'class-validator';
import { CreateChecklistItemDto } from '../../../classes/checklist-item';
import { CalculationMethod } from '../../../enums/common/calculation-method.enum';
import { FuelRateUnit } from '../../../enums/reservation/fuel-rate-unit.enum';
export class UpdateCompanySettingDto {
}
__decorate([
    IsArray(),
    IsString({ each: true }),
    IsOptional(),
    __metadata("design:type", Array)
], UpdateCompanySettingDto.prototype, "feeCategory", void 0);
__decorate([
    IsEnum(FuelRateUnit),
    IsOptional(),
    __metadata("design:type", String)
], UpdateCompanySettingDto.prototype, "fuelRateUnit", void 0);
__decorate([
    IsEnum(CalculationMethod),
    IsOptional(),
    __metadata("design:type", String)
], UpdateCompanySettingDto.prototype, "calculationMethod", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateCompanySettingDto.prototype, "defaultPickUpGarageId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateCompanySettingDto.prototype, "defaultDropOffGarageId", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], UpdateCompanySettingDto.prototype, "limitNumberOfReservation", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], UpdateCompanySettingDto.prototype, "licenseCertification", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateCompanySettingDto.prototype, "defaultVendorId", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], UpdateCompanySettingDto.prototype, "landingPage", void 0);
__decorate([
    IsArray(),
    ValidateNested({ each: true }),
    Type(() => CreateChecklistItemDto),
    IsOptional(),
    __metadata("design:type", Array)
], UpdateCompanySettingDto.prototype, "checklistItems", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], UpdateCompanySettingDto.prototype, "setUpTime", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], UpdateCompanySettingDto.prototype, "swapVehicle", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], UpdateCompanySettingDto.prototype, "swapBlock", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], UpdateCompanySettingDto.prototype, "kioskEarlyPickUpTimeLimit", void 0);
