import { Box, Table, TableBody, TableRow } from '@mui/material';
import { formatPhoneNumber, type Nullable } from '@orcar/common';
import { useEffect, useState } from 'react';
import BackspaceIcon from '@/components/BackspaceIcon';
import Key from './Key';
import KeypadInput from './KeypadInput';

type PhoneNumberKeypadProps = {
  defaultValue?: Nullable<string>;
  onConfirm: (value: string) => void;
};

const PhoneNumberKeypad = ({
  defaultValue,
  onConfirm,
}: PhoneNumberKeypadProps) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(defaultValue ?? '');
  }, [defaultValue]);

  const handleClick = (value: string) => {
    if (value === 'backspace') {
      setInputValue((prev) => prev.slice(0, prev.length - 1));
    } else {
      setInputValue((prev) => prev + value);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        bgcolor: 'white',
        height: 599,
        flexDirection: 'column',
      }}
    >
      <Table sx={{ width: '100%', flexGrow: 1 }}>
        <TableBody>
          <TableRow>
            <KeypadInput
              colSpan={3}
              value={formatPhoneNumber(inputValue)}
              onDelete={() => {
                setInputValue('');
              }}
            />
          </TableRow>
          <TableRow>
            {['1', '2', '3'].map((value, _) => (
              <Key
                key={_}
                sx={{ width: 100, height: 100 }}
                onClick={() => handleClick(value)}
              >
                {value}
              </Key>
            ))}
          </TableRow>
          <TableRow>
            {['4', '5', '6'].map((value, _) => (
              <Key
                key={_}
                sx={{ height: 100 }}
                onClick={() => handleClick(value)}
              >
                {value}
              </Key>
            ))}
          </TableRow>
          <TableRow>
            {['7', '8', '9'].map((value, _) => (
              <Key
                key={_}
                sx={{ height: 100 }}
                onClick={() => handleClick(value)}
              >
                {value}
              </Key>
            ))}
          </TableRow>
          <TableRow>
            {['010', '0'].map((value, _) => (
              <Key
                key={_}
                sx={{ height: 100 }}
                onClick={() => handleClick(value)}
              >
                {value}
              </Key>
            ))}
            <Key sx={{ height: 100 }} onClick={() => handleClick('backspace')}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <BackspaceIcon />
              </Box>
            </Key>
          </TableRow>
          <TableRow>
            <Key
              sx={{
                width: 100,
                height: 100,
                bgcolor: 'primary.main',
                color: 'white',
                fontWeight: 'medium',
              }}
              colSpan={3}
              onClick={() => onConfirm(inputValue)}
            >
              확인
            </Key>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default PhoneNumberKeypad;
