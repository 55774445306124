var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsArray, IsBoolean, IsEnum, IsNumber, IsOptional, IsString, ValidateNested, } from 'class-validator';
import { CreateFeeWeekendPolicyDto } from '../../../../classes/fee';
import { DrivingSystem, Fuel, Manufacturer, Transmission, VehicleModelFeeCalculationMethod, VehicleModelState, VehicleModelType, } from '../../../../enums';
import { CreateVehicleModelAudioDto } from './create-vehicle-model-audio.dto';
import { CreateVehicleModelFeeDto } from './create-vehicle-model-fee.dto';
import { CreateVehicleModelInsuranceDto } from './create-vehicle-model-insurance.dto';
import { CreateVehicleModelKeyOptionDto } from './create-vehicle-model-key-option.dto';
import { CreateVehicleModelOptionDto } from './create-vehicle-model-option.dto';
export class CreateVehicleModelDto {
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateVehicleModelDto.prototype, "name", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateVehicleModelDto.prototype, "graphOrder", void 0);
__decorate([
    IsEnum(VehicleModelType),
    __metadata("design:type", String)
], CreateVehicleModelDto.prototype, "type", void 0);
__decorate([
    IsEnum(VehicleModelState),
    __metadata("design:type", String)
], CreateVehicleModelDto.prototype, "state", void 0);
__decorate([
    IsEnum(Manufacturer),
    __metadata("design:type", String)
], CreateVehicleModelDto.prototype, "manufacturer", void 0);
__decorate([
    IsEnum(Fuel),
    __metadata("design:type", String)
], CreateVehicleModelDto.prototype, "fuel", void 0);
__decorate([
    IsEnum(Transmission),
    __metadata("design:type", String)
], CreateVehicleModelDto.prototype, "transmission", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateVehicleModelDto.prototype, "seat", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateVehicleModelDto.prototype, "capacity", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateVehicleModelDto.prototype, "mileage", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateVehicleModelAudioDto),
    __metadata("design:type", CreateVehicleModelAudioDto)
], CreateVehicleModelDto.prototype, "audio", void 0);
__decorate([
    IsEnum(DrivingSystem),
    __metadata("design:type", String)
], CreateVehicleModelDto.prototype, "drivingSystem", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateVehicleModelKeyOptionDto),
    __metadata("design:type", CreateVehicleModelKeyOptionDto)
], CreateVehicleModelDto.prototype, "keyOption", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateVehicleModelOptionDto),
    __metadata("design:type", CreateVehicleModelOptionDto)
], CreateVehicleModelDto.prototype, "modelOption", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateVehicleModelDto.prototype, "driverAge", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateVehicleModelDto.prototype, "driverExperience", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateVehicleModelDto.prototype, "memo", void 0);
__decorate([
    IsEnum(VehicleModelFeeCalculationMethod),
    __metadata("design:type", String)
], CreateVehicleModelDto.prototype, "calculationMethod", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateFeeWeekendPolicyDto),
    __metadata("design:type", CreateFeeWeekendPolicyDto)
], CreateVehicleModelDto.prototype, "weekend", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateVehicleModelFeeDto),
    __metadata("design:type", CreateVehicleModelFeeDto)
], CreateVehicleModelDto.prototype, "fee", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateVehicleModelInsuranceDto),
    IsOptional(),
    __metadata("design:type", CreateVehicleModelInsuranceDto)
], CreateVehicleModelDto.prototype, "insurance1", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateVehicleModelInsuranceDto),
    IsOptional(),
    __metadata("design:type", CreateVehicleModelInsuranceDto)
], CreateVehicleModelDto.prototype, "insurance2", void 0);
__decorate([
    IsArray(),
    IsNumber({}, { each: true }),
    __metadata("design:type", Array)
], CreateVehicleModelDto.prototype, "insuranceIds", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateVehicleModelDto.prototype, "title", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], CreateVehicleModelDto.prototype, "isActive", void 0);
