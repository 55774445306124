import { Box, Button, Dialog, Typography } from '@mui/material';

interface NoResultDialogProps {
  open: boolean;
  onConfirm: () => void;
}

const NoResultDialog = ({ open, onConfirm }: NoResultDialogProps) => {
  return (
    <Dialog open={open}>
      <Box sx={{ width: '100%' }}>
        <Typography
          fontSize={32}
          fontWeight='regular'
          color='text.secondary'
          lineHeight='44px'
          sx={{ marginBottom: 5 }}
        >
          해당 번호로 검색된 예약이 없습니다. <br />
          예약하실 때 입력한 전화번호를 확인해주세요.
        </Typography>
        <Button
          variant='contained'
          fullWidth
          sx={{ height: 100, fontSize: 40, fontWeight: 500 }}
          onClick={onConfirm}
        >
          확인
        </Button>
      </Box>
    </Dialog>
  );
};

export default NoResultDialog;
