import { type AxiosException, type CompanyProfileDto } from '@orcar/common';
import { useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { getCompanyProfile } from '@/apis/company-profile.api';

export const useGetProfile = () => {
  return useQuery<CompanyProfileDto, AxiosError<AxiosException>>({
    queryKey: ['profile'],
    queryFn: getCompanyProfile,
  });
};
