import { Box, LinearProgress } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmDialog from './ConfirmDialog';

const BYPASS_PATHS = ['/login', '/'];
const INACTIVITY_DURATION_IN_VEHICLE_READY = 45;
const DEFAULT_INACTIVITY_DURATION = 120;
const COUNTDOWN_DURATION = 10;

const InactivityDialog = () => {
  const [isActive, setIsActive] = useState(true);
  const [progress, setProgress] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const inactivityTimer = useRef(0);

  const inactivityDuration =
    location.pathname === '/vehicle-ready'
      ? INACTIVITY_DURATION_IN_VEHICLE_READY
      : DEFAULT_INACTIVITY_DURATION;

  const restartTimer = useCallback(() => {
    clearTimeout(inactivityTimer.current);
    inactivityTimer.current = window.setTimeout(() => {
      setIsActive(false);
    }, inactivityDuration * 1000);
  }, [inactivityDuration]);

  const goHome = useCallback(() => {
    setIsActive(true);
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    if (BYPASS_PATHS.includes(location.pathname)) {
      return;
    }

    restartTimer();

    window.addEventListener('mousemove', restartTimer);
    window.addEventListener('mousedown', restartTimer);
    window.addEventListener('keypress', restartTimer);
    window.addEventListener('scroll', restartTimer);
    window.addEventListener('touchstart', restartTimer);

    return () => {
      clearTimeout(inactivityTimer.current);

      window.removeEventListener('mousemove', restartTimer);
      window.removeEventListener('mousedown', restartTimer);
      window.removeEventListener('keypress', restartTimer);
      window.removeEventListener('scroll', restartTimer);
      window.removeEventListener('touchstart', restartTimer);
    };
  }, [restartTimer, location.pathname]);

  useEffect(() => {
    setProgress(0);

    if (!isActive) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 10 / COUNTDOWN_DURATION,
        );
      }, 100);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isActive]);

  useEffect(() => {
    if (progress >= 100) {
      goHome();
    }
  }, [progress, goHome]);

  return (
    <ConfirmDialog
      open={!isActive}
      title={['장시간 동작이 없어', '홈 화면으로 이동합니다']}
      confirmButtonLabel='연장하기'
      onConfirm={() => {
        setIsActive(true);
      }}
      closeButtonLabel='홈으로'
      onClose={goHome}
    >
      <Box paddingY={6}>
        <LinearProgress
          variant='determinate'
          value={progress}
          sx={{
            height: 6,
          }}
        />
      </Box>
    </ConfirmDialog>
  );
};

export default InactivityDialog;
