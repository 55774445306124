import dayjs from 'dayjs';
export const isValidDate = (date) => {
    if (!date)
        return false;
    return date instanceof Date && !isNaN(date.getTime());
};
export const toDate = (input) => {
    const date = dayjs(input);
    if (!date.isValid()) {
        throw new Error('Invalid date');
    }
    return date.toDate();
};
export const isConvertibleToValidDate = (date) => {
    return isValidDate(toDate(date));
};
