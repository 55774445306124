export var MessagingRecordStatus;
(function (MessagingRecordStatus) {
    MessagingRecordStatus["REQUEST_RECEIVED"] = "REQUEST_RECEIVED";
    MessagingRecordStatus["SENDING"] = "SENDING";
    MessagingRecordStatus["SENT_KAKAO_TALK"] = "SENT_KAKAO_TALK";
    MessagingRecordStatus["SENT_TEXT_MESSAGE"] = "SENT_TEXT_MESSAGE";
    MessagingRecordStatus["FAILED"] = "FAILED";
    MessagingRecordStatus["FAILED_INVALID_RECEIVER"] = "FAILED_INVALID_RECEIVER";
    MessagingRecordStatus["CANCELED"] = "CANCELED";
})(MessagingRecordStatus || (MessagingRecordStatus = {}));
