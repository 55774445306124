var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsArray, IsEnum, IsNumber, IsOptional, IsString, ValidateNested, } from 'class-validator';
import { InsuranceCalculationMethod } from '../../../enums/insurance/insurance-calculation-method.enum';
import { UpdateDaily357InsuranceFeeDto, UpdateDaily7InsuranceFeeDto, UpdateDailyExcessInsuranceFeeDto, UpdateHourly24InsuranceFeeDto, UpdateHourlyInsuranceFeeDto, } from './fee';
import { UpdateInsuranceDetailDto } from './update-insurance-detail.dto';
const determineInsuranceFeeType = (options) => {
    var _a;
    const calculationMethod = (_a = options === null || options === void 0 ? void 0 : options.object) === null || _a === void 0 ? void 0 : _a.calculationMethod;
    switch (calculationMethod) {
        case InsuranceCalculationMethod.DAILY_7:
            return UpdateDaily7InsuranceFeeDto;
        case InsuranceCalculationMethod.DAILY_357:
            return UpdateDaily357InsuranceFeeDto;
        case InsuranceCalculationMethod.DAILY_EXCESS:
            return UpdateDailyExcessInsuranceFeeDto;
        case InsuranceCalculationMethod.HOUR_0:
        case InsuranceCalculationMethod.HOUR_1:
        case InsuranceCalculationMethod.HOUR_2:
        case InsuranceCalculationMethod.HOUR_3:
        case InsuranceCalculationMethod.HOUR_4:
        case InsuranceCalculationMethod.HOUR_5:
        case InsuranceCalculationMethod.N_HOUR_1:
        case InsuranceCalculationMethod.N_HOUR_2:
        case InsuranceCalculationMethod.N_HOUR_3:
            return UpdateHourlyInsuranceFeeDto;
        case InsuranceCalculationMethod.HOUR_24:
            return UpdateHourly24InsuranceFeeDto;
        default:
            throw new Error('Invalid calculation method');
    }
};
export class UpdateInsuranceDto {
}
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], UpdateInsuranceDto.prototype, "name", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateInsuranceDetailDto),
    IsOptional(),
    __metadata("design:type", UpdateInsuranceDetailDto)
], UpdateInsuranceDto.prototype, "detailA", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateInsuranceDetailDto),
    IsOptional(),
    __metadata("design:type", UpdateInsuranceDetailDto)
], UpdateInsuranceDto.prototype, "detailB", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateInsuranceDetailDto),
    IsOptional(),
    __metadata("design:type", UpdateInsuranceDetailDto)
], UpdateInsuranceDto.prototype, "detailC", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateInsuranceDetailDto),
    IsOptional(),
    __metadata("design:type", UpdateInsuranceDetailDto)
], UpdateInsuranceDto.prototype, "detailD", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateInsuranceDetailDto),
    IsOptional(),
    __metadata("design:type", UpdateInsuranceDetailDto)
], UpdateInsuranceDto.prototype, "detailE", void 0);
__decorate([
    ValidateNested(),
    Type(determineInsuranceFeeType),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateInsuranceDto.prototype, "feeA", void 0);
__decorate([
    ValidateNested(),
    Type(determineInsuranceFeeType),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateInsuranceDto.prototype, "feeB", void 0);
__decorate([
    ValidateNested(),
    Type(determineInsuranceFeeType),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateInsuranceDto.prototype, "feeC", void 0);
__decorate([
    ValidateNested(),
    Type(determineInsuranceFeeType),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateInsuranceDto.prototype, "feeD", void 0);
__decorate([
    ValidateNested(),
    Type(determineInsuranceFeeType),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateInsuranceDto.prototype, "feeE", void 0);
__decorate([
    IsEnum(InsuranceCalculationMethod),
    IsOptional(),
    __metadata("design:type", String)
], UpdateInsuranceDto.prototype, "calculationMethod", void 0);
__decorate([
    IsArray(),
    IsNumber({}, { each: true }),
    IsOptional(),
    __metadata("design:type", Array)
], UpdateInsuranceDto.prototype, "vehicleModelIds", void 0);
