var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ImageResize } from 'quill-image-resize-module-ts';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React, { useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import './editor.css';
const Font = Quill.import('formats/font');
const Size = Quill.import('formats/size');
Font.whitelist = [
    '기본서체',
    '돋움',
    '굴림',
    '바탕',
    '궁서',
    'arial',
    'serif',
    'monospace',
];
Size.whitelist = [
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '16',
    '18',
    '20',
    '22',
    '24',
    '26',
    '28',
    '36',
    '48',
    '72',
];
Quill.register(Font, true);
Quill.register(Size, true);
Quill.register('modules/imageResize', ImageResize);
const Editor = ({ content, setContent, style, imageUploadPromise, }) => {
    const quillRef = useRef(null);
    const handleImageUpload = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.addEventListener('change', () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const file = (_a = input.files) === null || _a === void 0 ? void 0 : _a[0];
            if (!file) {
                window.alert('파일을 선택해주세요.');
                return;
            }
            if (file.size > 1024 * 1024 * 5) {
                window.alert('파일 용량이 너무 큽니다. (5MB 이하)');
                return;
            }
            if (!file.type.includes('image')) {
                window.alert('이미지 파일만 업로드 가능합니다.');
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            yield imageUploadPromise(formData);
        }));
    };
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ size: Size.whitelist }],
                    [{ header: [1, 2, 3, false] }],
                    [{ font: Font.whitelist }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],
                    ['link', 'image'],
                ],
                handlers: { image: handleImageUpload },
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize', 'Toolbar'],
            },
        };
    }, []);
    return (React.createElement(ReactQuill, { ref: quillRef, style: Object.assign({ display: 'flex', flexDirection: 'column', minHeight: '600px', overflowY: 'auto' }, style), value: content, onChange: (value) => setContent(value), modules: modules }));
};
export default Editor;
