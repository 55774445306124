import { BaseNumberIdDataDto } from '../../../classes/common';
export class VehicleImageDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.reservationId = initializer.reservationId;
        this.vehicleId = initializer.vehicleId;
        this.url = initializer.url;
        this.type = initializer.type;
    }
}
