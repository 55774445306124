import { Box, Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';
import logger from 'electron-log';
import { type FC, type ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomButtons from '@/components/BottomButtons';
import TitleMessage from '@/components/TitleMessage';
import { usePrintReceipt } from '@/hooks/print-receipt.hook';

const Line: FC = () => {
  return (
    <Typography
      fontSize='32px'
      lineHeight={1.6}
      overflow='hidden'
      whiteSpace='nowrap'
    >
      {'-'.repeat(50)}
    </Typography>
  );
};

const Text: FC<{
  children: ReactNode;
  align?: 'center';
  fontSize?: string;
}> = ({ children, align, fontSize = '32px' }) => {
  return (
    <Typography fontSize={fontSize} align={align} lineHeight={1.6}>
      {children}
    </Typography>
  );
};

const Row: FC<{ left: string; right: string; fontSize?: string }> = ({
  left,
  right,
  fontSize = '32px',
}) => {
  return (
    <Box display='flex' justifyContent='space-between'>
      <Typography fontSize={fontSize} lineHeight={1.6}>
        {left}
      </Typography>
      <Typography fontSize={fontSize} lineHeight={1.6}>
        {right}
      </Typography>
    </Box>
  );
};

export type VehicleReadyData = {
  dispatchArea: string;
  vehicleNumber: string;
  contractorName: string;
  driverName: string | null;
  pickUpAt: string | Date;
  actualDropOffAt: string | Date;
  vehicleModelName: string;
};

const VehicleReadyPage = () => {
  const navigate = useNavigate();

  const { printReceipt } = usePrintReceipt();

  const [clicks, setClicks] = useState<number[]>([]);

  const location = useLocation();
  const { vehicleReadyData, paymentData, managerApprovals } = location.state;

  const handleBoxClick = async () => {
    const now = Date.now();

    const newClicks = clicks.filter((click) => now - click < 2000);
    newClicks.push(now);

    if (newClicks.length >= 3) {
      try {
        await printReceipt(vehicleReadyData, paymentData, managerApprovals);
      } catch (error) {
        logger.error(error instanceof Error ? error.stack : error);
      }
      setClicks([]);
    } else {
      setClicks(newClicks);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          background: '#f5f5f5',
          paddingTop: '100px',
          paddingBottom: '80px',
          paddingX: '80px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TitleMessage message={'출고 준비가 완료되었습니다.'} />
        <Typography
          fontSize={40}
          fontWeight={'regular'}
          color={'text.secondary'}
          lineHeight={1.4}
          sx={{ marginTop: '20px' }}
        >
          {'아래에 출력되는 “출고 대기 확인증”을 가지고'}
          <br />
          {'주차장으로 이동하여 출고 담당자의 안내를 받으세요.'}
        </Typography>
        <Box sx={{ height: '56px' }} />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1px 1fr',
            width: '100%',
            height: '236px',
            background: '#ffffff',
            border: '1px solid #D4D4D4',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography fontSize={40} color={'text.secondary'}>
              배차구역
            </Typography>
            <Box sx={{ height: 20 }} />
            <Typography fontSize={40} color={'primary'} fontWeight={'bold'}>
              {vehicleReadyData.dispatchArea}
            </Typography>
          </Box>
          <Divider orientation='vertical' />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography fontSize={40} color={'text.secondary'}>
              차량번호
            </Typography>
            <Box sx={{ height: 20 }} />
            <Typography fontSize={40} color={'primary'} fontWeight={'bold'}>
              {vehicleReadyData.vehicleNumber}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ height: '72px' }} />
        <Box display='flex' justifyContent='center'>
          <Box width='600px' onClick={handleBoxClick}>
            <Box sx={{ height: '700px', background: 'white', padding: '40px' }}>
              <Line />
              <Text align='center'>출고 대기 확인증</Text>
              <Line />
              {vehicleReadyData.driverName ? (
                <>
                  <Row
                    left='제1 운전자'
                    right={vehicleReadyData.contractorName}
                  />
                  <Row left='제2 운전자' right={vehicleReadyData.driverName} />
                </>
              ) : (
                <Row left='운전자' right={vehicleReadyData.contractorName} />
              )}
              <Row
                left='대여 일시'
                right={dayjs(vehicleReadyData.pickUpAt).format(
                  'YYYY-MM-DD HH:mm',
                )}
              />
              <Row
                left='반납 일시'
                right={dayjs(vehicleReadyData.actualDropOffAt).format(
                  'YYYY-MM-DD HH:mm',
                )}
              />
              <Row left='차량 모델' right={vehicleReadyData.vehicleModelName} />
              <Line />
              <Row
                left='배차 구역'
                right={vehicleReadyData.dispatchArea}
                fontSize='48px'
              />
              <Row
                left='차량 번호'
                right={vehicleReadyData.vehicleNumber}
                fontSize='48px'
              />
            </Box>
            {/* Zigzag bottom border */}
            <Box
              sx={{
                height: '80px',
                backgroundImage:
                  'linear-gradient(135deg, white 50px, transparent 0), linear-gradient(225deg, white 50px, transparent 0)',
                backgroundSize: '100px 100px',
                backgroundRepeat: 'repeat-x',
              }}
            />
          </Box>
        </Box>
      </Box>
      <BottomButtons
        label='종료'
        onClick={() => {
          navigate('/');
        }}
      />
    </Box>
  );
};

export default VehicleReadyPage;
