import {
  type BlackListDto,
  type GetManyBlackListsQueryDto,
  type Paginated,
} from '@orcar/common';
import axiosClient from './axiosClient';

export const getBlackLists = async (
  params?: GetManyBlackListsQueryDto,
): Promise<Paginated<BlackListDto>> => {
  const response = await axiosClient.get('/black-lists/', { params });

  return response.data;
};
