import { io } from 'socket.io-client';

export const socket = io(
  import.meta.env.RENDERER_VITE_WEB_SOCKET_HOST + '/reservations',
  {
    transports: ['websocket'],
    autoConnect: false,
    withCredentials: true,
  },
);
