import { Box, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { type FC } from 'react';
import SuccessfullyDoneJson from '@/assets/lottie/successfully-done.json';
import { GreyButton } from '@/components/StyledMuiComponent';

type Props = {
  onClosed: () => void;
};

const DriverLicenseValidationManagerApproval: FC<Props> = ({ onClosed }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: '#ffffff',
        padding: '40px',
        paddingTop: '64px',
      }}
    >
      <Typography fontSize={40} fontWeight='bold'>
        {'운전자 자격 확인'}
      </Typography>
      <Box sx={{ height: '16px' }} />
      <Typography
        fontSize={32}
        fontWeight='regular'
        color='text.secondary'
        sx={{ whiteSpace: 'pre-line' }}
      >
        {'담당 직원에게 운전면허증을 확인시켜주세요.'}
      </Typography>
      <Box sx={{ height: '40px' }} />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Lottie
          animationData={SuccessfullyDoneJson}
          loop={true}
          style={{ height: 240 }}
        />
      </Box>
      <Box sx={{ height: '40px' }} />
      <GreyButton
        sx={{
          width: '100%',
          fontSize: 40,
        }}
        onClick={() => {
          onClosed();
        }}
      >
        <Typography fontSize={40} fontWeight='medium'>
          취소
        </Typography>
      </GreyButton>
    </Box>
  );
};

export default DriverLicenseValidationManagerApproval;
