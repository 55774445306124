import { Button, Dialog, Typography } from '@mui/material';
import { formatPhoneNumber, type ReservationDto } from '@orcar/common';

const VehicleUnreadyDialog = ({
  open,
  reservation,
  onConfirm,
}: {
  open: boolean;
  reservation: ReservationDto;
  onConfirm: () => void;
}) => {
  return (
    <Dialog open={open}>
      <Typography fontSize={40} fontWeight='bold' sx={{ marginBottom: 2 }}>
        데스크 문의
      </Typography>
      <Typography
        fontSize={32}
        fontWeight={400}
        color='text.secondary'
        lineHeight='44px'
        sx={{ marginBottom: 5 }}
      >
        {reservation.customerName1}(
        {reservation.customerPhoneNumber1 &&
          formatPhoneNumber(reservation.customerPhoneNumber1)}
        )님,
        <br />
        예약하신 차량이 아직 준비 중입니다.
      </Typography>
      <Button
        variant='contained'
        color='primary'
        fullWidth
        sx={{
          height: 100,
          fontSize: 40,
          fontWeight: 500,
        }}
        onClick={onConfirm}
      >
        확인
      </Button>
    </Dialog>
  );
};

export default VehicleUnreadyDialog;
