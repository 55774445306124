import { Box, Typography } from '@mui/material';
import { type UseFormReturn } from 'react-hook-form';
import { ChipButton } from '@/components/StyledMuiComponent';
import { type KeypadType } from './KeypadWrapper';
import { type DriverLicenseForm, type KeypadState } from '../DriverInfoPage';

export type DriverInputType =
  | 'name'
  | 'phoneNumber'
  | 'birthDate'
  | 'driverLicenseNumber'
  | 'driverLicenseType';

const DriverInput: React.FC<{
  type: DriverInputType;
  value: string;
  driverLicenseForm: UseFormReturn<DriverLicenseForm, unknown, undefined>;
  canEdit?: boolean;
  setKeypadState: React.Dispatch<React.SetStateAction<KeypadState>>;
}> = ({ type, value, driverLicenseForm, canEdit = true, setKeypadState }) => {
  const {
    clearErrors,
    formState: { errors },
  } = driverLicenseForm;
  const label =
    type === 'name'
      ? '이름'
      : type === 'phoneNumber'
      ? '연락처'
      : type === 'birthDate'
      ? '생년월일'
      : type === 'driverLicenseNumber'
      ? '면허번호'
      : '면허종류';
  const hasError = errors[type] !== undefined;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        paddingY: '20px',
      }}
    >
      <Typography
        sx={{
          width: 112,
          marginRight: '32px',
          fontSize: 32,
          color: 'text.secondary',
        }}
      >
        {label}
      </Typography>

      <Typography
        sx={{
          flexGrow: 1,
          border: hasError ? '3px solid #EF5350' : undefined,
          padding: '12px',
          height: 68,
          fontSize: 32,
          color: 'text.primary',
        }}
        onClick={() => {
          if (canEdit) {
            setKeypadState({
              open: true,
              type:
                type === 'driverLicenseType' || type === 'driverLicenseNumber'
                  ? 'licenseInfo'
                  : (type as KeypadType),
              editSingleType: true,
            });
            clearErrors(type);
          }
        }}
      >
        {value}
      </Typography>
      <Box sx={{ width: 16 }} />
      {canEdit && (
        <ChipButton
          sx={{
            marginY: -1,
            backgroundColor: hasError ? '#EF5350' : '#fafafa',
            color: hasError ? '#fff' : 'text.secondary',
          }}
          buttonSize='large'
          iconColor={hasError ? '#fff' : '#7E7E7E'}
          onClick={() => {
            setKeypadState({
              open: true,
              type:
                type === 'driverLicenseType' || type === 'driverLicenseNumber'
                  ? 'licenseInfo'
                  : (type as KeypadType),
              editSingleType: true,
            });
            clearErrors(type);
          }}
        >
          변경
        </ChipButton>
      )}
      {hasError && (
        <Typography
          sx={{
            position: 'absolute',
            left: 150,
            bottom: -10,
            fontSize: 24,
            color: '#EF5350',
          }}
        >
          *{errors[type]?.message}
        </Typography>
      )}
    </Box>
  );
};

export default DriverInput;
