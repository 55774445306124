export class UserAuthorityDto {
    // 마감관리 (ins에는 있음)
    constructor(initializer) {
        this.vendor = initializer.vendor;
        this.product = initializer.product;
        this.history = initializer.history;
        this.accounting = initializer.accounting;
        this.maintenance = initializer.maintenance;
        this.board = initializer.board;
        this.preallocation = initializer.preallocation;
    }
}
