import { Box, Divider, Typography } from '@mui/material';
import { ContractSectionType } from '@orcar/common';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import BottomButtons from '@/components/BottomButtons';
import CheckBox from '@/components/CheckBox';
import TitleMessage from '@/components/TitleMessage';
import Viewer from '@/components/Viewer';
import { useGetSetting } from '@/hooks/company-setting.hook';
import { useGetContractSections } from '@/hooks/contract-section.hook';
import { useGetReservation } from '@/hooks/reservation.hook';
import ReservationSummaryCard from './components/ReservationSummaryCard';
import TooEarlyDialog from './components/TooEarlyDialog';
import VehicleUnreadyDialog from './components/VehicleUnreadyDialog';

const TermsAgreePage = () => {
  const [checked, setChecked] = useState(false);
  const [vehicleUnready, setVehicleUnready] = useState(false);
  const [tooEarly, setTooEarly] = useState(false);

  const navigate = useNavigate();

  const id = useParams().id;

  const { data, isLoading } = useGetReservation({ id });

  const { data: setting } = useGetSetting();
  const pickUpTimeLimit = setting?.kioskEarlyPickUpTimeLimit || 0;

  const { data: contractSectionData } = useGetContractSections({
    params: {
      type: ContractSectionType.UNMANNED_DISPATCH_CONSENT,
    },
  });

  if (isLoading) {
    return <></>;
  }

  if (!data) {
    return <Navigate to='/' replace />;
  }

  const unmannedDispatchConsent =
    contractSectionData?.data?.find(
      (section) => section.branchId === data.reservedVehicle?.branchId,
    ) || contractSectionData?.data?.[0];

  const handleClickStart = () => {
    if (dayjs(data.pickUpAt).diff(dayjs(), 'minute') > pickUpTimeLimit) {
      setTooEarly(true);
      return;
    }

    if (
      data.dispatchAreaId === null ||
      data.assignedVehicleId === null ||
      data.assignedVehicle?.garageId === null
    ) {
      setVehicleUnready(true);
      return;
    }

    navigate(`/${data.id}/reservation-info`);
  };

  const goHome = () => {
    setVehicleUnready(false);
    setTooEarly(false);
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingX: '80px',
        paddingTop: '100px',
      }}
    >
      <TitleMessage
        message={['예약자 정보 확인 후', '무인배차 사용에 동의해 주세요.']}
      />
      <ReservationSummaryCard reservation={data} />
      <Box
        sx={() => ({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '32px',
          border: '1px solid #d4d4d4',
          backgroundColor: 'grey.50',
          width: '100%',
          height: '774px',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            maxHeight: 'calc(100%)',
            flexDirection: 'column',
            paddingX: '40px',
            paddingTop: '40px',
          }}
        >
          <Typography fontWeight={'bold'} fontSize={40}>
            렌트카 무인 배차 사용 동의
          </Typography>
          <Divider sx={{ marginY: '40px' }} />
          <Box
            sx={{
              width: '100%',
              height: '480px',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '0px',
              },
            }}
          >
            <Viewer content={unmannedDispatchConsent?.content || ''} />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            paddingX: '40px',
            bottom: 0,
            background: '#ffffff',
            height: '100px',
            width: '100%',
          }}
        >
          <Typography fontSize={32}>
            위의 내용을 이해했으며, 이에 동의합니다.
          </Typography>
          <CheckBox
            sx={{
              marginLeft: 'auto',
              height: '48px',
            }}
            fontSize={32}
            label='동의'
            checked={checked}
            onChange={(checked) => {
              setChecked(checked);
            }}
          ></CheckBox>
        </Box>
      </Box>
      <BottomButtons
        label='시작하기'
        backButton
        disabled={!checked}
        onClick={handleClickStart}
      />
      <VehicleUnreadyDialog
        open={vehicleUnready}
        reservation={data}
        onConfirm={goHome}
      />
      <TooEarlyDialog open={tooEarly} reservation={data} onConfirm={goHome} />
    </Box>
  );
};

export default TermsAgreePage;
