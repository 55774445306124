var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsEnum, IsIn, IsNumber, IsOptional, IsString, ValidateNested, } from 'class-validator';
import { CalculationMethod, VendorInsuranceGrade, VendorType } from '../../../enums';
import { CreateVendorAuthorityDto } from './create-vendor-authority.dto';
export class CreateVendorDto {
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateVendorDto.prototype, "name", void 0);
__decorate([
    IsEnum(VendorType),
    __metadata("design:type", String)
], CreateVendorDto.prototype, "type", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateVendorDto.prototype, "managerName", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateVendorDto.prototype, "phoneNumber1", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateVendorDto.prototype, "phoneNumber2", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateVendorDto.prototype, "fax", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateVendorDto.prototype, "email", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateVendorDto.prototype, "commission1", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateVendorDto.prototype, "commission2", void 0);
__decorate([
    IsEnum(VendorInsuranceGrade),
    __metadata("design:type", String)
], CreateVendorDto.prototype, "insuranceGrade", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateVendorAuthorityDto),
    __metadata("design:type", CreateVendorAuthorityDto)
], CreateVendorDto.prototype, "authority", void 0);
__decorate([
    IsIn(['DEFAULT'].concat(Object.values(CalculationMethod))),
    __metadata("design:type", String)
], CreateVendorDto.prototype, "calculationMethod", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], CreateVendorDto.prototype, "rate", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], CreateVendorDto.prototype, "memo", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateVendorDto.prototype, "feeGradeId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateVendorDto.prototype, "updateDeadlineHours", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateVendorDto.prototype, "updateDeadlineHoursForApi", void 0);
