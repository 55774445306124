import { Box, useTheme } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import AdminDialog from './AdminDialog';
import AdminToolbar from './AdminToolbar';

const TopProgressBar = () => {
  const theme = useTheme();
  const location = useLocation();

  const [clicks, setClicks] = useState<number[]>([]);
  const [adminDialogOpen, setAdminDialogOpen] = useState(false);
  const [toolbarOpen, setToolbarOpen] = useState(false);

  const isReservationInfo =
    location.pathname.endsWith('/terms') ||
    location.pathname.endsWith('/reservation-info');
  const isExtraItem =
    location.pathname.endsWith('/extra-item') ||
    location.pathname.endsWith('/special-contract');
  const isDriverInfo =
    location.pathname.includes('/driver-info') ||
    location.pathname.includes('/driver-license-info');
  const isSignature =
    location.pathname.endsWith('/contract-info') ||
    location.pathname.includes('/signature');
  const isPayment = location.pathname.endsWith('/payment');
  const isComplete = location.pathname.endsWith('/vehicle-ready');

  const handleBoxClick = () => {
    const now = Date.now();

    const newClicks = clicks.filter((click) => now - click < 2000);
    newClicks.push(now);

    if (newClicks.length >= 3) {
      setToolbarOpen(true);
      setClicks([]);
    } else {
      setClicks(newClicks);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor:
            !isReservationInfo &&
            !isExtraItem &&
            !isDriverInfo &&
            !isSignature &&
            !isPayment &&
            !isComplete
              ? 'transparent'
              : '#ffffff',
          width: '100%',
          height: 100,
          flexShrink: 0,
        }}
        onClick={handleBoxClick}
      >
        {location.pathname !== '/' && location.pathname !== '/login' && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              fontSize: 24,
              fontWeight: 'bold',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '15%',
                height: '100%',
                fontWeight: isReservationInfo ? 'bold' : 'normal',
                bgcolor: isReservationInfo
                  ? theme.palette.primary.main
                  : 'white',
                color: isReservationInfo
                  ? theme.palette.primary.contrastText
                  : undefined,
              }}
            >
              1.예약정보
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '17%',
                height: '100%',
                borderLeft: `50px solid ${
                  isReservationInfo ? theme.palette.primary.main : 'white'
                }`,
                borderTop: '50px solid transparent',
                borderBottom: '50px solid transparent',
                fontWeight: isExtraItem ? 'bold' : 'normal',
                bgcolor: isExtraItem ? theme.palette.primary.main : 'white',
                color: isExtraItem
                  ? theme.palette.primary.contrastText
                  : undefined,
              }}
            >
              2.선택상품
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '17%',
                height: '100%',
                borderLeft: `50px solid ${
                  isExtraItem ? theme.palette.primary.main : 'white'
                }`,
                borderTop: '50px solid transparent',
                borderBottom: '50px solid transparent',
                fontWeight: isDriverInfo ? 'bold' : 'normal',
                bgcolor: isDriverInfo ? theme.palette.primary.main : 'white',
                color: isDriverInfo
                  ? theme.palette.primary.contrastText
                  : undefined,
              }}
            >
              3.운전자등록
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '17%',
                height: '100%',
                borderLeft: `50px solid ${
                  isDriverInfo ? theme.palette.primary.main : 'white'
                }`,
                borderTop: '50px solid transparent',
                borderBottom: '50px solid transparent',
                fontWeight: isSignature ? 'bold' : 'normal',
                bgcolor: isSignature ? theme.palette.primary.main : 'white',
                color: isSignature
                  ? theme.palette.primary.contrastText
                  : undefined,
              }}
            >
              4.서명
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '17%',
                height: '100%',
                borderLeft: `50px solid ${
                  isSignature ? theme.palette.primary.main : 'white'
                }`,
                borderTop: '50px solid transparent',
                borderBottom: '50px solid transparent',
                fontWeight: isPayment ? 'bold' : 'normal',
                bgcolor: isPayment ? theme.palette.primary.main : 'white',
                color: isPayment
                  ? theme.palette.primary.contrastText
                  : undefined,
              }}
            >
              5.결제
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '17%',
                height: '100%',
                borderLeft: `50px solid ${
                  isPayment ? theme.palette.primary.main : 'white'
                }`,
                borderTop: '50px solid transparent',
                borderBottom: '50px solid transparent',
                fontWeight: isComplete ? 'bold' : 'normal',
                bgcolor: isComplete ? theme.palette.primary.main : 'white',
                color: isComplete
                  ? theme.palette.primary.contrastText
                  : undefined,
              }}
            >
              6.완료
            </Box>
          </Box>
        )}
      </Box>
      <AdminDialog
        open={adminDialogOpen}
        onClose={() => {
          setAdminDialogOpen(false);
        }}
      />
      <AdminToolbar
        open={toolbarOpen}
        onClose={() => {
          setToolbarOpen(false);
        }}
        openAdminDialog={() => {
          setAdminDialogOpen(true);
        }}
      />
    </>
  );
};

export default TopProgressBar;
