import { Box, Divider, Typography } from '@mui/material';
import { type FC } from 'react';
import {
  StyledOption,
  StyledSelect,
  TableContent,
  TableLabel,
} from '@/components/StyledMuiComponent';

type ReceiptProps = {
  totalFee: number;
  payFee: number;
  isCashPayment: boolean;
  installment: number;
  onChangeInstallment: (value: number) => void;
};

const Receipt: FC<ReceiptProps> = ({
  totalFee,
  payFee,
  isCashPayment,
  installment,
  onChangeInstallment,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#ffffff',
        borderTop: '1px solid #D4D4D4',
        height: '322px',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '1fr 3fr',
          rowGap: '24px',
          padding: '40px',
        }}
      >
        <TableLabel>요금합계</TableLabel>
        <TableContent textAlign={'right'}>
          {`${totalFee.toLocaleString()}원`}
        </TableContent>
        <TableLabel>선납입금</TableLabel>
        <TableContent textAlign={'right'}>
          {`${(totalFee - payFee).toLocaleString()}원`}
        </TableContent>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingX: '40px',
          height: '142px',
        }}
      >
        <Typography fontSize={40} fontWeight={'bold'}>
          총 결제 금액
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography
          fontSize={40}
          fontWeight={'bold'}
          sx={({ palette }) => ({
            color: palette.primary.main,
            textAlign: 'right',
          })}
        >
          {`${payFee.toLocaleString()}원`}
        </Typography>
        {!isCashPayment && payFee > 0 && (
          <StyledSelect
            sx={{
              marginLeft: '24px',
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            value={installment}
            onChange={(e) => {
              onChangeInstallment(e.target.value as number);
            }}
          >
            <StyledOption value={0}>일시불</StyledOption>
            {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((months) => (
              <StyledOption key={months} value={months}>
                {`${months}개월`}
              </StyledOption>
            ))}
          </StyledSelect>
        )}
      </Box>
    </Box>
  );
};

export default Receipt;
