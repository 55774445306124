var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { UserWithoutRelationsDto } from '../../../classes/user';
import { VehicleWithoutRelationsDto } from '../../../classes/vehicle/response/vehicle-without-relations.dto';
import { OmitType } from '../../../utils';
import { VehicleMaintenanceDetailDto } from './vehicle-maintenance-detail.dto';
export class VehicleMaintenanceDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.vehicle = initializer.vehicle;
        this.vehicleId = initializer.vehicleId;
        this.incomingDate = initializer.incomingDate;
        this.releasingDate = initializer.releasingDate;
        this.serviceCenter = initializer.serviceCenter;
        this.manager = initializer.manager;
        this.managerId = initializer.managerId;
        this.vehicleMileageAtMaintenance = initializer.vehicleMileageAtMaintenance;
        this.details = initializer.details;
    }
}
__decorate([
    Type(() => VehicleWithoutRelationsDto),
    __metadata("design:type", VehicleWithoutRelationsDto)
], VehicleMaintenanceDto.prototype, "vehicle", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], VehicleMaintenanceDto.prototype, "incomingDate", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], VehicleMaintenanceDto.prototype, "releasingDate", void 0);
__decorate([
    Type(() => UserWithoutRelationsDto),
    __metadata("design:type", UserWithoutRelationsDto)
], VehicleMaintenanceDto.prototype, "manager", void 0);
__decorate([
    Type(() => VehicleMaintenanceDetailDto),
    __metadata("design:type", Array)
], VehicleMaintenanceDto.prototype, "details", void 0);
export class VehicleMaintenanceWithoutRelationsDto extends OmitType(VehicleMaintenanceDto, ['vehicle']) {
    constructor(initializer) {
        super();
        this.id = initializer.id;
        this.createdAt = initializer.createdAt;
        this.updatedAt = initializer.updatedAt;
        this.deletedAt = initializer.deletedAt;
        this.vehicleId = initializer.vehicleId;
        this.incomingDate = initializer.incomingDate;
        this.releasingDate = initializer.releasingDate;
        this.serviceCenter = initializer.serviceCenter;
        this.manager = initializer.manager;
        this.managerId = initializer.managerId;
        this.vehicleMileageAtMaintenance = initializer.vehicleMileageAtMaintenance;
        this.details = initializer.details;
    }
}
