import { Box, Button, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import theme from '@/app/theme';
import { ChipButton, GreyButton } from '@/components/StyledMuiComponent';
import { type ScanResult } from './DriverLicenseScanDialog';

const DriverLicenseImageCensor = ({
  scanResult,
  onSelected,
  onCanceled,
}: {
  scanResult: ScanResult;
  onSelected: (isOlderVersion: boolean) => void;
  onCanceled: () => void;
}) => {
  const canvas1Ref = useRef<HTMLCanvasElement | null>(null);
  const canvas2Ref = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const context1 = canvas1Ref.current?.getContext('2d');
    const context2 = canvas2Ref.current?.getContext('2d');

    if (context1 && context2) {
      const image = new Image();
      image.src = URL.createObjectURL(
        new Blob([scanResult.imageBuffer], { type: 'image/bmp' }),
      );
      image.onload = () => {
        for (const context of [context1, context2]) {
          if (scanResult.direction === 2) {
            context.translate(370 / 2, 230 / 2);
            context.rotate(Math.PI);
            context.drawImage(image, -370 / 2, -230 / 2, 370, 230);
            context.setTransform(1, 0, 0, 1, 0, 0);
          } else {
            context.drawImage(image, 0, 0, 370, 230);
          }
          context.fillStyle = 'black';
        }
        context1.fillRect(198, 78, 66, 19);
        context2.fillRect(260, 78, 66, 19);
      };
    }
  }, [scanResult]);

  const [isOlderVersion, setIsOlderVersion] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: '#ffffff',
        padding: '40px',
      }}
    >
      <Typography fontSize={40} fontWeight='bold'>
        운전자 운전면허증 스캔 완료
      </Typography>
      <Box sx={{ height: '16px' }} />
      <Typography fontSize={32} fontWeight='regular' color='text.secondary'>
        주민번호 뒷자리가 가려진 이미지를 선택하세요.
      </Typography>
      <Box sx={{ height: '40px' }} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 20px 1fr',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
          onClick={() => {
            setIsOlderVersion(false);
          }}
        >
          <canvas
            width={370}
            height={230}
            style={{
              outline: '3px solid',
              outlineColor: isOlderVersion
                ? 'transparent'
                : theme.palette.primary.main,
            }}
            ref={canvas1Ref}
          />
          <Box sx={{ height: '20px' }} />
          <Box>
            <ChipButton
              useIcon={false}
              sx={{
                ...(!isOlderVersion && {
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  background: theme.palette.primary.main + '08',
                  ':hover': {
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    background: theme.palette.primary.main + '08',
                  },
                }),
              }}
            >
              {isOlderVersion ? '선택하기' : '선택됨'}
            </ChipButton>
          </Box>
        </Box>
        <Box></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
          onClick={() => {
            setIsOlderVersion(true);
          }}
        >
          <canvas
            width={370}
            height={230}
            style={{
              outline: '3px solid',
              outlineColor: isOlderVersion
                ? theme.palette.primary.main
                : 'transparent',
            }}
            ref={canvas2Ref}
          />
          <Box sx={{ height: '20px' }} />
          <Box>
            <ChipButton
              useIcon={false}
              sx={{
                ...(isOlderVersion && {
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  background: theme.palette.primary.main + '08',
                  ':hover': {
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    background: theme.palette.primary.main + '08',
                  },
                }),
              }}
            >
              {isOlderVersion ? '선택됨' : '선택하기'}
            </ChipButton>
          </Box>
        </Box>
      </Box>

      <Box sx={{ height: '40px' }} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 20px 1fr',
          width: '100%',
        }}
      >
        <GreyButton
          sx={{
            width: '100%',
            fontSize: 40,
          }}
          onClick={() => {
            onCanceled();
          }}
        >
          <Typography fontSize={40} fontWeight='medium'>
            스캔 다시하기
          </Typography>
        </GreyButton>
        <Box height={'20px'}></Box>
        <Button
          variant='contained'
          sx={{
            height: '100%',
            fontSize: 40,
          }}
          onClick={() => {
            onSelected(isOlderVersion);
          }}
        >
          <Typography fontSize={40} fontWeight='medium'>
            선택 완료
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default DriverLicenseImageCensor;
