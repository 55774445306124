var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto, VisibilityMapDto } from '../../../classes/common';
export class ExtraItemWithoutRelationsDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.name = initializer.name;
        this.category = initializer.category;
        this.fee = initializer.fee;
        this.feeCriterion = initializer.feeCriterion;
        this.exposure = initializer.exposure;
        this.visibility = initializer.visibility;
        this.minReservationPeriod = initializer.minReservationPeriod;
        this.detail = initializer.detail;
        this.global = initializer.global;
        this.availableWithInsuranceOption1 =
            initializer.availableWithInsuranceOption1;
        this.availableWithInsuranceOption2 =
            initializer.availableWithInsuranceOption2;
    }
}
__decorate([
    Type(() => VisibilityMapDto),
    __metadata("design:type", VisibilityMapDto)
], ExtraItemWithoutRelationsDto.prototype, "visibility", void 0);
