import { Box, Divider, Typography } from '@mui/material';
import {
  formatPhoneNumber,
  getActualDropOffAt,
  type ReservationDto,
} from '@orcar/common';
import dayjs from 'dayjs';

type ReservationSummaryCardProps = {
  reservation: ReservationDto;
};

const ReservationSummaryCard = ({
  reservation,
}: ReservationSummaryCardProps) => {
  return (
    <Box
      sx={{
        marginTop: '56px',
        width: '100%',
        background: '#ffffff',
        border: '1px solid #d4d4d4',
        padding: '40px',
        height: '370px',
      }}
    >
      <Typography
        sx={({ palette }) => ({
          fontSize: 40,
          fontWeight: 'bold',
          color: palette.text.primary,
        })}
        lineHeight={'normal'}
      >{`${reservation.customerName1} 님`}</Typography>
      <Divider sx={{ marginY: '40px' }} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '145px 1fr',
          rowGap: '24px',
        }}
      >
        <Typography
          sx={({ palette }) => ({
            fontSize: 32,
            color: palette.text.secondary,
          })}
        >
          전화번호
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: 32,
            color: palette.text.primary,
          })}
        >
          {reservation.customerPhoneNumber1 &&
            formatPhoneNumber(reservation.customerPhoneNumber1)}
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: 32,
            color: palette.text.secondary,
          })}
        >
          대여차량
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: 32,
            color: palette.text.primary,
          })}
        >
          {reservation.vehicleModel.name}
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: 32,
            color: palette.text.secondary,
          })}
        >
          대여기간
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: 32,
            color: palette.text.primary,
          })}
        >
          {dayjs(reservation.pickUpAt).format('YY.MM.DD HH:mm')}
          {' ~ '}
          {dayjs(getActualDropOffAt(reservation)).format('YY.MM.DD HH:mm')}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReservationSummaryCard;
