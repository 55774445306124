import { Box, Button, type SxProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BackIcon from '../assets/icons/arrow_back.svg?react';
import HomeIcon from '../assets/icons/home.svg?react';

type BottomButtonsProps = {
  label?: string;
  onClick?: () => void;
  isSubmit?: boolean;
  backButton?: boolean;
  onClickBack?: () => void;
  homeButton?: boolean;
  onClickHome?: () => void;
  disabled?: boolean;
  sx?: SxProps;
};

const BottomButtons = ({
  label,
  backButton = false,
  homeButton = false,
  isSubmit = false,
  onClickBack,
  onClickHome,
  onClick,
  disabled = false,
  sx,
}: BottomButtonsProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        display: 'flex',
        width: '100vw',
        flexShrink: 0,
        ...sx,
      }}
    >
      {homeButton && (
        <Button
          variant='contained'
          sx={(theme) => ({
            background: theme.palette.background.default,
            width: '220px',
            height: '240px',
            fontSize: 64,
            ':hover': {
              background: theme.palette.grey[200],
            },
          })}
          onClick={() => {
            onClickHome
              ? onClickHome()
              : navigate('/', {
                  replace: true,
                });
          }}
        >
          <HomeIcon />
        </Button>
      )}
      {backButton && (
        <Button
          variant='contained'
          sx={(theme) => ({
            background: theme.palette.grey[200],
            width: '220px',
            height: '240px',
            fontSize: 64,
            ':hover': {
              background: theme.palette.grey[300],
            },
          })}
          onClick={() => {
            onClickBack ? onClickBack() : navigate(-1);
          }}
        >
          <BackIcon />
        </Button>
      )}
      <Button
        variant='contained'
        sx={(theme) => ({
          flexGrow: 1,
          height: '240px',
          fontSize: 64,
          ':disabled': {
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.main,
          },
        })}
        disabled={disabled}
        onClick={() => {
          onClick && onClick();
        }}
        type={isSubmit ? 'submit' : 'button'}
      >
        {label}
      </Button>
    </Box>
  );
};

export default BottomButtons;
