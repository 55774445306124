import {
  type ManagerApprovalDto,
  type RequestManagerApprovalDto,
} from '@orcar/common';
import axiosClient from './axiosClient';

export const requestManagerApproval = async (
  input: RequestManagerApprovalDto,
) => {
  const response = await axiosClient.post<ManagerApprovalDto>(
    `/manager-approvals/request`,
    input,
  );

  return response.data;
};
