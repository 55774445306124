export var RequestSource;
(function (RequestSource) {
    RequestSource["ERP"] = "ERP";
    RequestSource["VENDOR"] = "VENDOR";
    RequestSource["KIOSK"] = "KIOSK";
    RequestSource["ONLINE_CONTRACT"] = "ONLINE_CONTRACT";
    RequestSource["OPEN_API"] = "OPEN_API";
    RequestSource["ONLINE_CHECK_IN"] = "ONLINE_CHECK_IN";
    RequestSource["SURE"] = "SURE";
    // PARTNERS = 'PARTNERS', // 필요할지 몰라서 일단 주석처리
})(RequestSource || (RequestSource = {}));
