import {
  Box,
  Button,
  type ButtonProps,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import RightIcon from '@/assets/icons/arrow_forward_small.svg?react';

type ChipButtonProps = ButtonProps & {
  useIcon?: boolean;
  iconColor?: string;
  buttonSize?: 'small' | 'large';
};

export const TableLabel = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  color: theme.palette.text.secondary,
}));

export const TableContent = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  color: theme.palette.text.primary,
}));

export const GreyButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey[100],
  color: theme.palette.text.primary,
  ':hover': {
    background: theme.palette.grey[200],
  },
}));

export const SureYellowButton = styled(Button)(() => ({
  background: '#FFB21D',
  color: '#3B3B3B',
  ':hover': {
    background: '#CC8E17',
  },
}));

export const SmallButton = styled(Button)(({ theme }) => ({
  background: '#ffffff',
  color: theme.palette.text.secondary,
  fontSize: 32,
  fontWeight: 'regular',
  border: `1px solid ${theme.palette.grey[200]}`,
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingY: 0,
  ':hover': {
    background: '#ffffff',
    color: theme.palette.text.primary,
  },
}));

export const ChipButton = (props: ChipButtonProps) => {
  const {
    useIcon = true,
    iconColor = '#7e7e7e',
    buttonSize = 'small',
    ...buttonProps
  } = props;

  return (
    <SmallButton
      {...buttonProps}
      sx={{
        ...buttonProps.sx,
        paddingRight: useIcon ? '10px' : '16px',
        paddingY: buttonSize === 'small' ? 0 : '4px',
      }}
      endIcon={
        useIcon && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '24px',
              height: '24px',
            }}
          >
            <RightIcon stroke={iconColor} fill={iconColor} />
          </Box>
        )
      }
    />
  );
};

export const StyledSelect = styled(Select)(({ theme }) => ({
  height: '56px',
  color: theme.palette.grey[500],
  backgroundColor: theme.palette.grey[50],
  borderRadius: 0,
  paddingLeft: '16px',
  paddingRight: '4px',

  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  //disabled border
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #e0e0e0',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  '& .MuiOutlinedInput-input': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '32px',
    padding: 0,
  },
  '& .MuiSelect-icon': {
    fontSize: '24px',
    right: '10px',
  },
}));

export const StyledOption = styled(MenuItem)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  fontSize: '32px',
  height: '64px',
}));
