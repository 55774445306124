var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean, IsDateString, IsEnum, IsNumber, IsOptional, IsString, } from 'class-validator';
import { DriverLicenseType } from '../../../enums';
export class CreateReservationPersonDto {
}
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "name", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "birthDate", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "telNumber", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "phoneNumber", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "fax", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "email", void 0);
__decorate([
    IsEnum(DriverLicenseType),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "driverLicenseType", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "driverLicenseNumber", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "driverLicenseValidDate", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "driverLicenseImageUrl", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "signatureUrl", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "postNumber", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "address", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "nationality", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "passportNumber", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], CreateReservationPersonDto.prototype, "isBirthDateExtracted", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], CreateReservationPersonDto.prototype, "isDriverLicenseNumberExtracted", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "memo", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], CreateReservationPersonDto.prototype, "shouldValidate", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], CreateReservationPersonDto.prototype, "sureUserId", void 0);
