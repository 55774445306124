var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { VehicleModelAudioDto } from './vehicle-model-audio.dto';
import { VehicleModelImageDto } from './vehicle-model-image.dto';
import { VehicleModelKeyOptionDto } from './vehicle-model-key-option.dto';
import { VehicleModelOptionDto } from './vehicle-model-option.dto';
export class VehicleModelD2CDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.name = initializer.name;
        this.graphOrder = initializer.graphOrder;
        this.type = initializer.type;
        this.state = initializer.state;
        this.manufacturer = initializer.manufacturer;
        this.fuel = initializer.fuel;
        this.transmission = initializer.transmission;
        this.seat = initializer.seat;
        this.capacity = initializer.capacity;
        this.mileage = initializer.mileage;
        this.audio = initializer.audio;
        this.drivingSystem = initializer.drivingSystem;
        this.keyOption = initializer.keyOption;
        this.modelOption = initializer.modelOption;
        this.driverAge = initializer.driverAge;
        this.driverExperience = initializer.driverExperience;
        this.memo = initializer.memo;
        this.calculationMethod = initializer.calculationMethod;
        // D2C
        this.title = initializer.title;
        this.vehicleModelImages = initializer.vehicleModelImages;
    }
}
__decorate([
    Type(() => VehicleModelAudioDto),
    __metadata("design:type", VehicleModelAudioDto)
], VehicleModelD2CDto.prototype, "audio", void 0);
__decorate([
    Type(() => VehicleModelKeyOptionDto),
    __metadata("design:type", VehicleModelKeyOptionDto)
], VehicleModelD2CDto.prototype, "keyOption", void 0);
__decorate([
    Type(() => VehicleModelOptionDto),
    __metadata("design:type", VehicleModelOptionDto)
], VehicleModelD2CDto.prototype, "modelOption", void 0);
__decorate([
    Type(() => VehicleModelImageDto),
    __metadata("design:type", Array)
], VehicleModelD2CDto.prototype, "vehicleModelImages", void 0);
