var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsArray, IsEnum, IsNumber, IsString, ValidateNested, } from 'class-validator';
import { InsuranceCalculationMethod } from '../../../enums/insurance/insurance-calculation-method.enum';
import { CreateInsuranceDetailDto } from './create-insurance-detail.dto';
import { CreateDaily357InsuranceFeeDto, CreateDaily7InsuranceFeeDto, CreateDailyExcessInsuranceFeeDto, CreateHourly24InsuranceFeeDto, CreateHourlyInsuranceFeeDto, } from './fee';
const determineInsuranceFeeType = (type) => {
    var _a;
    const calculationMethod = (_a = type === null || type === void 0 ? void 0 : type.object) === null || _a === void 0 ? void 0 : _a.calculationMethod;
    switch (calculationMethod) {
        case InsuranceCalculationMethod.DAILY_7:
            return CreateDaily7InsuranceFeeDto;
        case InsuranceCalculationMethod.DAILY_357:
            return CreateDaily357InsuranceFeeDto;
        case InsuranceCalculationMethod.DAILY_EXCESS:
            return CreateDailyExcessInsuranceFeeDto;
        case InsuranceCalculationMethod.HOUR_0:
        case InsuranceCalculationMethod.HOUR_1:
        case InsuranceCalculationMethod.HOUR_2:
        case InsuranceCalculationMethod.HOUR_3:
        case InsuranceCalculationMethod.HOUR_4:
        case InsuranceCalculationMethod.HOUR_5:
        case InsuranceCalculationMethod.N_HOUR_1:
        case InsuranceCalculationMethod.N_HOUR_2:
        case InsuranceCalculationMethod.N_HOUR_3:
            return CreateHourlyInsuranceFeeDto;
        case InsuranceCalculationMethod.HOUR_24:
            return CreateHourly24InsuranceFeeDto;
        default:
            throw new Error('Invalid calculation method');
    }
};
export class CreateInsuranceDto {
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateInsuranceDto.prototype, "name", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateInsuranceDetailDto),
    __metadata("design:type", CreateInsuranceDetailDto)
], CreateInsuranceDto.prototype, "detailA", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateInsuranceDetailDto),
    __metadata("design:type", CreateInsuranceDetailDto)
], CreateInsuranceDto.prototype, "detailB", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateInsuranceDetailDto),
    __metadata("design:type", CreateInsuranceDetailDto)
], CreateInsuranceDto.prototype, "detailC", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateInsuranceDetailDto),
    __metadata("design:type", CreateInsuranceDetailDto)
], CreateInsuranceDto.prototype, "detailD", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateInsuranceDetailDto),
    __metadata("design:type", CreateInsuranceDetailDto)
], CreateInsuranceDto.prototype, "detailE", void 0);
__decorate([
    ValidateNested(),
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], CreateInsuranceDto.prototype, "feeA", void 0);
__decorate([
    ValidateNested(),
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], CreateInsuranceDto.prototype, "feeB", void 0);
__decorate([
    ValidateNested(),
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], CreateInsuranceDto.prototype, "feeC", void 0);
__decorate([
    ValidateNested(),
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], CreateInsuranceDto.prototype, "feeD", void 0);
__decorate([
    ValidateNested(),
    Type(determineInsuranceFeeType),
    __metadata("design:type", Object)
], CreateInsuranceDto.prototype, "feeE", void 0);
__decorate([
    IsEnum(InsuranceCalculationMethod),
    __metadata("design:type", String)
], CreateInsuranceDto.prototype, "calculationMethod", void 0);
__decorate([
    IsArray(),
    IsNumber({}, { each: true }),
    __metadata("design:type", Array)
], CreateInsuranceDto.prototype, "vehicleModelIds", void 0);
