import { BaseNumberIdDataDto } from '../../../classes/common';
export class BankCardDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.type = initializer.type;
        this.name = initializer.name;
        this.accountNumber = initializer.accountNumber;
        this.default = initializer.default;
    }
}
