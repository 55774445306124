export var ContractSectionType;
(function (ContractSectionType) {
    // for contract paper
    ContractSectionType["COMPANY_INFO"] = "COMPANY_INFO";
    ContractSectionType["MAIN_CONTENT"] = "MAIN_CONTENT";
    ContractSectionType["PRIVACY_AGREEMENT"] = "PRIVACY_AGREEMENT";
    ContractSectionType["FINAL_AGREEMENT"] = "FINAL_AGREEMENT";
    ContractSectionType["COMPANY_SIGNATURE"] = "COMPANY_SIGNATURE";
    ContractSectionType["ADDITIONAL_CONTENT"] = "ADDITIONAL_CONTENT";
    ContractSectionType["INSURANCE_ADDITIONAL_CONTENT"] = "INSURANCE_ADDITIONAL_CONTENT";
    // for online-check-in
    ContractSectionType["NON_INSURANCE_GUIDANCE"] = "NON_INSURANCE_GUIDANCE";
    // for kiosk
    ContractSectionType["UNMANNED_DISPATCH_CONSENT"] = "UNMANNED_DISPATCH_CONSENT";
})(ContractSectionType || (ContractSectionType = {}));
