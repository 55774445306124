export const DRIVER_LICENSE_VALIDATION_ERROR = {
  REQUIRE_TO_NAME: 'REQUIRE_TO_NAME',
  REQUIRE_TO_PHONE_NUMBER: 'REQUIRE_TO_PHONE_NUMBER',
  REQUIRE_TO_BIRTH_DATE: 'REQUIRE_TO_BIRTH_DATE',
  REQUIRE_LICENSE_TYPE: 'REQUIRE_LICENSE_TYPE',
  REQUIRE_LICENSE_NUMBER: 'REQUIRE_LICENSE_NUMBER',

  NOT_SATISFIED_REQUIRE_CONDITION: 'NOT_SATISFIED_REQUIRE_CONDITION',

  FAILED_TO_VERIFY_NAME: 'FAILED_TO_VERIFY_NAME',
  FAILED_TO_VERIFY_LICENSE_TYPE: 'FAILED_TO_VERIFY_LICENSE_TYPE',
  FAILED_TO_VERIFY_LICENSE_NUMBER: 'FAILED_TO_VERIFY_LICENSE_NUMBER',
  FAILED_TO_EXTRACT_LICENSE_NUMBER: 'FAILED_TO_EXTRACT_LICENSE_NUMBER',

  MISMATCHED_LICENSE_NUMBER: 'MISMATCHED_LICENSE_NUMBER',
} as const;
