import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
export class VehicleMaintenanceDetailDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.vehicleMaintenanceId = initializer.vehicleMaintenanceId;
        this.type = initializer.type;
        this.partSupplier = initializer.partSupplier;
        this.partPrice = initializer.partPrice;
        this.content = initializer.content;
    }
}
