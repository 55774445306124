import cv from '@techstark/opencv-js';
import { createWorker, type Lang, PSM } from 'tesseract.js';

type ImageLike =
  | string
  | HTMLImageElement
  | HTMLCanvasElement
  | HTMLVideoElement
  | CanvasRenderingContext2D
  | File
  | Blob
  | ImageData
  | Buffer
  | OffscreenCanvas;

const WORKER_OPTIONS = [
  {
    lang: 'eng',
    tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
  },
  {
    lang: 'eng',
    tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
    recognizeOption: {
      rectangle: {
        top: 0,
        left: 300,
        width: 560,
        height: 140,
      },
    },
  },
  {
    lang: ['eng', 'kor'] as unknown as Lang[],
    tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
  },
  {
    lang: 'kor',
    tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
  },
  {
    lang: 'eng',
    tessedit_pageseg_mode: PSM.AUTO,
  },
  {
    lang: 'kor',
    tessedit_pageseg_mode: PSM.AUTO,
  },
];

export function compareLicenseNumbers(
  licenseNumber1: string,
  licenseNumber2: string,
) {
  if (licenseNumber1.length !== licenseNumber2.length) return false;

  let countMatch = 0;
  for (let i = 0; i < licenseNumber1.length; i++) {
    if (licenseNumber1[i] === licenseNumber2[i]) {
      countMatch++;
    }
  }

  return countMatch >= 6;
}

export const extractTextFromImage = async (image: ImageLike | null) => {
  if (!image) return null;

  const processedImage = await preprocessImage(image);

  return await runImageOcr(processedImage);
};

const preprocessImage = async (image: ImageLike) => {
  try {
    const img = new Image();
    img.src = URL.createObjectURL(image as Blob);
    img.width = 800;
    img.height = 523;

    await new Promise<void>((resolve) => {
      img.onload = () => resolve();
    });

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    if (ctx) {
      ctx.drawImage(img, 0, 0, img.width, img.height);
    }

    const src = cv.imread(canvas);
    const dst1 = new cv.Mat();

    const sharpeningMask1 = cv.matFromArray(
      3,
      3,
      cv.CV_32F,
      [-1, -1, 0, -1, 8, -1, 0, -1, -1],
    );

    cv.filter2D(src, dst1, cv.CV_8U, sharpeningMask1);

    cv.imshow(canvas, dst1);

    src.delete();
    dst1.delete();
    sharpeningMask1.delete();

    return canvas.toDataURL();
  } catch (e) {
    console.error(e);
  }
};

const runImageOcr = async (image: string | undefined) => {
  if (!image) {
    console.error('Image is not provided for OCR');
    return null;
  }

  try {
    let recognizedLicenseNumber: string | undefined = undefined;
    let recognizedBirthDate: string | undefined = undefined;

    for (let i = 0; i < WORKER_OPTIONS.length; i++) {
      const { lang, tessedit_pageseg_mode, recognizeOption } =
        WORKER_OPTIONS[i];
      const worker = await createWorker(lang);
      worker.setParameters({
        preserve_interword_spaces: '1',
        tessedit_pageseg_mode,
        tessedit_char_whitelist: '0123456789-',
      });
      const {
        data: { text },
      } = await worker.recognize(image, {
        rectangle: recognizeOption?.rectangle,
        rotateAuto: true,
      });

      const licenseNumber = text
        .match(/\d{2}-\d{6}-\d{2}/)?.[0]
        .replace(/-/g, '')
        .slice(0, 8);
      const birthDate = text.match(/\d{6}-\d{7}/)?.[0].slice(0, 6);

      if (!!birthDate) {
        recognizedBirthDate = birthDate;
      }

      if (!!licenseNumber) {
        recognizedLicenseNumber = licenseNumber;
        worker.terminate();
        break;
      }
      worker.terminate();
    }
    return {
      recognizedLicenseNumber,
      recognizedBirthDate,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
};
