import {
  type AxiosException,
  type ContractSectionDto,
  type GetManyApiProps,
  type GetManyContractSectionsQueryDto,
  type Paginated,
} from '@orcar/common';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { getContractSections } from '@/apis/contract-section.api';

export const useGetContractSections = (
  props: GetManyApiProps<
    GetManyContractSectionsQueryDto,
    Paginated<ContractSectionDto>
  > = { params: {} },
) => {
  const { params } = props;
  return useQuery<Paginated<ContractSectionDto>, AxiosError<AxiosException>>({
    queryKey: ['contractSectionList', params],
    queryFn: () => getContractSections(params),
    placeholderData: keepPreviousData,
  });
};
