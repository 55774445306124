var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { UserWithoutRelationsDto } from '../../../classes/user/response/user-without-relations.dto';
export class PostDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.title = initializer.title;
        this.notice = initializer.notice;
        this.boardType = initializer.boardType;
        this.content = initializer.content;
        this.requestId = initializer.requestId;
        this.answers = initializer.answers;
        this.user = initializer.user;
        this.userId = initializer.userId;
    }
}
__decorate([
    Type(() => PostDto),
    __metadata("design:type", Array)
], PostDto.prototype, "answers", void 0);
__decorate([
    Type(() => UserWithoutRelationsDto),
    __metadata("design:type", UserWithoutRelationsDto)
], PostDto.prototype, "user", void 0);
