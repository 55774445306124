import {
  type AxiosException,
  type ManagerApprovalDto,
  type ManagerApprovalType,
  type RequestManagerApprovalDto,
} from '@orcar/common';
import { useMutation } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { requestManagerApproval } from '@/apis/manager-approval.api';

export type ApprovalStatus = 'IDLE' | 'WAITING' | 'APPROVED';

export const useRequestApproval = () => {
  return useMutation<
    ManagerApprovalDto,
    AxiosError<AxiosException>,
    RequestManagerApprovalDto
  >({ mutationFn: requestManagerApproval });
};

export const useManagerApproval = (
  type: ManagerApprovalType,
  reservationId?: string,
) => {
  const [intervalId, setIntervalId] = useState(0);
  const [status, setStatus] = useState<ApprovalStatus>('IDLE');

  const { mutateAsync: requestApproval } = useRequestApproval();

  useEffect(() => {
    return () => {
      window.clearInterval(intervalId);
    };
  }, [intervalId]);

  const requestAndCheckApproval = useCallback(
    async (requestKey: string) => {
      const { approvedAt } = await requestApproval({
        requestKey,
        type,
        reservationId,
      });

      return !!approvedAt;
    },
    [requestApproval, type, reservationId],
  );

  const stop = useCallback(() => {
    setIntervalId(0);
    setStatus('IDLE');
  }, []);

  const start = useCallback(
    async (callback: () => Promise<void> | void) => {
      setStatus('WAITING');

      const requestKey = `${reservationId}-${type}-${Date.now()}`;

      try {
        await requestAndCheckApproval(requestKey);
      } catch {
        stop();
        return;
      }

      const interval = window.setInterval(async () => {
        try {
          const isApproved = await requestAndCheckApproval(requestKey);

          if (isApproved) {
            setIntervalId(0);
            setStatus('APPROVED');
            await callback();
          }
        } catch {
          stop();
        }
      }, 2000);

      setIntervalId(interval);
    },
    [stop, requestAndCheckApproval, reservationId, type],
  );

  return {
    status,
    start,
    stop,
  };
};
