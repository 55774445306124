var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean, IsDateString, IsNumber, IsOptional, IsString, Validate, } from 'class-validator';
import { IsReservationStateQuery } from '../../../classes/reservation/request/get-many-reservations-query.dto';
import { TransformBoolean } from '../../../decorators';
export class GetVendorSettlementQueryDto {
}
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetVendorSettlementQueryDto.prototype, "fromCreatedAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetVendorSettlementQueryDto.prototype, "toCreatedAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetVendorSettlementQueryDto.prototype, "fromPickUpAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetVendorSettlementQueryDto.prototype, "toPickUpAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetVendorSettlementQueryDto.prototype, "fromDropOffAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetVendorSettlementQueryDto.prototype, "toDropOffAt", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetVendorSettlementQueryDto.prototype, "branchId", void 0);
__decorate([
    Validate(IsReservationStateQuery),
    IsOptional(),
    __metadata("design:type", Object)
], GetVendorSettlementQueryDto.prototype, "state", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetVendorSettlementQueryDto.prototype, "vehicleModelId", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetVendorSettlementQueryDto.prototype, "withVehicle", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetVendorSettlementQueryDto.prototype, "criterion", void 0);
