export class ExtraItemForComparisonDto {
    constructor(initialize) {
        this.name = initialize.name;
    }
}
export class ConvenienceEquipmentForComparisonDto {
    constructor(initialize) {
        this.name = initialize.name;
    }
}
export class InsuranceForComparisonDto {
    constructor(initialize) {
        this.name = initialize.name;
    }
}
