var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { UserAuthorityDto } from '../../../classes/user/response/user-authority.dto';
import { UserRole } from '../../../enums';
import { VendorLoginResponseDto } from './vendor-login-response.dto';
export class LoginResponseDto {
    constructor(initializer) {
        this.login = initializer.login;
        this.id = initializer.id;
        this.username = initializer.username;
        this.name = initializer.name;
        this.companyName = initializer.companyName;
        this.authority = initializer.authority;
        this.role = initializer.role;
        this.landingPage = initializer.landingPage;
        if (initializer.role === UserRole.VENDOR) {
            if (!initializer.vendor) {
                throw new Error('LoginResponseDto: Vendor Role User must have vendor');
            }
            this.vendor = initializer.vendor;
        }
        this.isOnlineContract = initializer.isOnlineContract;
    }
}
__decorate([
    Type(() => UserAuthorityDto),
    __metadata("design:type", Object)
], LoginResponseDto.prototype, "authority", void 0);
__decorate([
    Type(() => VendorLoginResponseDto),
    __metadata("design:type", Object)
], LoginResponseDto.prototype, "vendor", void 0);
