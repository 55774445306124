var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsOptional, ValidateNested } from 'class-validator';
import { UpdateVehicleModelAudioDto, UpdateVehicleModelKeyOptionDto, UpdateVehicleModelOptionDto, } from '../../../classes/vehicle-model';
import { OmitType, PartialType } from '../../../utils';
import { CreateVehicleDto } from './create-vehicle.dto';
export class UpdateVehicleDto extends PartialType(OmitType(CreateVehicleDto, ['audio', 'vehicleOption', 'keyOption'])) {
}
__decorate([
    ValidateNested(),
    Type(() => UpdateVehicleModelAudioDto),
    IsOptional(),
    __metadata("design:type", UpdateVehicleModelAudioDto)
], UpdateVehicleDto.prototype, "audio", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateVehicleModelOptionDto),
    IsOptional(),
    __metadata("design:type", UpdateVehicleModelOptionDto)
], UpdateVehicleDto.prototype, "vehicleOption", void 0);
__decorate([
    ValidateNested(),
    Type(() => UpdateVehicleModelKeyOptionDto),
    IsOptional(),
    __metadata("design:type", UpdateVehicleModelKeyOptionDto)
], UpdateVehicleDto.prototype, "keyOption", void 0);
