var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean, IsDateString, IsEnum, IsIn, IsNumber, IsOptional, IsString, Validate, } from 'class-validator';
import { GetManyQueryDto } from '../../../classes/common/request/get-many-query.dto';
import { IsReservationStateQuery } from '../../../classes/reservation/request';
import { TransformBoolean } from '../../../decorators';
import { BankCardType, ReservationPaymentType } from '../../../enums';
import { PartialType } from '../../../utils';
export class GetManyReservationPaymentsQueryDto extends PartialType(GetManyQueryDto) {
}
__decorate([
    IsIn(['PAYMENT'].concat(Object.values(ReservationPaymentType))),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationPaymentsQueryDto.prototype, "type", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationPaymentsQueryDto.prototype, "branchId", void 0);
__decorate([
    IsEnum(BankCardType),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationPaymentsQueryDto.prototype, "bankCardType", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationPaymentsQueryDto.prototype, "bankCardId", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationPaymentsQueryDto.prototype, "bankCardNameKeyword", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationPaymentsQueryDto.prototype, "managerId", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationPaymentsQueryDto.prototype, "date", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationPaymentsQueryDto.prototype, "fromDate", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationPaymentsQueryDto.prototype, "toDate", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationPaymentsQueryDto.prototype, "fromPickUpAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationPaymentsQueryDto.prototype, "toPickUpAt", void 0);
__decorate([
    Validate(IsReservationStateQuery),
    IsOptional(),
    __metadata("design:type", Object)
], GetManyReservationPaymentsQueryDto.prototype, "reservationState", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationPaymentsQueryDto.prototype, "unSettledOnly", void 0);
