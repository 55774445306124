var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { ReservationChecklistItemForComparisonDto, ReservationConsultationForComparisonDto, ReservationConvenienceForComparisonDto, ReservationExtensionForComparisonDto, ReservationExtraItemForComparisonDto, ReservationInsuranceForComparisonDto, ReservationManagerApprovalForComparisonDto, ReservationMessagingRecordForComparisonDto, ReservationPaymentForComparisonDto, } from './reservation-for-comparison-array.dto';
import { DispatchAreaForComparisonDto, GarageForComparisonDto, ReservationPersonForComparisonDto, UserForComparisonDto, VehicleForComparisonDto, VehicleModelForComparisonDto, VendorForComparisonDto, } from './reservation-for-comparison-entity.dto';
export class ReservationForComparisonDto {
    constructor(initialize) {
        this.vendor = initialize.vendor;
        this.vehicleModel = initialize.vehicleModel;
        this.pickUpGarage = initialize.pickUpGarage;
        this.dropOffGarage = initialize.dropOffGarage;
        this.reservedVehicle = initialize.reservedVehicle;
        this.manager = initialize.manager;
        this.assignedVehicle = initialize.assignedVehicle;
        this.dispatchArea = initialize.dispatchArea;
        this.returnManager = initialize.returnManager;
        this.returnGarage = initialize.returnGarage;
        this.releaseManager = initialize.releaseManager;
        this.cancelManager = initialize.cancelManager;
        this.pickUpAt = initialize.pickUpAt;
        this.dropOffAt = initialize.dropOffAt;
        this.expectedDepositDate = initialize.expectedDepositDate;
        this.canceledAt = initialize.canceledAt;
        this.departureDate = initialize.departureDate;
        this.returnDate = initialize.returnDate;
        this.flightDepartedAt = initialize.flightDepartedAt;
        this.billingTime = initialize.billingTime;
        this.vendorReservationNumber = initialize.vendorReservationNumber;
        this.insuranceOption = initialize.insuranceOption;
        this.listPrice = initialize.listPrice;
        this.finalPrice = initialize.finalPrice;
        this.priceLock = initialize.priceLock;
        this.directPaymentPrice = initialize.directPaymentPrice;
        this.checkDirectPayment = initialize.checkDirectPayment;
        this.state = initialize.state;
        this.route = initialize.route;
        this.allAges = initialize.allAges;
        this.guide = initialize.guide;
        this.email = initialize.email;
        this.customerName1 = initialize.customerName1;
        this.customerName2 = initialize.customerName2;
        this.customerPhoneNumber1 = initialize.customerPhoneNumber1;
        this.customerPhoneNumber2 = initialize.customerPhoneNumber2;
        this.memo = initialize.memo;
        this.vendorMemo = initialize.vendorMemo;
        this.expectedDepositFee = initialize.expectedDepositFee;
        this.expectedDepositMemo = initialize.expectedDepositMemo;
        this.contractNo = initialize.contractNo;
        this.departureFuelRate = initialize.departureFuelRate;
        this.departureFuelRateUnit = initialize.departureFuelRateUnit;
        this.departureMileage = initialize.departureMileage;
        this.releasePreparation = initialize.releasePreparation;
        this.releasePreparationMemo = initialize.releasePreparationMemo;
        this.returnFuelRate = initialize.returnFuelRate;
        this.returnFuelRateUnit = initialize.returnFuelRateUnit;
        this.returnMileage = initialize.returnMileage;
        this.cancelReason = initialize.cancelReason;
        this.customerAirportArrived = initialize.customerAirportArrived;
        this.numberOfPassengers = initialize.numberOfPassengers;
        this.wantedVendor = initialize.wantedVendor;
        this.contractor = initialize.contractor;
        this.driver = initialize.driver;
        this.extraItems = initialize.extraItems;
        this.conveniences = initialize.conveniences;
        this.extensions = initialize.extensions;
        this.insurances = initialize.insurances;
        this.payments = initialize.payments;
        this.consultations = initialize.consultations;
        this.checklistItems = initialize.checklistItems;
        this.messagingRecords = initialize.messagingRecords;
        this.managerApprovals = initialize.managerApprovals;
        this.privacyAgreementSignatureUrl = initialize.privacyAgreementSignatureUrl;
    }
}
__decorate([
    Type(() => VendorForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "vendor", void 0);
__decorate([
    Type(() => VehicleModelForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "vehicleModel", void 0);
__decorate([
    Type(() => GarageForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "pickUpGarage", void 0);
__decorate([
    Type(() => GarageForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "dropOffGarage", void 0);
__decorate([
    Type(() => GarageForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "returnGarage", void 0);
__decorate([
    Type(() => VehicleForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "reservedVehicle", void 0);
__decorate([
    Type(() => VehicleForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "assignedVehicle", void 0);
__decorate([
    Type(() => DispatchAreaForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "dispatchArea", void 0);
__decorate([
    Type(() => UserForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "manager", void 0);
__decorate([
    Type(() => UserForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "returnManager", void 0);
__decorate([
    Type(() => UserForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "releaseManager", void 0);
__decorate([
    Type(() => UserForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "cancelManager", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationForComparisonDto.prototype, "pickUpAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationForComparisonDto.prototype, "dropOffAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "expectedDepositDate", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "canceledAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "departureDate", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "returnDate", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "flightDepartedAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "releasePreparedAt", void 0);
__decorate([
    Type(() => ReservationPersonForComparisonDto),
    __metadata("design:type", ReservationPersonForComparisonDto)
], ReservationForComparisonDto.prototype, "contractor", void 0);
__decorate([
    Type(() => ReservationPersonForComparisonDto),
    __metadata("design:type", ReservationPersonForComparisonDto)
], ReservationForComparisonDto.prototype, "driver", void 0);
__decorate([
    Type(() => ReservationExtraItemForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "extraItems", void 0);
__decorate([
    Type(() => ReservationConvenienceForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "conveniences", void 0);
__decorate([
    Type(() => ReservationExtensionForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "extensions", void 0);
__decorate([
    Type(() => ReservationInsuranceForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "insurances", void 0);
__decorate([
    Type(() => ReservationPaymentForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "payments", void 0);
__decorate([
    Type(() => ReservationConsultationForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "consultations", void 0);
__decorate([
    Type(() => ReservationChecklistItemForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "checklistItems", void 0);
__decorate([
    Type(() => ReservationMessagingRecordForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "messagingRecords", void 0);
__decorate([
    Type(() => ReservationManagerApprovalForComparisonDto),
    __metadata("design:type", Object)
], ReservationForComparisonDto.prototype, "managerApprovals", void 0);
