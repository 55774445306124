import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#33ca77',
      light: '#36d67e',
      dark: '#228a51',
      contrastText: '#fff',
    },
    text: {
      primary: '#333333',
      secondary: '#7E7E7E',
      disabled: '#7E7E7E',
    },
    grey: {
      '50': '#ECECEC',
      '100': '#E0E0E0',
      '200': '#D4D4D4',
      '300': '#C4C4C4',
      '400': '#A6A6A6',
      '500': '#7E7E7E',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
  },
  typography: {
    fontFamily: [
      'Pretendard Variable',
      'Pretendard',
      '-apple-system',
      'BlinkMacSystemFont',
      'system-ui',
      'Roboto',
      'Helvetica Neue',
      'Segoe UI',
      'Apple SD Gothic Neo',
      'Noto Sans KR',
      'Malgun Gothic',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'sans-serif',
    ].join(','),
    fontSize: 16,
    fontWeightBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          paddingTop: '26px',
          paddingBottom: '26px',
          borderRadius: 0,
          boxShadow: 'none',
          '&.Mui-disabled': {
            opacity: 0.4,
          },
          ':hover': {
            boxShadow: 'none',
          },
          ...(ownerState?.color === 'secondary' && {
            color: '#4A4A4A',
            backgroundColor: '#E0E0E0',
            ':hover': { boxShadow: 'none', backgroundColor: '#D4D4D4' },
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: 0,
            backgroundColor: '#ffffff',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: false,
      },
      styleOverrides: {
        paper: {
          borderRadius: 0,
          boxShadow: 'none',
          width: '100%',
          marginLeft: '120px',
          marginRight: '120px',
          padding: '40px',
          paddingTop: '52px',
          textAlign: 'center',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 'normal',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontSize: 32,
            marginLeft: '8px',
          },
          '& .MuiRadio-root': {
            width: 48,
            height: 48,
            padding: 0,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            height: '100%',
            paddingLeft: '40px',
            paddingRight: '40px',
            paddingTop: '36px',
            paddingBottom: '36px',
            '& .MuiInputBase-input': {
              padding: 0,
              lineHeight: 'normal',
            },
          },
          '& .MuiOutlinedInput-root': {
            lineHeight: 'normal',
            '& fieldset': {
              borderColor: '#D4D4D4',
            },
            '&:hover fieldset': {
              borderColor: '#D4D4D4',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#D4D4D4',
            },
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      },
    },
  },
});

export default theme;
