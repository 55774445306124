var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsArray, IsBoolean, IsEnum, IsNumber, IsString, ValidateNested, } from 'class-validator';
import { CreateVisibilityMapDto } from '../../../classes/common';
import { IsNullable } from '../../../decorators';
import { ExtraItemCategory, ExtraItemFeeCriterion } from '../../../enums';
export class CreateExtraItemDto {
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateExtraItemDto.prototype, "name", void 0);
__decorate([
    IsEnum(ExtraItemCategory),
    __metadata("design:type", String)
], CreateExtraItemDto.prototype, "category", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateExtraItemDto.prototype, "fee", void 0);
__decorate([
    IsEnum(ExtraItemFeeCriterion),
    __metadata("design:type", String)
], CreateExtraItemDto.prototype, "feeCriterion", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreateExtraItemDto.prototype, "exposure", void 0);
__decorate([
    ValidateNested(),
    Type(() => CreateVisibilityMapDto),
    __metadata("design:type", CreateVisibilityMapDto)
], CreateExtraItemDto.prototype, "visibility", void 0);
__decorate([
    IsNumber(),
    IsNullable(),
    __metadata("design:type", Object)
], CreateExtraItemDto.prototype, "minReservationPeriod", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateExtraItemDto.prototype, "detail", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateExtraItemDto.prototype, "global", void 0);
__decorate([
    IsArray(),
    IsNumber({}, { each: true }),
    __metadata("design:type", Array)
], CreateExtraItemDto.prototype, "vehicleModelIds", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateExtraItemDto.prototype, "availableWithInsuranceOption1", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateExtraItemDto.prototype, "availableWithInsuranceOption2", void 0);
