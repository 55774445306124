var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BankCardDto } from '../../../classes/bank-card/response/bank-card.dto';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { ReservationPaymentWithoutSettlementDto } from '../../../classes/reservation-payment/response/reservation-payment-without-settlement.dto';
import { UserWithoutRelationsDto } from '../../../classes/user';
import { VendorWithoutRelationsDto } from '../../../classes/vendor';
import { OmitType } from '../../../utils';
class SettlementDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.settledAt = initializer.settledAt;
        this.transactionPrice = initializer.transactionPrice;
        this.state = initializer.state;
        this.startDate = initializer.startDate;
        this.endDate = initializer.endDate;
        this.memo = initializer.memo;
        this.vendor = initializer.vendor;
        this.vendorId = initializer.vendorId;
        this.bankCard = initializer.bankCard;
        this.bankCardId = initializer.bankCardId;
        this.payments = initializer.payments;
        this.manager = initializer.manager;
        this.managerId = initializer.managerId;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], SettlementDto.prototype, "settledAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], SettlementDto.prototype, "startDate", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], SettlementDto.prototype, "endDate", void 0);
__decorate([
    Type(() => VendorWithoutRelationsDto),
    __metadata("design:type", VendorWithoutRelationsDto)
], SettlementDto.prototype, "vendor", void 0);
__decorate([
    Type(() => BankCardDto),
    __metadata("design:type", BankCardDto)
], SettlementDto.prototype, "bankCard", void 0);
__decorate([
    Type(() => ReservationPaymentWithoutSettlementDto),
    __metadata("design:type", Array)
], SettlementDto.prototype, "payments", void 0);
__decorate([
    Type(() => UserWithoutRelationsDto),
    __metadata("design:type", UserWithoutRelationsDto)
], SettlementDto.prototype, "manager", void 0);
export class SettlementWithoutRelationsDto extends OmitType(SettlementDto, [
    'vendor',
    'bankCard',
    'payments',
    'manager',
]) {
    constructor(initializer) {
        super();
        this.id = initializer.id;
        this.createdAt = initializer.createdAt;
        this.updatedAt = initializer.updatedAt;
        this.deletedAt = initializer.deletedAt;
        this.settledAt = initializer.settledAt;
        this.transactionPrice = initializer.transactionPrice;
        this.state = initializer.state;
        this.startDate = initializer.startDate;
        this.endDate = initializer.endDate;
        this.memo = initializer.memo;
        this.vendorId = initializer.vendorId;
        this.bankCardId = initializer.bankCardId;
        this.managerId = initializer.managerId;
    }
}
