import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
export class PreallocationHistoryDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.userId = initializer.userId;
        this.username = initializer.username;
        this.taskSubtype = initializer.taskSubtype;
        this.description = initializer.description;
    }
}
