export class InsuranceDetailDto {
    constructor(initializer) {
        this.limit = initializer.limit;
        this.indemnification = initializer.indemnification;
        this.compensation = initializer.compensation;
        this.age = initializer.age;
        this.experience = initializer.experience;
        this.content = initializer.content;
    }
}
