var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { ReservationStatisticBranchInfoDto } from './statistic-branch-info.dto';
export class ReservationMonthlyStatisticByVehicleModelPerBranchDto {
    constructor({ vehicleModelId, vehicleModelName, vehicleModelState, vehicleModelType, reservationCount, vehicleCount, totalFee, vehicleFee, insuranceFee, extensionFee, extraItemFee, convenienceFee, branchInfo, }) {
        this.vehicleModelId = vehicleModelId;
        this.vehicleModelName = vehicleModelName;
        this.vehicleModelState = vehicleModelState;
        this.vehicleModelType = vehicleModelType;
        this.reservationCount = reservationCount;
        this.vehicleCount = vehicleCount;
        this.totalFee = totalFee;
        this.vehicleFee = vehicleFee;
        this.insuranceFee = insuranceFee;
        this.extensionFee = extensionFee;
        this.extraItemFee = extraItemFee;
        this.convenienceFee = convenienceFee;
        this.branchInfo = branchInfo;
    }
}
__decorate([
    Type(() => ReservationStatisticBranchInfoDto),
    __metadata("design:type", Array)
], ReservationMonthlyStatisticByVehicleModelPerBranchDto.prototype, "branchInfo", void 0);
