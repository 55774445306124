import {
  Box,
  Button,
  Dialog,
  Table,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material';
import {
  DriverLicenseType,
  formatDriverLicenseNumber,
  type Nullable,
  REGIONAL_CODE,
} from '@orcar/common';
import { chunk } from 'lodash';
import { useEffect, useState } from 'react';
import BackspaceIcon from '@/components/BackspaceIcon';
import Key from './Key';
import KeypadInput from './KeypadInput';

type DriverLicenseInfoKeypadProps = {
  defaultDriverLicenseNumber?: Nullable<string>;
  defaultDriverLicenseType?: Nullable<DriverLicenseType>;
  onConfirm: (licenseType: DriverLicenseType, licenseNumber: string) => void;
};

const LicenseTypeArray = {
  [DriverLicenseType.FIRST_LARGE]: '1종대형',
  [DriverLicenseType.FIRST_ORDINARY]: '1종보통',
  [DriverLicenseType.SECOND_ORDINARY]: '2종보통',
};

const NumberKeyPad = [
  ['1', '2', '3'],
  ['4', '5', '6'],
  ['7', '8', '9'],
  ['', '0', 'backspace'],
];

const DriverLicenseInfoKeypad = ({
  defaultDriverLicenseNumber,
  defaultDriverLicenseType,
  onConfirm,
}: DriverLicenseInfoKeypadProps) => {
  const [driverLicenseNumber, setDriverLicenseNumber] = useState('');
  const [driverLicenseType, setDriverLicenseType] =
    useState<DriverLicenseType | null>(null);

  const [viewNumber, setViewNumber] = useState<boolean>(false);

  const [dialogState, setDialogState] = useState({ open: false, message: '' });

  useEffect(() => {
    setDriverLicenseNumber(defaultDriverLicenseNumber ?? '');
    setDriverLicenseType(defaultDriverLicenseType ?? null);
  }, [defaultDriverLicenseNumber, defaultDriverLicenseType]);

  const handleConfirm = (
    licenseType: DriverLicenseType | null,
    licenseNumber: string,
  ) => {
    if (!licenseType) {
      setDialogState({
        open: true,
        message: '면허종류를 선택해주세요.',
      });
      return;
    } else if (licenseNumber.length < 12) {
      setDialogState({
        open: true,
        message: '면허번호를 확인해주세요.',
      });
      return;
    }
    onConfirm(licenseType, licenseNumber);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: 760,
        flexDirection: 'column',
        gap: 2.5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 100,
          gap: 2.5,
        }}
      >
        <Typography
          fontSize={40}
          fontWeight={600}
          sx={{
            color: 'grey.500',
          }}
        >
          면허종류
        </Typography>
        {Object.entries(LicenseTypeArray).map(([key, value], _) => (
          <Box
            key={_}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 267,
              height: '100%',
              bgcolor: 'white',
              border: `${driverLicenseType === key ? 3 : 1}px solid`,
              borderColor:
                driverLicenseType === key ? 'primary.main' : 'grey.200',
              fontSize: 40,
              color: driverLicenseType === key ? 'primary.main' : 'grey.500',
              fontWeight: driverLicenseType === key ? 800 : 400,
            }}
            onClick={() => setDriverLicenseType(key as DriverLicenseType)}
          >
            {value}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 100,
          gap: 2.5,
        }}
      >
        <Typography
          fontSize={40}
          fontWeight={600}
          sx={{
            color: 'grey.500',
          }}
        >
          면허번호
        </Typography>
        <KeypadInput
          sx={{ flexGrow: 1 }}
          value={formatDriverLicenseNumber(driverLicenseNumber)}
          onDelete={() => setDriverLicenseNumber('')}
          nonTable
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 2.5,
        }}
      >
        <Table sx={{ bgcolor: 'white', height: 400 }}>
          <TableBody>
            {chunk(
              Object.entries(REGIONAL_CODE).filter(
                ([key]) => key !== '경기남부' && key !== '경기북부',
              ),
              4,
            ).map((row, index) => (
              <TableRow key={index}>
                {row.map(([key, value], _) => (
                  <Key
                    colSpan={2}
                    key={_}
                    sx={{ width: 100 }}
                    onClick={() => setDriverLicenseNumber(value)}
                  >
                    {viewNumber ? value : key}
                  </Key>
                ))}
              </TableRow>
            ))}
            <TableRow>
              {!viewNumber && (
                <Key
                  colSpan={3}
                  onClick={() => setDriverLicenseNumber(REGIONAL_CODE.경기남부)}
                >
                  {viewNumber ? REGIONAL_CODE.경기남부 : '경기남부'}
                </Key>
              )}
              <Key
                colSpan={viewNumber ? 4 : 3}
                onClick={() => setDriverLicenseNumber(REGIONAL_CODE.경기북부)}
              >
                {viewNumber ? REGIONAL_CODE.경기북부 : '경기북부'}
              </Key>
              <Key
                colSpan={viewNumber ? 4 : 2}
                sx={{ width: 100, bgcolor: '#f5f5f5' }}
                onClick={() => setViewNumber((prev) => !prev)}
              >
                {viewNumber ? '지역명' : '숫자'}
              </Key>
            </TableRow>
          </TableBody>
        </Table>
        <Table sx={{ bgcolor: 'white' }}>
          <TableBody>
            {NumberKeyPad.map((row, _) => (
              <TableRow key={_}>
                {row.map((value, _) => (
                  <Key
                    key={_}
                    sx={{ width: 100, height: 100 }}
                    onClick={() => {
                      if (value === 'backspace') {
                        setDriverLicenseNumber(
                          driverLicenseNumber.slice(
                            0,
                            driverLicenseNumber.length - 1,
                          ),
                        );
                      } else if (driverLicenseNumber.length < 12) {
                        setDriverLicenseNumber(
                          driverLicenseNumber + value.toString(),
                        );
                      }
                    }}
                  >
                    {value === 'backspace' ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <BackspaceIcon />
                      </Box>
                    ) : (
                      value
                    )}
                  </Key>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Table sx={{ width: '100%', height: 100 }}>
        <TableBody>
          <TableRow>
            <Key
              sx={{
                width: 100,
                height: 100,
                bgcolor: 'primary.main',
                color: 'white',
                fontWeight: 'medium',
              }}
              colSpan={3}
              onClick={() =>
                handleConfirm(driverLicenseType, driverLicenseNumber)
              }
            >
              확인
            </Key>
          </TableRow>
        </TableBody>
      </Table>
      <Dialog open={dialogState.open}>
        <Box sx={{ width: '100%' }}>
          <Typography
            fontSize={32}
            fontWeight='regular'
            color='text.secondary'
            lineHeight='44px'
            sx={{ marginBottom: 5, whiteSpace: 'pre-line' }}
          >
            {dialogState.message}
          </Typography>
          <Button
            variant='contained'
            fullWidth
            sx={{ height: 100, fontSize: 40, fontWeight: 500 }}
            onClick={() => setDialogState((prev) => ({ ...prev, open: false }))}
          >
            닫기
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default DriverLicenseInfoKeypad;
