export var ReservationTaskSubtype;
(function (ReservationTaskSubtype) {
    ReservationTaskSubtype["CREATE"] = "CREATE";
    ReservationTaskSubtype["UPDATE"] = "UPDATE";
    ReservationTaskSubtype["PICK_UP"] = "PICK_UP";
    ReservationTaskSubtype["DROP_OFF"] = "DROP_OFF";
    ReservationTaskSubtype["SWAP"] = "SWAP";
    ReservationTaskSubtype["ALIGN"] = "ALIGN";
    ReservationTaskSubtype["CANCEL"] = "CANCEL";
})(ReservationTaskSubtype || (ReservationTaskSubtype = {}));
