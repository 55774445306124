var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../../classes/common/response/base-number-id-data.dto';
import { FeeWithoutRelationsDto } from '../../../classes/fee';
import { VendorWithoutRelationsDto } from '../../../classes/vendor/response/vendor-without-relations.dto';
export class FeeGradeDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.name = initializer.name;
        this.vendors = initializer.vendors;
        this.fees = initializer.fees;
    }
}
__decorate([
    Type(() => VendorWithoutRelationsDto),
    __metadata("design:type", Array)
], FeeGradeDto.prototype, "vendors", void 0);
__decorate([
    Type(() => FeeWithoutRelationsDto),
    __metadata("design:type", Array)
], FeeGradeDto.prototype, "fees", void 0);
