var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { VehicleWithoutRelationsDto } from '../../../classes/vehicle/response/vehicle-without-relations.dto';
import { VehicleModelWithoutRelationsDto } from '../../../classes/vehicle-model/response/vehicle-model-without-relations.dto';
// TODO: 필드 옵셔널처리 (추후 거래처 전용으로 분리)
export class ReservationSettlementDto {
    constructor(initializer) {
        this.reservationId = initializer.reservationId;
        this.customerName = initializer.customerName;
        this.vehicleModel = initializer.vehicleModel;
        this.reservedVehicle = initializer.reservedVehicle;
        this.createdAt = initializer.createdAt;
        this.pickUpAt = initializer.pickUpAt;
        this.dropOffAt = initializer.dropOffAt;
        this.usageHour = initializer.usageHour;
        this.state = initializer.state;
        this.listPrice = initializer.listPrice;
        this.finalPrice = initializer.finalPrice;
        this.insuranceFee = initializer.insuranceFee;
        this.extensionFee = initializer.extensionFee;
        this.extraFee = initializer.extraFee;
        this.totalFee = initializer.totalFee;
        this.totalPayment = initializer.totalPayment;
        this.totalRefund = initializer.totalRefund;
        this.customerArrear = initializer.customerArrear;
        this.vendorPayment = initializer.vendorPayment;
        this.vendorArrear = initializer.vendorArrear;
        this.memo = initializer.memo;
    }
}
__decorate([
    Type(() => VehicleModelWithoutRelationsDto),
    __metadata("design:type", VehicleModelWithoutRelationsDto)
], ReservationSettlementDto.prototype, "vehicleModel", void 0);
__decorate([
    Type(() => VehicleWithoutRelationsDto),
    __metadata("design:type", Object)
], ReservationSettlementDto.prototype, "reservedVehicle", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationSettlementDto.prototype, "createdAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationSettlementDto.prototype, "pickUpAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationSettlementDto.prototype, "dropOffAt", void 0);
