import { Box, Button, Dialog, Typography } from '@mui/material';

const ErrorDialog = ({
  open,
  message,
  onConfirm,
}: {
  open: boolean;
  message: string;
  onConfirm: () => void;
}) => {
  return (
    <Dialog open={open}>
      <Box sx={{ width: '100%' }}>
        <Typography
          fontSize={32}
          fontWeight='regular'
          color='text.secondary'
          lineHeight='44px'
          sx={{ marginBottom: 5, whiteSpace: 'pre-line' }}
        >
          {message}
        </Typography>
        <Button
          variant='contained'
          fullWidth
          sx={{ height: 100, fontSize: 40, fontWeight: 500 }}
          onClick={onConfirm}
        >
          확인
        </Button>
      </Box>
    </Dialog>
  );
};

export default ErrorDialog;
