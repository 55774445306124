export class Hourly24InsuranceFeeDto {
    static instanceOf(obj) {
        if (obj === null || obj === undefined) {
            return false;
        }
        return ('hour24' in obj &&
            typeof obj.hour24 === 'number' &&
            Object.keys(obj).length === 1);
    }
}
