export var PartnersContractStatus;
(function (PartnersContractStatus) {
    // 요청처리중: 여행사에서 계약 요청을 보낸 상태
    PartnersContractStatus["PENDING"] = "PENDING";
    // 정상: 계약이 정상적으로 처리된 상태
    PartnersContractStatus["ACTIVE"] = "ACTIVE";
    // 요정등록: 여행사의 계약 요청을 관리자가 승인했지만 렌트카 회사가 승인하지 않았거나, 승인된 상태에서 렌트카 회사가 계약을 일시정지한 상태
    PartnersContractStatus["INACTIVE"] = "INACTIVE";
    // 거래처명변경, 거래처삭제: 렌트카 회사가 거래처 설정을 변경하여 다시 계약을 요청해야 하는 상태
    PartnersContractStatus["EXPIRED"] = "EXPIRED";
})(PartnersContractStatus || (PartnersContractStatus = {}));
