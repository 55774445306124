export class VehicleModelAudioDto {
    constructor(initializer) {
        this.bluetooth = initializer.bluetooth;
        this.aux = initializer.aux;
        this.cd = initializer.cd;
        this.iPod = initializer.iPod;
        this.mp3 = initializer.mp3;
        this.usbA = initializer.usbA;
        this.usbC = initializer.usbC;
    }
}
