import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

const BackspaceIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{
        width: 36,
        height: 28,
        ...props.sx,
      }}
      viewBox='0 0 36 28'
      fill='none'
      {...props}
    >
      <path
        d='M12 28C11.2667 28 10.6 27.8083 10 27.425C9.4 27.0417 8.88333 26.55 8.45 25.95L0 14L8.45 2.05C8.88333 1.45 9.4 0.958333 10 0.575C10.6 0.191667 11.2667 0 12 0H33C33.825 0 34.5312 0.29375 35.1188 0.88125C35.7063 1.46875 36 2.175 36 3V25C36 25.825 35.7063 26.5312 35.1188 27.1187C34.5312 27.7062 33.825 28 33 28H12ZM11.45 25H33V3H11.45L3.75 14L11.45 25ZM16.4 21.7L22 16.1L27.6 21.7L29.75 19.55L24.1 14L29.65 8.45L27.5 6.3L22 11.9L16.4 6.3L14.25 8.45L19.9 14L14.25 19.55L16.4 21.7Z'
        fill='#7E7E7E'
      />
    </SvgIcon>
  );
};
export default BackspaceIcon;
