import { Box, Button, Dialog, Typography } from '@mui/material';
import {
  type CreateReservationPersonDto,
  type DriverLicenseType,
  getAge,
  getLimitConditions,
  type ReservationDto,
} from '@orcar/common';
import dayjs from 'dayjs';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import BottomButtons from '@/components/BottomButtons';
import ConfirmDialog from '@/components/ConfirmDialog';
import LoadingDialog from '@/components/LoadingDialog';
import TitleMessage from '@/components/TitleMessage';
import { DRIVER_LICENSE_VALIDATION_ERROR } from '@/constant/error.constant';
import {
  useGetReservation,
  useUpdateReservation,
} from '@/hooks/reservation.hook';
import KeypadWrapper, { type KeypadType } from './components/KeypadWrapper';
import ReservationPersonCard from './components/ReservationPersonCard';
import { type DriverLicenseImageForm } from './DriverLicenseInfoPage';

export type DriverLicenseForm = {
  name: string;
  phoneNumber: string;
  birthDate: string;
  driverLicenseType: DriverLicenseType | null;
  driverLicenseNumber: string;
  isBirthDateExtracted: boolean;
  isDriverLicenseNumberExtracted: boolean;
};

export type KeypadState = {
  open: boolean;
  type: KeypadType;
  editSingleType: boolean;
};

type ErrorDialogState = {
  open: boolean;
  message: string;
  type?: keyof typeof DRIVER_LICENSE_VALIDATION_ERROR;
};

type Props = {
  reservation: ReservationDto;
  driverNumber: 1 | 2;
};

const DriverInfo: FC<Props> = ({ reservation, driverNumber }) => {
  const navigate = useNavigate();
  const { state } = useLocation() as {
    state: {
      error: {
        type: keyof typeof DRIVER_LICENSE_VALIDATION_ERROR;
        message?: string;
      };
    };
  };

  const [openLoading, setOpenLoading] = useState(false);
  const [keypadState, setKeypadState] = useState<KeypadState>({
    open: false,
    type: 'name',
    editSingleType: false,
  });
  const [errorDialogState, setErrorDialogState] = useState<ErrorDialogState>({
    open: false,
    message: '',
  });
  const [confirmDialogState, setConfirmDialogState] = useState<{
    open: boolean;
    message: string;
  }>({ open: false, message: '' });

  const { mutateAsync: updateReservation } = useUpdateReservation();

  const person = useMemo(
    () => (driverNumber === 1 ? reservation.contractor : reservation.driver),
    [driverNumber, reservation],
  );

  const driverLicenseForm = useForm<DriverLicenseForm>();
  const driverLicenseImageForm = useForm<DriverLicenseImageForm>();
  const { reset, setError, setValue } = driverLicenseForm;

  useEffect(() => {
    reset({
      name: person.name || '',
      phoneNumber: person.phoneNumber || '',
      birthDate: person.birthDate
        ? dayjs(person.birthDate).format('YYYYMMDD')
        : '',
    });
  }, [driverNumber, person, reservation, reset]);

  useEffect(() => {
    setOpenLoading(false);
    setErrorDialogState({ open: false, message: '' });
    setKeypadState({ open: false, type: 'name', editSingleType: false });
  }, [driverNumber]);

  useEffect(() => {
    const { name, phoneNumber, birthDate } = driverLicenseForm.getValues();

    if (name === '' || phoneNumber === '' || birthDate === '') {
      setKeypadState({ open: true, type: 'name', editSingleType: false });
    }
  }, [driverLicenseForm]);

  useEffect(() => {
    if (state) {
      if (state.error) {
        const { type, message } = state.error;
        if (type === DRIVER_LICENSE_VALIDATION_ERROR.FAILED_TO_VERIFY_NAME) {
          setError('name', { message });
        } else if (
          type ===
          DRIVER_LICENSE_VALIDATION_ERROR.NOT_SATISFIED_REQUIRE_CONDITION
        ) {
          reset({
            name: '',
            phoneNumber: '',
            birthDate: '',
          });
          setKeypadState({ open: true, type: 'name', editSingleType: false });
        }
      }
    }
  }, [state]);

  const handleOpenError = (
    message: string,
    type?: keyof typeof DRIVER_LICENSE_VALIDATION_ERROR,
  ) => {
    setErrorDialogState({ open: true, message, type });
    return new Error(message);
  };

  const openLoadingModal = (ms: number) => {
    setOpenLoading(true);
    return new Promise<void>((resolve) =>
      setTimeout(() => {
        setOpenLoading(false);
        resolve();
      }, ms),
    );
  };

  const handleSubmit = useCallback(async (): Promise<void> => {
    const { name, birthDate, phoneNumber } = driverLicenseForm.getValues();
    const { age: ageLimit } = getLimitConditions(reservation);

    if (!name) {
      throw handleOpenError(
        '이름을 입력해주세요.',
        DRIVER_LICENSE_VALIDATION_ERROR.REQUIRE_TO_NAME,
      );
    } else if (birthDate.length !== 8) {
      throw handleOpenError(
        '생년월일을 입력해주세요.',
        DRIVER_LICENSE_VALIDATION_ERROR.REQUIRE_TO_BIRTH_DATE,
      );
    } else if (phoneNumber.length < 10) {
      throw handleOpenError(
        '연락처를 확인해주세요.',
        DRIVER_LICENSE_VALIDATION_ERROR.REQUIRE_TO_PHONE_NUMBER,
      );
    }

    if (
      name === person.name &&
      birthDate === dayjs(person.birthDate).format('YYYYMMDD') &&
      phoneNumber === person.phoneNumber
    ) {
      return;
    }

    await openLoadingModal(2000);

    try {
      if (getAge(birthDate, reservation.pickUpAt) < ageLimit) {
        throw handleOpenError(
          `나이 제한으로 인해 대여가 불가능합니다.
          다른 운전자 정보로 다시 작성해주세요.`,
          DRIVER_LICENSE_VALIDATION_ERROR.NOT_SATISFIED_REQUIRE_CONDITION,
        );
      }
      try {
        const input = {
          name,
          birthDate,
          phoneNumber,
        };
        await updateReservation({
          id: reservation.id,
          input: driverNumber === 1 ? { contractor: input } : { driver: input },
        });
      } catch (error) {
        throw handleOpenError(
          '운전자 정보 검증 중 오류가 발생했어요.\n다시 시도해주세요.',
        );
      }
    } finally {
      setOpenLoading(false);
    }
  }, [person, driverLicenseForm, reservation, updateReservation]);

  const afterSubmit = useCallback(() => {
    navigate(`/${reservation.id}/driver-license-info/${driverNumber}`, {
      replace: true,
    });
    setKeypadState({ open: false, type: 'name', editSingleType: false });
  }, [driverNumber, navigate, reservation]);

  const onSubmit = async () => {
    try {
      await handleSubmit();
      afterSubmit();
    } catch {}
  };

  const handleSetError = (
    type?: keyof typeof DRIVER_LICENSE_VALIDATION_ERROR,
  ) => {
    switch (type) {
      case DRIVER_LICENSE_VALIDATION_ERROR.REQUIRE_TO_NAME:
        setError('name', { message: '이름을 확인해주세요.' });
        break;
      case DRIVER_LICENSE_VALIDATION_ERROR.REQUIRE_TO_BIRTH_DATE:
        setError('birthDate', { message: '생년월일을 확인해주세요.' });
        break;
      case DRIVER_LICENSE_VALIDATION_ERROR.NOT_SATISFIED_REQUIRE_CONDITION:
        reset({
          name: '',
          phoneNumber: '',
          birthDate: '',
        });
        setKeypadState({ open: true, type: 'name', editSingleType: false });
        break;
      case DRIVER_LICENSE_VALIDATION_ERROR.REQUIRE_TO_PHONE_NUMBER:
        setError('phoneNumber', { message: '핸드폰번호를 확인해주세요.' });
        break;
      default:
        break;
    }
  };

  const handleConfirmKeypad = (type: KeypadType, value?: string) => {
    if (type === 'birthDate') {
      if (value) {
        setValue('birthDate', value);
        setKeypadState((prev) => ({ ...prev, open: false }));
        if (!keypadState.editSingleType) {
          onSubmit();
        }
      }
    } else if (value) {
      if (type === 'phoneNumber') {
        setValue(type, value);
        if (keypadState.editSingleType) {
          setKeypadState((prev) => ({ ...prev, open: false }));
        } else {
          setKeypadState((prev) => ({ ...prev, type: 'birthDate' }));
        }
      } else if (type === 'name') {
        setValue(type, value);
        if (keypadState.editSingleType) {
          setKeypadState((prev) => ({ ...prev, open: false }));
        } else {
          setKeypadState((prev) => ({ ...prev, type: 'phoneNumber' }));
        }
      }
    }
  };

  const removeDriverInfo = useCallback(async () => {
    try {
      const data: CreateReservationPersonDto = {
        name: null,
        birthDate: null,
        phoneNumber: null,
        telNumber: null,
        email: null,
        driverLicenseType: null,
        driverLicenseValidDate: null,
        driverLicenseNumber: null,
        address: null,
        driverLicenseImageUrl: null,
      };
      await updateReservation({
        id: reservation.id,
        input: { driver: data },
      });
    } catch (error) {
      throw handleOpenError(
        '제2 운전자 정보 삭제 중 오류가 발생했어요.\n다시 시도해주세요.',
      );
    }
  }, [updateReservation]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          background: '#f5f5f5',
          paddingTop: '100px',
          paddingX: '80px',
        }}
      >
        <TitleMessage
          message={`${
            driverNumber === 2 ? '제2 ' : ''
          }운전자 정보를 확인해 주세요.`}
        />
        <Box sx={{ height: '56px' }} />
        <ReservationPersonCard
          type='driverInfo'
          reservationId={reservation.id}
          driverNumber={driverNumber}
          person={person}
          driverLicenseForm={driverLicenseForm}
          driverLicenseImageForm={driverLicenseImageForm}
          setKeypadState={setKeypadState}
        />
      </Box>
      <BottomButtons
        label='다음'
        homeButton
        backButton
        onClickBack={() => {
          if (driverNumber === 1) {
            navigate(-1);
          } else if (driverNumber === 2) {
            setConfirmDialogState({
              open: true,
              message: `제2 운전자 등록을 취소하시겠어요?`,
            });
          }
        }}
        onClick={async () => {
          try {
            await handleSubmit();
            afterSubmit();
          } catch {}
        }}
      />

      <Dialog open={errorDialogState.open}>
        <Box sx={{ width: '100%' }}>
          <Typography
            fontSize={32}
            fontWeight='regular'
            color='text.secondary'
            lineHeight='44px'
            sx={{ marginBottom: 5, whiteSpace: 'pre-line' }}
          >
            {errorDialogState.message}
          </Typography>
          <Button
            variant='contained'
            fullWidth
            sx={{ height: 100, fontSize: 40, fontWeight: 500 }}
            onClick={() => {
              handleSetError(errorDialogState.type);
              setErrorDialogState({ open: false, message: '' });
            }}
          >
            닫기
          </Button>
        </Box>
      </Dialog>
      <ConfirmDialog
        open={confirmDialogState.open}
        title={confirmDialogState.message}
        confirmButtonLabel='네, 취소할게요'
        closeButtonLabel='아니요'
        onConfirm={async () => {
          try {
            await removeDriverInfo();
            const hasSignature = reservation.contractor.signatureUrl;

            if (hasSignature) {
              navigate(`/${reservation.id}/payment`, { replace: true });
            } else {
              navigate(`/${reservation.id}/contract-info`, { replace: true });
            }
          } catch {}
        }}
        onClose={() => setConfirmDialogState({ open: false, message: '' })}
        onClickOutside={() =>
          setConfirmDialogState((prev) => ({ ...prev, open: false }))
        }
      ></ConfirmDialog>
      <LoadingDialog
        open={openLoading}
        content='운전자 정보를 확인하고 있어요.'
      />
      <KeypadWrapper
        open={keypadState.open}
        type={keypadState.type}
        editSingleType={keypadState.editSingleType}
        driverNumber={driverNumber}
        driverLicenseForm={driverLicenseForm}
        setType={(type: KeypadType) =>
          setKeypadState((prev) => ({ ...prev, type }))
        }
        onClose={() => {
          setKeypadState((prev) => ({ ...prev, open: false }));
        }}
        onConfirm={handleConfirmKeypad}
      />
    </Box>
  );
};

const DriverInfoPage: FC = () => {
  const { id, driverNumber: driverNumberString } = useParams<{
    id: string;
    driverNumber: '1' | '2';
  }>();

  const { data: reservation, isLoading } = useGetReservation({ id });

  const driverNumber =
    driverNumberString === '1' ? 1 : driverNumberString === '2' ? 2 : null;

  if (isLoading) {
    return <></>;
  }

  if (!driverNumber || !reservation) {
    return <Navigate to='/' replace />;
  }

  return <DriverInfo reservation={reservation} driverNumber={driverNumber} />;
};

export default DriverInfoPage;
