import {
  type CreateReservationPaymentByKioskDto,
  type KioskPaymentDto,
} from '@orcar/common';
import axiosClient from './axiosClient';

export const confirmPayment = async (
  input: CreateReservationPaymentByKioskDto,
) => {
  const response = await axiosClient.post<KioskPaymentDto>(
    `/reservation-payments/kiosk`,
    input,
  );

  return response.data;
};
