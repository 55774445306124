import { ArrowBack, Close } from '@mui/icons-material';
import { Backdrop, Box, IconButton, Typography } from '@mui/material';
import { type DriverLicenseType } from '@orcar/common';
import { type FC, Fragment, useEffect } from 'react';
import { type UseFormReturn } from 'react-hook-form';
import BirthDateKeypad from './BirthDateKeypad';
import DriverLicenseInfoKeypad from './DriverLicenseInfoKeypad';
import PhoneNumberKeypad from './PhoneNumberKeypad';
import TextKeypad from './TextKeypad';
import { type DriverLicenseForm } from '../DriverLicenseInfoPage';

export type KeypadType = 'name' | 'phoneNumber' | 'birthDate' | 'licenseInfo';

type Props = {
  open: boolean;
  type: KeypadType | null;
  editSingleType: boolean;
  driverNumber: 1 | 2;
  setType: (type: KeypadType) => void;
  onClose: () => void;
  driverLicenseForm: UseFormReturn<DriverLicenseForm, unknown, undefined>;
  onConfirm: (
    type: KeypadType,
    value?: string,
    licenseInfo?: { licenseType: DriverLicenseType; licenseNumber: string },
  ) => void;
};

const KeypadWrapper: FC<Props> = ({
  open,
  type,
  editSingleType,
  driverNumber,
  setType,
  onClose,
  driverLicenseForm,
  onConfirm,
}) => {
  const { watch } = driverLicenseForm;

  const typeKorean =
    type === 'name'
      ? '이름(운전면허증 기준)'
      : type === 'phoneNumber'
      ? '연락처'
      : type === 'birthDate'
      ? '생년월일'
      : '면허정보';

  const isBackButtonVisible =
    !editSingleType && (type === 'phoneNumber' || type === 'birthDate');

  useEffect(() => {
    if (open) {
      window.scrollTo({
        top: 454,
        behavior: 'smooth',
      });
    }
  }, [open]);

  return (
    <Backdrop
      open={open}
      onClick={() => {
        onClose();
      }}
      sx={{ zIndex: 1, backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          position: 'fixed',
          borderBox: 'content',
          bottom: 0,
          bgcolor: 'grey.50',
          width: '100%',
          padding: '52px 40px 40px 40px',
          border: '1px solid grey.200',
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            {isBackButtonVisible && (
              <IconButton
                sx={{
                  width: 48,
                  height: 48,
                  marginTop: -1.5,
                }}
                onClick={() =>
                  setType(
                    type === 'phoneNumber'
                      ? 'name'
                      : type === 'birthDate'
                      ? 'phoneNumber'
                      : 'birthDate',
                  )
                }
              >
                <ArrowBack
                  sx={{
                    fontSize: 48,
                  }}
                />
              </IconButton>
            )}
            <Box
              sx={{
                flexGrow: 1,
                ...(type === 'name' && {
                  marginLeft: 6,
                }),
              }}
            />
            <Typography fontSize={40} fontWeight='bold'>
              제{driverNumber} 운전자의&nbsp;
            </Typography>
            <Typography fontSize={40} fontWeight='bold' color='primary.main'>
              {typeKorean}
            </Typography>
            <Typography fontSize={40} fontWeight='bold'>
              {type && ['name', 'birthDate'].includes(type) ? '을 ' : '를 '}
              입력해 주세요.
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              sx={{
                width: 48,
                height: 48,
                marginTop: -1.5,
              }}
              onClick={() => {
                onClose();
              }}
            >
              <Close
                sx={{
                  fontSize: 48,
                }}
              />
            </IconButton>
          </Box>

          {type === 'phoneNumber' ? (
            <Typography
              sx={{ width: '100%', paddingTop: 2 }}
              textAlign='center'
              fontSize={32}
              color='text.secondary'
            >
              연락처를 010부터 차례로 입력하고 확인을 눌러주세요.
            </Typography>
          ) : type === 'birthDate' ? (
            <Fragment>
              <Box
                sx={{
                  paddingTop: 2,
                  textAlign: 'center',
                }}
              >
                <Typography
                  textAlign='center'
                  fontSize={32}
                  color='text.secondary'
                  display='inline'
                >
                  생년월일{' '}
                </Typography>
                <Typography
                  textAlign='center'
                  fontSize={32}
                  fontWeight={600}
                  color='primary.main'
                  display='inline'
                >
                  6자리(ex. 911208)
                </Typography>
                <Typography
                  textAlign='center'
                  fontSize={32}
                  color='text.secondary'
                  display='inline'
                >
                  를 누르고, ‘확인’을 눌러주세요.
                </Typography>
              </Box>
            </Fragment>
          ) : null}
        </Box>
        {type === 'name' && open && (
          <TextKeypad
            defaultValue={watch('name')}
            onConfirm={(value) => onConfirm('name', value)}
          />
        )}
        {type === 'phoneNumber' && (
          <PhoneNumberKeypad
            defaultValue={watch('phoneNumber')}
            onConfirm={(value) => onConfirm('phoneNumber', value)}
          />
        )}
        {type === 'birthDate' && (
          <BirthDateKeypad
            defaultValue={watch('birthDate')}
            onConfirm={(value) => onConfirm('birthDate', value)}
          />
        )}
        {type === 'licenseInfo' && (
          <DriverLicenseInfoKeypad
            defaultDriverLicenseNumber={watch('driverLicenseNumber')}
            defaultDriverLicenseType={watch('driverLicenseType')}
            onConfirm={(licenseType, licenseNumber) =>
              onConfirm('licenseInfo', undefined, {
                licenseType,
                licenseNumber,
              })
            }
          />
        )}
      </Box>
    </Backdrop>
  );
};

export default KeypadWrapper;
