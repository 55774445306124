/* eslint-disable @typescript-eslint/ban-types */
import { defaultMetadataStorage } from 'class-transformer/cjs/storage';
import { getFromContainer, getMetadataStorage, IsOptional, MetadataStorage, } from 'class-validator';
export function applyIsOptionalDecorator(targetClass, propertyKey) {
    const decoratorFactory = IsOptional();
    decoratorFactory(targetClass.prototype, propertyKey);
}
export function inheritValidationMetadata(parentClass, targetClass, isPropertyInherited) {
    try {
        const metadataStorage = getMetadataStorage
            ? getMetadataStorage()
            : getFromContainer(MetadataStorage);
        const getTargetValidationMetadatasArgs = [parentClass, null, false, false];
        const targetMetadata = metadataStorage.getTargetValidationMetadatas(...getTargetValidationMetadatasArgs);
        return targetMetadata
            .filter(({ propertyName }) => !isPropertyInherited || isPropertyInherited(propertyName))
            .map((value) => {
            const originalType = Reflect.getMetadata('design:type', parentClass.prototype, value.propertyName);
            if (originalType) {
                Reflect.defineMetadata('design:type', originalType, targetClass.prototype, value.propertyName);
            }
            metadataStorage.addValidationMetadata(Object.assign(Object.assign({}, value), { target: targetClass }));
            return value.propertyName;
        });
    }
    catch (err) {
        console.error(`Validation ("class-validator") metadata cannot be inherited for "${parentClass.name}" class.`);
        console.error(err);
    }
}
export function inheritTransformationMetadata(parentClass, targetClass, isPropertyInherited) {
    try {
        const transformMetadataKeys = [
            '_excludeMetadatas',
            '_exposeMetadatas',
            '_transformMetadatas',
            '_typeMetadatas',
        ];
        transformMetadataKeys.forEach((key) => inheritTransformerMetadata(key, parentClass, targetClass, isPropertyInherited));
    }
    catch (err) {
        console.error(`Transformer ("class-transformer") metadata cannot be inherited for "${parentClass.name}" class.`);
        console.error(err);
    }
}
function inheritTransformerMetadata(key, parentClass, targetClass, isPropertyInherited) {
    // const classTransformer = require('class-transformer/cjs/storage');
    const metadataStorage /*: typeof import('class-transformer/types/storage').defaultMetadataStorage */ = defaultMetadataStorage;
    while (parentClass && parentClass !== Object) {
        if (metadataStorage[key].has(parentClass)) {
            const metadataMap = metadataStorage[key];
            const parentMetadata = metadataMap.get(parentClass);
            const targetMetadataEntries = Array.from(parentMetadata.entries())
                .filter(([key]) => !isPropertyInherited || isPropertyInherited(key))
                .map(([key, metadata]) => {
                if (Array.isArray(metadata)) {
                    // "_transformMetadatas" is an array of elements
                    const targetMetadata = metadata.map((item) => (Object.assign(Object.assign({}, item), { target: targetClass })));
                    return [key, targetMetadata];
                }
                return [key, Object.assign(Object.assign({}, metadata), { target: targetClass })];
            });
            if (metadataMap.has(targetClass)) {
                const existingRules = metadataMap.get(targetClass).entries();
                metadataMap.set(targetClass, new Map([...existingRules, ...targetMetadataEntries]));
            }
            else {
                metadataMap.set(targetClass, new Map(targetMetadataEntries));
            }
        }
        parentClass = Object.getPrototypeOf(parentClass);
    }
}
export function inheritPropertyInitializers(target, sourceClass, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
isPropertyInherited = (key) => true) {
    try {
        const tempInstance = new sourceClass();
        const propertyNames = Object.getOwnPropertyNames(tempInstance);
        propertyNames
            .filter((propertyName) => typeof tempInstance[propertyName] !== 'undefined' &&
            typeof target[propertyName] === 'undefined')
            .filter((propertyName) => isPropertyInherited(propertyName))
            .forEach((propertyName) => {
            target[propertyName] = tempInstance[propertyName];
        });
    }
    catch (_a) { }
}
