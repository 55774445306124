import dayjs from 'dayjs';
export const formatDriverLicenseNumber = (value) => {
    if (!value)
        return '';
    return value
        .replace(/^([\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uD7B0-\uD7FF\d]{2})(\d{0,2})(\d{0,6})(\d{0,2})/, '$1-$2-$3-$4')
        .replace(/-+$/g, '');
};
export const getDriverLicenseAcquisitionYear = (value) => {
    if (value.length !== 12) {
        return null;
    }
    const twoDigitYear = value.slice(2, 4);
    const acquisitionYear = twoDigitYear <= dayjs().format('YY')
        ? `20${twoDigitYear}`
        : `19${twoDigitYear}`;
    if (isNaN(Number(acquisitionYear))) {
        return null;
    }
    return Number(acquisitionYear);
};
