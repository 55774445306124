export var ProductType;
(function (ProductType) {
    ProductType["USER"] = "USER";
    ProductType["COMPANY"] = "COMPANY";
    ProductType["VENDOR"] = "VENDOR";
    ProductType["VEHICLE"] = "VEHICLE";
    ProductType["VEHICLE_MODEL"] = "VEHICLE_MODEL";
    ProductType["INSURANCE"] = "INSURANCE";
    ProductType["FEE"] = "FEE";
})(ProductType || (ProductType = {}));
