var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseNumberIdDataDto } from '../../common';
import { ReservationForComparisonDto } from '../../reservation-history';
export class ManagerHistoryDto extends BaseNumberIdDataDto {
    constructor({ id, createdAt, updatedAt, deletedAt, userId, source, nextData, }) {
        super({ id, createdAt, updatedAt, deletedAt });
        this.source = source;
        this.userId = userId;
        this.nextData = nextData;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ManagerHistoryDto.prototype, "createdAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ManagerHistoryDto.prototype, "updatedAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ManagerHistoryDto.prototype, "deletedAt", void 0);
__decorate([
    Type(() => ReservationForComparisonDto),
    __metadata("design:type", ReservationForComparisonDto)
], ManagerHistoryDto.prototype, "nextData", void 0);
