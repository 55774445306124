import { DriverLicenseType, ExtraItemCategory, FuelRateUnit, ReservationPaymentType, ReservationState, } from '../enums';
import { getPropSum } from './array.util';
import { translateExtraItemCategory } from './translation.util';
import { toDate } from './type-validator.util';
export const getReservationStateColor = (state) => {
    switch (state) {
        case ReservationState.IN_USE:
            return '#BBDEFB';
        case ReservationState.PENDING:
            return '#FAFAFA';
        case ReservationState.IN_PROGRESS:
            return '#FFF9C4';
        case ReservationState.CONFIRMED:
            return '#FFE0B2';
        case ReservationState.RETURNED:
            return '#C8E6C9';
        case ReservationState.CLOSED:
            return '#FFCDD2';
        case ReservationState.CANCELED:
            return '#E0E0E0';
        default:
            return '#ffffff';
    }
};
export const getChartReservationStateColor = (state) => {
    switch (state) {
        case ReservationState.IN_USE:
            return 'rgba(187, 222, 251, 0.80)';
        case ReservationState.PENDING:
            return 'rgba(250, 250, 250, 0.80)';
        case ReservationState.IN_PROGRESS:
            return 'rgba(255, 245, 157, 0.80)';
        case ReservationState.CONFIRMED:
            return 'rgba(255, 204, 128, 0.80)';
        case ReservationState.RETURNED:
            return 'rgba(224, 224, 224, 0.80)';
        case ReservationState.CLOSED:
            return 'rgba(255, 205, 210, 0.80)';
        case ReservationState.CANCELED:
            return 'rgba(189, 189, 189, 0.80)';
        default:
            return '#ffffff';
    }
};
export const getOnlineContractReservationStateColor = (state) => {
    switch (state) {
        case ReservationState.IN_USE:
            return '#D7EEFF';
        case ReservationState.PENDING:
            return '#ffffff';
        case ReservationState.IN_PROGRESS:
            return '#FEF9D1';
        case ReservationState.CONFIRMED:
            return '#FFE7D1';
        case ReservationState.RETURNED:
            return '#E7F8D4';
        case ReservationState.CLOSED:
            return '#FFEBEE';
        case ReservationState.CANCELED:
            return '#E0E0E0';
        default:
            return '#ffffff';
    }
};
export const getReservationDirectPayments = (reservation) => {
    var _a;
    const vehicle = {
        amount: (_a = reservation.directPaymentPrice) !== null && _a !== void 0 ? _a : 0,
        category: '차량료',
    };
    const extraItems = reservation.extraItems.map((item) => {
        var _a;
        return ({
            amount: (_a = item.directPaymentPrice) !== null && _a !== void 0 ? _a : 0,
            category: translateExtraItemCategory(item.extraItem.category),
        });
    });
    const conveniences = reservation.conveniences.map((convenience) => {
        var _a;
        return ({
            amount: (_a = convenience.directPaymentPrice) !== null && _a !== void 0 ? _a : 0,
            category: '편의장비',
        });
    });
    const insurances = reservation.insurances.map((insurance) => {
        var _a;
        return ({
            amount: (_a = insurance.directPaymentPrice) !== null && _a !== void 0 ? _a : 0,
            category: '자차료',
        });
    });
    const extensions = reservation.extensions.map((extension) => {
        var _a;
        return ({
            amount: (_a = extension.directPaymentPrice) !== null && _a !== void 0 ? _a : 0,
            category: '연장료',
        });
    });
    const prepayments = reservation.payments
        .filter((payment) => [
        ReservationPaymentType.PAYMENT_CUSTOMER,
        ReservationPaymentType.REFUND,
    ].includes(payment.type))
        .map((payment) => ({
        amount: payment.type === ReservationPaymentType.REFUND
            ? -payment.fee
            : payment.fee,
        category: payment.category,
    }));
    return {
        payments: [
            vehicle,
            ...extraItems,
            ...conveniences,
            ...insurances,
            ...extensions,
        ],
        prepayments,
        vehicleFee: vehicle.amount,
        extraFee: [...extraItems, ...conveniences].reduce((acc, { amount }) => acc + amount, 0),
        insuranceFee: insurances.reduce((acc, { amount }) => acc + amount, 0),
        extensionFee: extensions.reduce((acc, { amount }) => acc + amount, 0),
        prepaymentFee: prepayments.reduce((acc, { amount }) => acc + amount, 0),
    };
};
export function getFuelInfoString(fuelRate, unit) {
    return `${fuelRate}${unit == FuelRateUnit.PERCENTAGE ? '%' : '칸'}`;
}
export function translateInsuranceOption(option) {
    switch (option) {
        case 0:
            return '선택안함';
        case 1:
            return '일반자차';
        case 2:
            return '고급자차';
    }
}
export function hasInsurance(reservation) {
    return ((reservation === null || reservation === void 0 ? void 0 : reservation.insuranceOption) !== 0 || (reservation === null || reservation === void 0 ? void 0 : reservation.insurances.length) > 0);
}
export function getInsuranceOptionText(reservation) {
    if (reservation.insuranceOption !== 1 && reservation.insuranceOption !== 2)
        return '';
    const vehicleModel = reservation.vehicleModel;
    const insuranceOption = vehicleModel[`insurance${reservation.insuranceOption}`];
    if (!insuranceOption)
        return '';
    let insuranceOptionText = '';
    if (insuranceOption.limit)
        insuranceOptionText += `보상한도: ${insuranceOption.limit}`;
    if (insuranceOption.indemnification) {
        insuranceOptionText +=
            (insuranceOptionText ? ' / ' : '') +
                `면책금: ${insuranceOption.indemnification} `;
    }
    if (insuranceOption === null || insuranceOption === void 0 ? void 0 : insuranceOption.compensation) {
        insuranceOptionText +=
            (insuranceOptionText ? ' / ' : '') +
                `휴차보상료: ${insuranceOption.compensation} `;
    }
    if (insuranceOption.age || insuranceOption.experience) {
        insuranceOptionText += (insuranceOptionText ? ' / ' : '') + `가입제한:`;
        if (insuranceOption.age)
            insuranceOptionText += ` 만 ${insuranceOption.age}세`;
        if (insuranceOption.age && !insuranceOption.experience)
            insuranceOptionText += ' 이상';
        if (insuranceOption.age && insuranceOption.experience)
            insuranceOptionText += ', ';
        if (insuranceOption.experience)
            insuranceOptionText += ` 운전경력 ${insuranceOption.experience}년 이상`;
    }
    if (insuranceOption.content) {
        insuranceOptionText +=
            (insuranceOptionText ? ' / ' : '') + `${insuranceOption.content} `;
    }
    return insuranceOptionText;
}
export function getInsuranceTextList(reservation) {
    const { insurances, vendor } = reservation;
    const insuranceGrade = vendor.insuranceGrade;
    const insuranceTextList = insurances.map(({ insurance }) => {
        var _a;
        const detail = insurance[`detail${insuranceGrade}`];
        let insuranceText = '';
        if (insurance.name)
            insuranceText += `${insurance.name} `;
        if (detail.limit)
            insuranceText += `보상한도: ${detail.limit}`;
        if (detail.indemnification) {
            insuranceText +=
                (insuranceText ? ' / ' : '') + `면책금: ${detail.indemnification} `;
        }
        if (detail.compensation) {
            insuranceText +=
                (insuranceText ? ' / ' : '') + `휴차보상료: ${detail.compensation} `;
        }
        if (detail.age || detail.experience) {
            insuranceText += (insuranceText ? ' / ' : '') + `가입제한:`;
            if (detail.age)
                insuranceText += ` 만 ${(_a = insurance === null || insurance === void 0 ? void 0 : insurance.detailA) === null || _a === void 0 ? void 0 : _a.age}세`;
            if (detail.experience)
                insuranceText += ` 운전경력 ${detail.experience}년 이상`;
        }
        if (detail.content) {
            insuranceText += (insuranceText ? ' / ' : '') + `${detail.content} `;
        }
        return {
            insuranceId: insurance.id,
            text: insuranceText,
        };
    });
    return insuranceTextList;
}
export function getFeeList(reservation) {
    var _a;
    // 차량료
    const vehicleFee = (_a = reservation.finalPrice) !== null && _a !== void 0 ? _a : 0;
    // 자차료
    const insuranceFee = getPropSum(reservation.insurances, 'fee');
    // 연장료
    const extensionFee = getPropSum(reservation.extensions, 'fee');
    // 기타요금: 편의상품
    const convenienceFee = getPropSum(reservation.conveniences, 'fee');
    // 기타요금: 기타옵션
    const extraItemFee = getPropSum(reservation.extraItems, 'fee');
    // 요금합계
    const totalFee = vehicleFee + insuranceFee + extensionFee + convenienceFee + extraItemFee;
    return {
        vehicleFee,
        insuranceFee,
        extensionFee,
        convenienceFee,
        extraItemFee,
        totalFee,
    };
}
export const PAYMENT_LIST = {
    VEHICLE: 'vehicleDirectPayment',
    INSURANCE: 'insuranceDirectPayment',
    EXTENSION: 'extensionDirectPayment',
    SPECIAL_CONTRACT: 'specialContractDirectPayment',
    CONVENIENCE: 'convenienceDirectPayment',
    EXTRA_ITEM: 'extraItemDirectPayment',
    CUSTOM: 'customDirectPayment',
};
export function getPaymentList(reservation) {
    var _a, _b;
    const { totalFee } = getFeeList(reservation);
    const payments = (_a = reservation.payments) !== null && _a !== void 0 ? _a : [];
    const paymentsCustomer = payments.filter((payment) => payment.type === ReservationPaymentType.PAYMENT_CUSTOMER);
    const paymentsVendor = payments.filter((payment) => payment.type === ReservationPaymentType.PAYMENT_VENDOR);
    const refunds = payments.filter((payment) => payment.type === ReservationPaymentType.REFUND);
    // 항목별 이전 납입금
    const paidVehicleDirectPayment = getPropSum(payments.filter((item) => item.category === '차량료'), 'fee');
    const paidInsuranceDirectPayment = getPropSum(payments.filter((item) => item.category === '자차료'), 'fee');
    const paidExtensionDirectPayment = getPropSum(payments.filter((item) => item.category === '연장료'), 'fee');
    const paidConvenienceDirectPayment = getPropSum(payments.filter((item) => item.category === '편의장비'), 'fee');
    const paidContractDirectPayment = getPropSum(payments.filter((item) => item.category === '자차특약'), 'fee');
    const paidExtraDirectPayment = getPropSum(payments.filter((item) => item.category === '기타상품'), 'fee');
    // 항목별 직불금
    const vehicleDirectPayment = (_b = reservation.directPaymentPrice) !== null && _b !== void 0 ? _b : 0;
    const insuranceDirectPayment = getPropSum(reservation.insurances, 'directPaymentPrice');
    const specialContractDirectPayment = getPropSum(reservation.extraItems.filter((item) => item.extraItem.category === ExtraItemCategory.SPECIAL_CONTRACT), 'directPaymentPrice');
    const extensionDirectPayment = getPropSum(reservation.extensions, 'directPaymentPrice');
    const convenienceDirectPayment = getPropSum(reservation.conveniences, 'directPaymentPrice');
    const extraItemDirectPayment = getPropSum(reservation.extraItems.filter((item) => item.extraItem.category !== ExtraItemCategory.SPECIAL_CONTRACT), 'directPaymentPrice');
    //직불금 합계
    const totalDirectPayment = vehicleDirectPayment +
        insuranceDirectPayment +
        specialContractDirectPayment +
        extensionDirectPayment +
        convenienceDirectPayment +
        extraItemDirectPayment;
    // 환불금
    const refund = getPropSum(refunds, 'fee');
    // 고객 납입금
    const feeCustomerPaid = getPropSum(paymentsCustomer, 'fee');
    // 거래처 납입금
    const feeVendorPaid = getPropSum(paymentsVendor, 'fee');
    // 납입합
    const feePaid = feeCustomerPaid + feeVendorPaid;
    // 고객 미납금
    const feeCustomerNotPaid = totalDirectPayment + refund - feeCustomerPaid;
    // 거래처 미납금
    const feeVendorNotPaid = totalFee - totalDirectPayment - feeVendorPaid;
    // 미납합계
    const feeNotPaid = totalFee + refund - feePaid;
    return {
        vehicleDirectPayment,
        insuranceDirectPayment,
        specialContractDirectPayment,
        extensionDirectPayment,
        convenienceDirectPayment,
        extraItemDirectPayment,
        totalDirectPayment,
        refund,
        feeCustomerPaid,
        feeVendorPaid,
        feePaid,
        feeCustomerNotPaid,
        feeVendorNotPaid,
        feeNotPaid,
        paidVehicleDirectPayment,
        paidContractDirectPayment,
        paidConvenienceDirectPayment,
        paidExtensionDirectPayment,
        paidExtraDirectPayment,
        paidInsuranceDirectPayment,
    };
}
export const getActualDropOffAt = (reservation) => {
    const { dropOffAt, extensions, returnDate, state } = reservation;
    if ([ReservationState.RETURNED, ReservationState.CLOSED].includes(state) &&
        returnDate)
        return toDate(returnDate);
    if (extensions.length === 0)
        return toDate(dropOffAt);
    // find extension which have max endDate
    const maxEndDate = Math.max(toDate(dropOffAt).getTime(), ...extensions.map((extension) => toDate(extension.endDate).getTime()));
    return new Date(maxEndDate);
};
/**
 * @description 예약에 등록 가능한 운전자 조건을 반환합니다.
 * @param {ReservationDto} reservation 예약
 * @returns {{ age: number; experience: number; type: DriverLicenseType[]; }} { age: number; experience: number; type: DriverLicenseType[]; } 운전자 조건 (최소 나이, 최소 운전경력, 가능 운전면허 종류)
 */
export const getLimitConditions = (reservation) => {
    var _a, _b, _c;
    const { vendor, vehicleModel, insurances } = reservation;
    const vehicleModelAgeLimit = vehicleModel.driverAge;
    const vehicleModelExperienceLimit = vehicleModel.driverExperience;
    const vehicleModelSeat = (_a = vehicleModel.seat) !== null && _a !== void 0 ? _a : 0;
    const insuranceOption = reservation.insuranceOption === 1 || reservation.insuranceOption === 2
        ? vehicleModel[`insurance${reservation.insuranceOption}`]
        : null;
    const insuranceGrade = vendor.insuranceGrade;
    const insuranceAgeLimit = Math.max(...insurances.map((insurance) => {
        var _a;
        const detail = insurance.insurance[`detail${insuranceGrade}`];
        return (_a = detail.age) !== null && _a !== void 0 ? _a : 0;
    }));
    const insuranceExperienceLimit = Math.max(...insurances.map((insurance) => {
        var _a;
        const detail = insurance.insurance[`detail${insuranceGrade}`];
        return (_a = detail.experience) !== null && _a !== void 0 ? _a : 0;
    }));
    const types = [DriverLicenseType.FIRST_LARGE];
    if (vehicleModelSeat <= 15) {
        types.push(DriverLicenseType.FIRST_ORDINARY);
    }
    if (vehicleModelSeat <= 10) {
        types.push(DriverLicenseType.FIRST_LARGE_TRAILER);
        types.push(DriverLicenseType.FIRST_SMALL_TRAILER);
        types.push(DriverLicenseType.FIRST_WRECKER);
        types.push(DriverLicenseType.SECOND_ORDINARY);
    }
    return {
        age: Math.max(vehicleModelAgeLimit !== null && vehicleModelAgeLimit !== void 0 ? vehicleModelAgeLimit : 0, insuranceAgeLimit, (_b = insuranceOption === null || insuranceOption === void 0 ? void 0 : insuranceOption.age) !== null && _b !== void 0 ? _b : 0, 18),
        experience: Math.max(vehicleModelExperienceLimit !== null && vehicleModelExperienceLimit !== void 0 ? vehicleModelExperienceLimit : 0, insuranceExperienceLimit, (_c = insuranceOption === null || insuranceOption === void 0 ? void 0 : insuranceOption.experience) !== null && _c !== void 0 ? _c : 0, 0),
        licenseTypes: types,
    };
};
