import { Box, Divider, Typography } from '@mui/material';
import { ContractSectionType } from '@orcar/common';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import stringWidth from 'string-width';
import BottomButtons from '@/components/BottomButtons';
import LoadingDialog from '@/components/LoadingDialog';
import { ChipButton } from '@/components/StyledMuiComponent';
import TitleMessage from '@/components/TitleMessage';
import Viewer from '@/components/Viewer';
import { useGetContractSections } from '@/hooks/contract-section.hook';
import {
  useGetReservation,
  useUploadSignatureImageAndUpdateUrl,
} from '@/hooks/reservation.hook';
import AutographCanvas from './components/AutographCanvas';

const ContractAutographPage = () => {
  const navigate = useNavigate();

  const id = useParams().id;
  const driverNumber = useParams().driverNumber;

  const [isDrawn, setIsDrawn] = useState(false);

  const { data: reservation, isLoading } = useGetReservation({
    id,
  });
  const { data: privacyAgreementData } = useGetContractSections({
    params: {
      type: ContractSectionType.PRIVACY_AGREEMENT,
    },
  });
  const { data: finalAgreementData } = useGetContractSections({
    params: {
      type: ContractSectionType.FINAL_AGREEMENT,
    },
  });

  const { mutateAsync: uploadAndUpdateUrl, isPending: isUploadLoading } =
    useUploadSignatureImageAndUpdateUrl();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const getBlob = () => {
    return new Promise<Blob>((resolve, reject) => {
      const canvas = canvasRef.current;

      if (!canvas) {
        return reject();
      }

      const saveCanvas = document.createElement('canvas');

      saveCanvas.width = canvas.width;
      saveCanvas.height = canvas.height;

      const context = saveCanvas.getContext('2d');

      if (context) {
        context.fillStyle = 'white';
        context.fillRect(0, 0, saveCanvas.width, saveCanvas.height);
        context.drawImage(canvas, 0, 0);
      }

      saveCanvas.toBlob(async (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject();
        }
      }, 'image/jpeg');
    });
  };

  useEffect(() => {
    const imageUrl =
      driverNumber === '1'
        ? reservation?.contractor.signatureUrl
        : driverNumber === '2'
        ? reservation?.driver.signatureUrl
        : driverNumber === 'privacy'
        ? reservation?.privacyAgreementSignatureUrl
        : undefined;

    if (imageUrl) {
      const canvas = canvasRef.current;
      const context = canvas?.getContext('2d');

      if (canvas && context) {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = imageUrl;
        image.onload = () => {
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          setIsDrawn(true);
        };
      }
    } else {
      const canvas = canvasRef.current;
      const context = canvas?.getContext('2d');

      if (canvas && context) {
        context.clearRect(0, 0, canvas.width, canvas.height);
        setIsDrawn(false);
      }
    }
  }, [
    driverNumber,
    reservation?.contractor.signatureUrl,
    reservation?.driver.signatureUrl,
    reservation?.privacyAgreementSignatureUrl,
  ]);

  const defaultText = useMemo(() => {
    if (!reservation) {
      return null;
    }

    if (driverNumber === '2') {
      return reservation.driver.name;
    } else {
      return reservation.contractor.name;
    }
  }, [reservation, driverNumber]);

  const fontSize = useMemo(() => {
    if (canvasRef.current && defaultText) {
      const currentFontSize =
        canvasRef.current.offsetWidth / stringWidth(defaultText);
      return Math.max(Math.min(currentFontSize / 0.8, 200), 80);
    }

    return 180;
  }, [defaultText]);

  const spacing = useMemo(() => {
    if (defaultText) {
      return Math.max(0.56 - defaultText.length * 0.04, 0) + 'em';
    }

    return '0.44em';
  }, [defaultText]);

  if (isLoading) {
    return <></>;
  }

  if (
    !reservation ||
    (driverNumber !== 'privacy' && driverNumber !== '1' && driverNumber !== '2')
  ) {
    return <Navigate to='/' replace />;
  }

  const handleNext = async (id: string) => {
    const blob = await getBlob();

    await uploadAndUpdateUrl({
      id,
      input: {
        imageBlob: blob,
        driverNumber,
      },
    });

    switch (driverNumber) {
      case '1':
        if (
          reservation.driver.name &&
          reservation.driver.driverLicenseType &&
          reservation.driver.driverLicenseNumber
        ) {
          navigate(`/${id}/signature/2`);
        } else {
          navigate(`/${id}/payment`);
        }
        break;
      case '2':
        navigate(`/${id}/payment`);
        break;
      case 'privacy':
        navigate(`/${id}/signature/1`);
        break;
    }
  };

  const privacyAgreement =
    privacyAgreementData?.data?.find(
      (section) => section.branchId === reservation.reservedVehicle?.branchId,
    ) || privacyAgreementData?.data?.[0];

  const finalAgreement =
    finalAgreementData?.data?.find(
      (section) => section.branchId === reservation.reservedVehicle?.branchId,
    ) || finalAgreementData?.data?.[0];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        paddingBottom: '240px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          background: '#f5f5f5',
          paddingTop: '100px',
          paddingBottom: '80px',
          paddingX: '80px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TitleMessage
          message={['아래의 서명란에', '이름을 정자로 입력해 주세요.']}
        />
        <Box sx={{ height: '56px' }} />
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            padding: '40px',
            background: theme.palette.grey[50],
            border: '1px solid #d4d4d4',
            flexGrow: 1,
          })}
        >
          <Typography fontWeight='bold' fontSize={40}>
            {driverNumber === 'privacy'
              ? '개인정보 수집・이용 및 제3자 제공 동의'
              : `제${driverNumber}운전자 서명`}
          </Typography>
          <Divider sx={{ marginY: '40px' }} />
          <Box
            sx={{
              width: '100%',
              height: '276px',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '0px',
              },
            }}
          >
            <Viewer
              content={
                (driverNumber === 'privacy'
                  ? privacyAgreement?.content
                  : finalAgreement?.content) || ''
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '40px',
            flexGrow: 1,
            maxHeight: '648px',
            height: '648px',
            display: 'flex',
            flexDirection: 'column',
            padding: '40px',
            background: '#ffffff',
            border: '1px solid #d4d4d4',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography fontWeight='bold' fontSize={40}>
              서명란
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <ChipButton
              useIcon={false}
              onClick={() => {
                const canvas = canvasRef.current;
                const context = canvas?.getContext('2d');

                if (canvas && context) {
                  context.clearRect(0, 0, canvas.width, canvas.height);
                  setIsDrawn(false);
                }
              }}
            >
              다시하기
            </ChipButton>
          </Box>
          <Divider sx={{ marginY: '40px' }} />
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #d4d4d4',
              width: '100%',
              height: '100%',
            }}
          >
            <Typography
              sx={(theme) => ({
                width: '100%',
                color: theme.palette.grey[50],
                textIndent: spacing,
                letterSpacing: spacing,
              })}
              fontSize={fontSize}
              fontWeight={'regular'}
              textAlign={'center'}
            >
              {defaultText}
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            >
              <AutographCanvas canvasRef={canvasRef} setIsDrawn={setIsDrawn} />
            </Box>
          </Box>
        </Box>
      </Box>
      <LoadingDialog
        open={isUploadLoading}
        content='서명을 업로드하고 있어요.'
      />
      <BottomButtons
        disabled={!isDrawn}
        label='다음'
        homeButton
        backButton
        onClick={() => {
          handleNext(reservation.id);
        }}
      />
    </Box>
  );
};

export default ContractAutographPage;
